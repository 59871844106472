/** @format */

import checkout from "../schemas/form";

const {
    formField: {
        first_name,
        last_name,
        phone,
        education,
        dob,
        marital_status,
        country_id,
        province,
        district,
        cell,
        sector,
        village,
    },
} = checkout;

const FarmerInitialValues = {
    [first_name.name]: "",
    [last_name.name]: "",
    [phone.name]: "",
    [dob.name]: "",
    [marital_status.name]: "",
    [country_id.name]: "",
    [province.name]: "",
    [district.name]: "",
    [education.name]: "",
    [sector.name]: "",
    [cell.name]: "",
    [village.name]: "",
};

export default FarmerInitialValues;
