// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Settings page components
import FormField from 'components/FormField';

const top0 = { paddingTop: 0 };

function AddCountryInfo({ formData }) {
  const { formField, values, errors, touched } = formData;

  return (
    <Card id='country-info' sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant='h5'>Country Info</SoftTypography>
      </SoftBox>
      <SoftBox component='form' pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={formField.name.type}
              label={formField.name.label}
              name={formField.name.name}
              value={values.name}
              placeholder={formField.name.placeholder}
              error={errors.name && touched.name}
              success={values?.name?.length > 0 && !errors?.name}
            />
          </Grid>

          <Grid item xs={12} sm={12} style={top0}>
            <FormField
              type={formField.political_name.type}
              label={formField.political_name.label}
              name={formField.political_name.name}
              value={values.political_name}
              placeholder={formField.political_name.placeholder}
              error={errors.political_name && touched.political_name}
              success={
                values?.political_name?.length > 0 && !errors?.political_name
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} style={top0}>
            <FormField
              type={formField.h_to_cover.type}
              label={formField.h_to_cover.label}
              name={formField.h_to_cover.name}
              value={values.h_to_cover}
              placeholder={formField.h_to_cover.placeholder}
              error={errors.h_to_cover && touched.h_to_cover}
              success={values?.h_to_cover?.length > 0 && !errors?.h_to_cover}
            />
          </Grid>

          <Grid item xs={12} sm={12} style={top0}>
            <FormField
              type={formField.code.type}
              label={formField.code.label}
              name={formField.code.name}
              value={values.code}
              placeholder={formField.code.placeholder}
              error={errors.code && touched.code}
              success={values?.code?.length > 0 && !errors?.code}
            />
          </Grid>

          <Grid item xs={12} sm={12} style={top0}>
            <FormField
              type={formField.phone_code.type}
              label={formField.phone_code.label}
              name={formField.phone_code.name}
              value={values.phone_code}
              placeholder={formField.phone_code.placeholder}
              error={errors.phone_code && touched.phone_code}
              success={values?.phone_code?.length > 0 && !errors?.phone_code}
            />
          </Grid>

          <Grid item xs={12} sm={12} style={top0}>
            <FormField
              type={formField.money_code.type}
              label={formField.money_code.label}
              name={formField.money_code.name}
              value={values.money_code}
              placeholder={formField.money_code.placeholder}
              error={errors.money_code && touched.money_code}
              success={values?.money_code?.length > 0 && !errors?.money_code}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default AddCountryInfo;
