/** @format */

import { Grid } from "@mui/material";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import SoftBox from "components/SoftBox";
import DefaultCounterCard from "eco/Cards/CounterCards/DefaultCounterCard";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useGetSessionCountRangeQuery } from "features/slices/sessions";
import { useGetSessionCountQuery } from "features/slices/sessions";
import React, { useState } from "react";
import FormField from "../account/components/FormField";

const Session = () => {
    const { data: days, isLoading: dayIsLoading } =
        useGetSessionCountQuery("today");
    const { data: month } = useGetSessionCountQuery("month");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const { data: countDate, isLoading: dateLoading } =
        useGetSessionCountRangeQuery({
            startDate,
            endDate,
        });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div
                style={{ display: "flex", justifyContent: "end", gap: "1rem" }}
            >
                <FormField
                    type={"date"}
                    label={"From (start date)"}
                    name={"startDate"}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    placeholder={"start date"}
                />
                <FormField
                    type={"date"}
                    label={"To (end date)"}
                    name={"endDate"}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    placeholder={"end date"}
                />
            </div>
            <SoftBox pt={3}>
                <SoftBox mb={3}>
                    <Grid item xs={12} xl={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <LoadingCardSkeleton
                                    isLoading={dayIsLoading}
                                    height='133px'
                                >
                                    <DefaultCounterCard
                                        count={days?.count || 0}
                                        suffix=''
                                        title="Today's Session"
                                        description='
                                    '
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LoadingCardSkeleton
                                    isLoading={dayIsLoading}
                                    height='133px'
                                >
                                    <DefaultCounterCard
                                        count={month?.count || 0}
                                        suffix=''
                                        title="Months's Session"
                                        description='
                                    '
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <LoadingCardSkeleton
                                    isLoading={dateLoading}
                                    height='133px'
                                >
                                    <DefaultCounterCard
                                        count={countDate?.count || 0}
                                        suffix=''
                                        title='For Selected range Session'
                                        description='
                                    '
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
};

export default Session;
