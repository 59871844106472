/** @format */

import form from "./form";

const {
    formField: { name, Country },
} = form;

const initialValues = {
    [Country.name]: "",
    [name.name]: "",
};

export default initialValues;
