/** @format */

import changePassword from "./form";

const {
    formField: { password, repeatPassword },
} = changePassword;

const initialValues = {
    [password.name]: "",
    [repeatPassword.name]: "",
};

export default initialValues;
