/** @format */
import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

export const villageApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getVillagesByCellId: builder.query({
            query: (cellId) => `villages/by-cell/${cellId}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providersTags: ["village"],
        }),
    }),
});

export const { useGetVillagesByCellIdQuery } = villageApi;

const villageTypeSlice = createSlice({
    name: "village",
    initialState: {
        villages: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            villageApi.endpoints.getVillagesByCellId.matchFulfilled,
            (state, action) => {
                state.villages = action.payload;
            }
        );
    },
});

export const villageTypeReducer = villageTypeSlice.reducer;

export default villageTypeSlice;
