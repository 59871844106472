/** @format */

import * as Yup from "yup";
import form from "./form";

const {
    formField: { tree_class, tree_specy, description },
} = form;

const validations = [
    Yup.object().shape({
        [tree_class.name]: Yup.string(),
        [tree_specy.name]: Yup.string(),
        [description.name]: Yup.string(),
    }),
];

export default validations;
