/** @format */

import { Card } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import DataTable from "eco/Tables/DataTable";
import React from "react";
import treeSpecieColumn from "./data/treeSpeciesColumn";
import { useGetAllTreeSpeciesQuery } from "features/slices/treeSpeciesSlice";

const TreeSpecies = () => {
    const { values } = breakpoints;
    const { data, isLoading } = useGetAllTreeSpeciesQuery();
    const treeSpecies = data && data?.data;
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Tree Species
                </SoftTypography>
                <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                    <Card>
                        <DataTable
                            table={treeSpecieColumn}
                            entriesPerPage={5}
                            canSearch
                            theData={treeSpecies}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default TreeSpecies;
