/** @format */

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import { useGetSupportByIdQuery } from "features/slices/supportSlice";
import React from "react";
import { useParams } from "react-router-dom";
import HeaderDetail from "./details-header";
import SoftBox from "components/SoftBox";
import { Card, Grid } from "@mui/material";
import ProfileInfoCard from "eco/Cards/InfoCards/ProfileInfoCard";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { useGetSupportTasksQuery } from "features/slices/supportSlice";
import DataTable from "eco/Tables/DataTable";
import columnTask from "./data/columnTask";
import SoftTypography from "components/SoftTypography";

const DetailsSupport = () => {
    const { id } = useParams();
    const { data: support, isLoading: isLoadingSupport } =
        useGetSupportByIdQuery(id);
    const { data: supportTasks, isLoading: isLoadingTask } =
        useGetSupportTasksQuery(id);
    return (
        <DashboardLayout>
            <HeaderDetail
                name={support?.support?.name}
                isLoading={isLoadingSupport}
                role={"agent"}
            />
            <SoftBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4}>
                                <ProfileInfoCard
                                    title='Support informations'
                                    description='Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).'
                                    info={{
                                        supportRequested: `${
                                            (support &&
                                                support?.support?.name) ||
                                            "N/A"
                                        }`,
                                        farmerName: `${
                                            (support &&
                                                support?.farmer?.full_name) ||
                                            "N/A"
                                        }`,
                                        comment: `${
                                            (support && support?.comment) ||
                                            "N/A"
                                        }`,
                                        status: `${
                                            (support && support?.status) ||
                                            "N/A"
                                        }`,
                                    }}
                                    action={{
                                        route: "",
                                        tooltip: "Edit Profile",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <Card style={{ padding: 10 }}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingTask}
                                    >
                                        <SoftTypography
                                            variant='h6'
                                            fontWeight='medium'
                                            textTransform='capitalize'
                                        >
                                            Task History
                                        </SoftTypography>

                                        <DataTable
                                            table={columnTask}
                                            entriesPerPage={false}
                                            canSearch
                                            theData={supportTasks?.data}
                                        />
                                    </LoadingCardSkeleton>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SoftBox>
        </DashboardLayout>
    );
};

export default DetailsSupport;
