/** @format */

import { Card } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { MOBILE_BASE_URL } from "constants";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useGetTreeSpecieByIdQuery } from "features/slices/treeSpeciesSlice";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const TreeSpeciesDetail = () => {
    const { values } = breakpoints;
    const { id } = useParams();

    const { data, isLoading } = useGetTreeSpecieByIdQuery(id);

    const treeSpecy = data && data?.data;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Tree Species Details
                </SoftTypography>
                <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                    <Card sx={{ padding: "1rem" }}>
                        <TreeDetailContainer>
                            <PhotoWrapper>
                                <Image
                                    src={MOBILE_BASE_URL + treeSpecy?.photo}
                                    alt='photo'
                                />
                            </PhotoWrapper>
                            <div
                                style={{
                                    maxWidth: "500px",
                                    width: "500px",
                                    margin: "0 auto",
                                }}
                            >
                                <DetailContainer>
                                    <DetailWrapper>
                                        <span>Tree specy</span>:{" "}
                                        {treeSpecy?.tree_specy}
                                    </DetailWrapper>
                                    <DetailWrapper>
                                        <span>Tree class</span>:{" "}
                                        {treeSpecy?.tree_class}
                                    </DetailWrapper>
                                    <DetailWrapper>
                                        <span>Description</span>:{" "}
                                        {treeSpecy?.description}
                                    </DetailWrapper>
                                </DetailContainer>
                            </div>
                        </TreeDetailContainer>
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default TreeSpeciesDetail;

const TreeDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

const PhotoWrapper = styled.div`
    max-width: 500px;
    width: 500px;
    height: 400px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`;

const DetailWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    border-bottom: 1px solid #c9c8c5;
    span {
        font-weight: bold;
    }
`;
