/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

// Farmer API endpoints
export const farmerApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllFarmers: builder.query({
            query: () => "farmers",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["farmers"],
        }),
        getAllFarmersLoan: builder.query({
            query: (ongoing) => `farmers/loans?ongoing=${ongoing}`,
        }),
        getFarmerById: builder.query({
            query: (id) => `farmers/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["farmers"],
        }),
        getFarmerStatistics: builder.query({
            query: () => "farmers/loans-statistics",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getFarmerStatisticForest: builder.query({
            query: () => "farmers/forests-statistics",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getFamerHarvest: builder.query({
            query: (id) => `harvests/farmer/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getFamerMainDashboard: builder.query({
            query: () => `farmers/main-dashboard`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),

        getFarmerAgentStat: builder.query({
            query: () => "farmers/main-dashboard/agent-farmer",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getFarmerForest: builder.query({
            query: (id) => `forests/farmer/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getGetFarmerForestStat: builder.query({
            query: "farmers/forests-statistics",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getFarmerStatisticById: builder.query({
            query: (id) => `farmers/${id}/statistics`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        createFarmer: builder.mutation({
            query: (values) => {
                return {
                    url: "farmers",
                    method: "POST",
                    body: values,
                };
            },
            invalidatesTags: ["farmers"],
        }),
        updateFarmer: builder.mutation({
            query: (values, id) => {
                return {
                    url: `farmers/${id}`,
                    method: "PATCH",
                    body: values,
                };
            },
            invalidatesTags: ["farmers"],
        }),
    }),
});

export const {
    useGetAllFarmersQuery,
    useGetFarmerByIdQuery,
    useCreateFarmerMutation,
    useGetFarmerStatisticsQuery,
    useGetFarmerStatisticForestQuery,
    useGetFarmerStatisticByIdQuery,
    useGetAllFarmersLoanQuery,
    useGetFamerHarvestQuery,
    useGetFarmerForestQuery,
    useGetFamerMainDashboardQuery,
    useGetGetFarmerForestStatQuery,
    useGetFarmerAgentStatQuery,
} = farmerApi;

// Farmer slice
const farmerSlice = createSlice({
    name: "farmers",
    initialState: {
        farmers: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            farmerApi.endpoints.getAllFarmers.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.createFarmer.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getFarmerById.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getFarmerStatistics.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getFarmerStatisticForest.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getFarmerStatisticById.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getAllFarmersLoan.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getGetFarmerForestStat.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getFamerHarvest.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getFarmerForest.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getFarmerAgentStat.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
        builder.addMatcher(
            farmerApi.endpoints.getFamerMainDashboard.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
    },
});

export const farmerReducer = farmerSlice.reducer;

export default farmerSlice;
