/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

export const provinceApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllProvinceByCountryId: builder.query({
            query: () => "provinces",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providersTags: ["province"],
        }),
    }),
});

export const { useGetAllProvinceByCountryIdQuery } = provinceApi;

const provinceTypeSlice = createSlice({
    name: "province",
    initialState: {
        provinces: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            provinceApi.endpoints.getAllProvinceByCountryId.matchFulfilled,
            (state, action) => {
                state.provinces = action.payload;
            }
        );
    },
});
export const provinceTypeReducer = provinceTypeSlice.reducer;

export default provinceTypeSlice;
