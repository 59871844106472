/** @format */

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
// import dataTableData from "./data/DataTable";
import DataTable from "eco/Tables/DataTable";

import DefaultCounterCard from "eco/Cards/CounterCards/DefaultCounterCard";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";

import dataTableData from "./data/DataTable";

import { useGetLoansQuery } from "features/slices/loanSlice";
import { loanStatuses } from "constants/data";
import { useGetLoanStatisticsQuery } from "features/slices/loanSlice";
import breakpoints from "assets/theme/base/breakpoints";
import SoftTypography from "components/SoftTypography";

function LoansList() {
    const [loanStatus, setLoanStatus] = useState({
        id: "",
        status: "",
    });

    const { values } = breakpoints;

    const [menu, setMenu] = useState(null);

    const { data: loans, isLoading: isLoadingLoans } = useGetLoansQuery(
        loanStatus?.id
    );

    const { data: loanStatistics, isLoading: isLoadingLoanStat } =
        useGetLoanStatisticsQuery();

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    // Function to handle country filter change

    // Function to handle loan type filter change
    const handleLoanTypeFilterChange = (loanType) => {
        setLoanStatus(loanType);
    };

    const renderStatusMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <>
                {loanStatuses.map((loantype, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleLoanTypeFilterChange({
                                id: loantype.id || "",
                                status: loantype.status || "",
                            });
                            closeMenu();
                        }}
                    >
                        {loantype?.status}
                    </MenuItem>
                ))}
                <Divider sx={{ margin: "0.5rem 0" }} />

                {loanStatus.status && (
                    <MenuItem
                        onClick={() => {
                            handleLoanTypeFilterChange({
                                id: "",
                                status: "",
                            });
                            closeMenu();
                        }}
                    >
                        All
                    </MenuItem>
                )}
            </>
        </Menu>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Loan Statistics
                </SoftTypography>

                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingLoanStat}
                                        height='133px'
                                    >
                                        <DefaultCounterCard
                                            count={
                                                loanStatistics?.all_applications
                                            }
                                            suffix=''
                                            title='Loan applications'
                                            description='Farmers requests'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingLoanStat}
                                        height='133px'
                                    >
                                        <DefaultCounterCard
                                            count={loanStatistics?.loan_given}
                                            suffix=''
                                            title='loan given'
                                            description='Total amount given'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingLoanStat}
                                        height='133px'
                                    >
                                        <DefaultCounterCard
                                            count={
                                                loanStatistics?.total_paid_amount
                                            }
                                            suffix='FRW'
                                            title='Paid loan'
                                            description='Total amount paid'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingLoanStat}
                                        height='133px'
                                    >
                                        <DefaultCounterCard
                                            count={
                                                loanStatistics?.total_amount_to_pay
                                            }
                                            suffix='FRW'
                                            title='Total loan amount'
                                            description='Total amount to paid'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    mb={2}
                >
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Loan List
                    </SoftTypography>

                    <SoftBox display='flex'>
                        <SoftBox ml={1}>
                            <SoftButton
                                variant={menu ? "contained" : "outlined"}
                                color='secondary'
                                handleClick={openMenu}
                            >
                                {loanStatus?.status || (
                                    <>
                                        filters by loan Type&nbsp;
                                        <Icon>keyboard_arrow_down</Icon>
                                    </>
                                )}
                            </SoftButton>
                            {renderStatusMenu}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
                <LoadingCardSkeleton isLoading={isLoadingLoans} height='333px'>
                    <Card>
                        <DataTable
                            table={dataTableData}
                            entriesPerPage={false}
                            canSearch={true}
                            theData={loanStatus.id ? loans?.data : loans}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default LoansList;
