/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

export const projectApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllProject: builder.query({
            query: () => "projects",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["project"],
        }),
        getProjectById: builder.query({
            query: (id) => `projects/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getProjectByBeneficiary: builder.query({
            query: (id) => `projects/${id}/get-beneficiaries`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getProjectByForest: builder.query({
            query: (id) => `projects/${id}/get-forests`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        createProject: builder.mutation({
            query: (body) => {
                return {
                    url: "projects",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["project"],
        }),
        updateProject: builder.mutation({
            query: ({ id, values }) => {
                return {
                    url: `projects/${id}`,
                    method: "PATCH",
                    body: values,
                };
            },
            invalidatesTags: ["project"],
        }),
        addProjectBeneficiary: builder.mutation({
            query: (values) => {
                return {
                    url: `projects/add-beneficiary`,
                    method: "POST",
                    body: values,
                };
            },
            invalidatesTags: ["project"],
        }),
        addProjectForest: builder.mutation({
            query: (values) => {
                return {
                    url: `projects/add-forest`,
                    method: "POST",
                    body: values,
                };
            },
            invalidatesTags: ["project"],
        }),
    }),
});

export const {
    useCreateProjectMutation,
    useGetAllProjectQuery,
    useGetProjectByBeneficiaryQuery,
    useGetProjectByForestQuery,
    useGetProjectByIdQuery,
    useUpdateProjectMutation,
    useAddProjectBeneficiaryMutation,
    useAddProjectForestMutation,
} = projectApi;

const projectSlice = createSlice({
    name: "project",
    initialState: {
        projects: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            projectApi.endpoints.getAllProject.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );

        builder.addMatcher(
            projectApi.endpoints.getProjectById.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );

        builder.addMatcher(
            projectApi.endpoints.getProjectByBeneficiary.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );

        builder.addMatcher(
            projectApi.endpoints.getProjectByForest.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );

        builder.addMatcher(
            projectApi.endpoints.createProject.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );

        builder.addMatcher(
            projectApi.endpoints.updateProject.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );

        builder.addMatcher(
            projectApi.endpoints.addProjectBeneficiary.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );

        builder.addMatcher(
            projectApi.endpoints.addProjectForest.matchFulfilled,
            (state, action) => {
                state.projects = action.payload;
            }
        );
    },
});

export const projectReducer = projectSlice.reducer;

export default projectSlice;
