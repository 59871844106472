// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';

// Settings page components
import DashboardLayout from 'eco/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'eco/Navbars/DashboardNavbar';
// import BaseLayout from 'layouts/pages/account/components/BaseLayout';
import Sidenav from 'layouts/pages/account/settings/components/Sidenav';
import Header from 'layouts/pages/account/settings/components/Header';
import BasicInfo from 'layouts/pages/account/settings/components/BasicInfo';
import ChangePassword from 'layouts/pages/account/settings/components/ChangePassword';
import DeleteAccount from 'layouts/pages/account/settings/components/DeleteAccount';

function Settings() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                <Grid item xs={12}>
                  <DeleteAccount />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Settings;
