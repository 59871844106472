/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import styled from "styled-components";
import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { MOBILE_BASE_URL } from "constants";

const treeSpecieColumn = {
    columns: [
        {
            Header: "Photo",
            accessor: "photo",
            Cell: ({ value }) => {
                return (
                    <Logo
                        src={`
                        ${MOBILE_BASE_URL}${value}`}
                    />
                );
            },
        },
        {
            Header: "Tree Specy",
            accessor: "tree_specy",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Tree class",
            accessor: "tree_class",
            Cell: ({ value }) => (
                <DefaultCell value={value ?? "Contact person"} />
            ),
        },

        {
            Header: "More",
            accessor: "id",
            Cell: ({ value, _ }) => (
                <div style={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow={false} title='view details'>
                        <NavLink
                            style={{ fontSize: "25px" }}
                            to={`/tree-species/detail/${value}`}
                        >
                            <RemoveRedEyeIcon fontSize='inherit' />
                        </NavLink>
                    </Tooltip>
                    <Tooltip arrow={false} title='edit tree specy'>
                        <NavLink
                            style={{ fontSize: "25px" }}
                            to={`/tree-species/edit/${value}`}
                        >
                            <CreateIcon fontSize='inherit' />
                        </NavLink>
                    </Tooltip>
                </div>
            ),
        },
    ],
};

export const NavLink = styled(Link)`
    text-decoration: none;
    color: black;
    &:hover {
        text-decoration: underline !important;
    }
`;

export const Logo = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50px;
`;

export default treeSpecieColumn;
