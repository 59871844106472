/** @format */

import { Alert, Card, CircularProgress, Grid, Snackbar } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import DataTable from "eco/Tables/DataTable";
import { Form, Formik } from "formik";
import SponsorFormInfo from "./SponsorFormInfo";
import columnData from "./data/columnData";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import form from "./schema/form";
import { useGetAllSponsorsQuery } from "features/slices/sponsorSlice";
import validations from "./schema/validation";
import initialValues from "./schema/initialValues";
import ComplexStatisticsCard from "eco/Cards/StatisticsCards/ComplexStatisticsCard";
import { useCreateSponsorMutation } from "features/slices/sponsorSlice";
import { useState } from "react";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";

const Sponsor = () => {
    const { data: Sponsors, isLoading: isLoadingSponsor } =
        useGetAllSponsorsQuery();
    const [createSponsor, { isLoading, isError, error }] =
        useCreateSponsorMutation();
    const { formId, formField } = form;
    const [showError, setShowError] = useState(false);
    const [open, setOpen] = useState(false);
    const toggleShowError = () => setShowError(!showError);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const currentValidation = validations[0];
    const handleSubmit = async (values, actions) => {
        try {
            const result = await createSponsor(values).unwrap();
            if (result?.status) {
                actions.setSubmitting(false);
                handleClick();
                actions.resetForm();
            }
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        } finally {
            actions.setSubmitting(false);
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    New Sponsor Added Successfully!
                </Alert>
            </Snackbar>
            <SoftBox pt={3}>
                <SoftBox mb={3}>
                    {/* <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <ComplexStatisticsCard
                                        icon='account_circle'
                                        count={{
                                            number: 100,
                                            label: "All Sponsor",
                                        }}
                                        percentage=''
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ComplexStatisticsCard
                                        icon='check_list'
                                        count={{
                                            number: 100000,
                                            label: "Total spent",
                                        }}
                                        percentage=''
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ComplexStatisticsCard
                                        icon='check_list'
                                        count={{
                                            number: 80,
                                            label: "Number of sponsored",
                                        }}
                                        percentage=''
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </SoftBox>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={4}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={currentValidation}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <Form id={formId} autoComplete='off'>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <SponsorFormInfo
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                />
                                                <SoftBox
                                                    mt={2}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            ` Send`
                                                        )}
                                                    </SoftButton>
                                                    {showError &&
                                                        !isLoading &&
                                                        isError && (
                                                            <ErrorMessageResponse>
                                                                {
                                                                    error?.data
                                                                        ?.message
                                                                }
                                                            </ErrorMessageResponse>
                                                        )}
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                        <Grid item xs={12} xl={8}>
                            <LoadingCardSkeleton
                                isLoading={isLoadingSponsor}
                                height='300px'
                            >
                                <Card>
                                    <DataTable
                                        table={columnData}
                                        entriesPerPage={false}
                                        canSearch
                                        theData={Sponsors}
                                    />
                                </Card>
                            </LoadingCardSkeleton>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
};

export default Sponsor;
