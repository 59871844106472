// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React eco components
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import MiniStatisticsCard from "eco/Cards/StatisticsCards/MiniStatisticsCard";
import CountryTable from "eco/Tables/CountriesTable";

import ReportsBarChart from "eco/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "eco/Charts/LineCharts/GradientLineChart";
import Globe from "eco/Globe";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

//Routing
import { useParams } from "react-router-dom";

// Data
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboards/default/data/gradientLineChartData";
import hectaresCovered from "./data/HectareCoveredByCountry";

function CountryDashboard() {
  const { country } = useParams();
  const { values } = breakpoints;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <SoftBox mb={3} p={1}>
              <SoftTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                {country || ""}
              </SoftTypography>
            </SoftBox>

            <Grid container>
              <Grid item xs={12}>
                <Globe
                  display={{ xs: "none", md: "block" }}
                  position="absolute"
                  top="10%"
                  right={0}
                  mt={{ xs: -12, lg: 1 }}
                  mr={{ xs: 0, lg: 10 }}
                  canvasStyle={{ marginTop: "3rem" }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "Forests", fontWeight: "bold" }}
                    count="3,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "info", component: "paid" }}
                  />
                </SoftBox>

                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "Hectare restored", fontWeight: "bold" }}
                    count="2,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "info", component: "paid" }}
                  />
                </SoftBox>

                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "Trees planted", fontWeight: "bold" }}
                    count="1,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "info", component: "paid" }}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={5}>
                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "Farmers", fontWeight: "bold" }}
                    count="3,462"
                    percentage={{ color: "success", text: "+2%" }}
                    icon={{ color: "info", component: "emoji_events" }}
                  />
                </SoftBox>

                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "Agents", fontWeight: "bold" }}
                    count="15,462"
                    percentage={{ color: "success", text: "+2%" }}
                    icon={{ color: "info", component: "emoji_events" }}
                  />
                </SoftBox>

                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "Countries", fontWeight: "bold" }}
                    count="30"
                    percentage={{ color: "success", text: "+2%" }}
                    icon={{
                      color: "info",
                      component: "shopping_cart",
                    }}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} lg={7}>
            <Grid item xs={12} lg={10}>
              <SoftBox mb={3} position="relative">
                <CountryTable title="Hectares covered by country" rows={hectaresCovered} />
              </SoftBox>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <ReportsBarChart
                title="Loans"
                description={
                  <>
                    (<strong>+23%</strong>) than last month
                  </>
                }
                chart={chart}
                items={items}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <GradientLineChart
                title="Farmers & agents Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon sx={{ fontWeight: "bold" }}>arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2023
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CountryDashboard;
