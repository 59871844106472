/** @format */

const form = {
    formId: "new-beneficiary-form",
    formField: {
        beneficiary: {
            name: "beneficiary",
            label: "Beneficiary",
            type: "select",
            options: [],
            errorMsg: "beneficiary is required.",
        },
    },
};

export default form;
