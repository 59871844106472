/** @format */

import { Grid, MenuItem, Select, TextField } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetAllFarmersQuery } from "features/slices/farmerSlice";
import React, { useState } from "react";

const BenefiaciaryForm = ({ formData }) => {
    const { formField, values, errors, touched, setFieldValue } = formData;
    const { data: farmers } = useGetAllFarmersQuery();
    const [selectedProduct, setSelectedProduct] = useState([]);
    const transformBenef = farmers?.map((farmer) => {
        return { id: farmer?.id, label: farmer?.full_name };
    });

    return (
        <>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>
                    Create Beneficiary to Project
                </SoftTypography>
            </SoftBox>
            <SoftBox component='form' pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                beneficiary
                            </SoftTypography>
                        </SoftBox>
                        <div style={{ flex: 1 }}>
                            <Autocomplete
                                disablePortal
                                id='combo-box-demo'
                                options={transformBenef || []}
                                sx={{ width: "100%" }}
                                value={selectedProduct}
                                onChange={(event, newInputValue) => {
                                    setSelectedProduct(newInputValue);

                                    setFieldValue(
                                        formField.beneficiary?.name,
                                        newInputValue?.id
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label='Search Product'
                                    />
                                )}
                            />
                        </div>
                        {/* <Select
                            input={<SoftInput />}
                            value={values.beneficiary} // Set default to the first option's ID
                            error={errors.beneficiary && touched.beneficiary}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.beneficiary?.name,
                                    event.target.value
                                );
                            }}
                        >
                            {farmers?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.full_name}
                                </MenuItem>
                            ))}
                        </Select> */}
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
};

export default BenefiaciaryForm;
