/** @format */

import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Alert, CircularProgress, Snackbar } from "@mui/material";

import { Formik, Form } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import DataTable from "eco/Tables/DataTable";

// Data
import dataTableData from "./data/dataTableData";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import {
    useGetAllCountriesQuery,
    useCreateCountryMutation,
} from "features/slices/countrySlice";

import validations from "./data/schema/validation";
import form from "./data/schema/form";
import initialValues from "./data/schema/initialValues";
import AddCountryInfo from "./AddCountry";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";

function CountryList() {
    const { data: CountriesList, isLoading: countryIsLoading } =
        useGetAllCountriesQuery();

    const [createCountry, { isLoading, isError, error }] =
        useCreateCountryMutation();
    const { formId, formField } = form;
    const [open, setOpen] = useState(false);
    const [showError, setShowError] = useState(false);

    const toggleShowError = () => setShowError(!showError);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    const handleSubmit = async (values, actions) => {
        const newValue = {
            ...values,
            flag: `https://flagsapi.com/${values.code}/shiny/32.png`,
        };
        console.log(newValue);
        try {
            const response = await createCountry(newValue).unwrap();
            if (response && response?.status) {
                handleClick();
                actions.resetForm();
            }
        } catch (err) {
            actions.setTouched({});
            actions.setSubmitting(false);
            toggleShowError(true);
        } finally {
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    New Country Added Successfully!
                </Alert>
            </Snackbar>
            <SoftBox pt={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={4}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validations[0]}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <Form id={formId} autoComplete='off'>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <AddCountryInfo
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                />
                                                <SoftBox
                                                    mt={2}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            ` Send`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {showError &&
                                                    !isLoading &&
                                                    isError && (
                                                        <ErrorMessageResponse>
                                                            {error.message}
                                                        </ErrorMessageResponse>
                                                    )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                        <Grid item xs={12} xl={8}>
                            <LoadingCardSkeleton
                                isLoading={countryIsLoading}
                                height='300px'
                            >
                                <Card>
                                    <DataTable
                                        table={dataTableData}
                                        entriesPerPage={false}
                                        canSearch
                                        theData={CountriesList?.data}
                                    />
                                </Card>
                            </LoadingCardSkeleton>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CountryList;
