/** @format */

import * as Yup from "yup";
import formElement from "../schemas/form";

const {
    formField: {
        first_name,
        last_name,
        phone,
        country_id,
        province,
        district,
        sector,
        cell,
        village,
        dob,
        marital_status,
        education,
    },
} = formElement;

const FarmerValidations = [
    Yup.object().shape({
        [first_name.name]: Yup.string().required(first_name.errorMsg),
        [last_name.name]: Yup.string().required(last_name.errorMsg),
        [phone.name]: Yup.string()
            .required(phone.errorMsg)
            .matches(
                /^250\d{9}$/,
                "Phone number must be 12 digits and start with '250'"
            ),
        [province.name]: Yup.string().required(province.errorMsg),
        [district.name]: Yup.string().required(district.errorMsg),
        [sector.name]: Yup.string().required(sector.errorMsg),
        [cell.name]: Yup.string().required(cell.errorMsg),
        [marital_status.name]: Yup.string().required(marital_status.errorMsg),
        [dob.name]: Yup.string().required(dob.errorMsg),
        [village.name]: Yup.string().required(village.errorMsg),
        [education.name]: Yup.string().required(education.errorMsg),
        [country_id.name]: Yup.string().required(country_id.errorMsg),
    }),
];

export default FarmerValidations;
