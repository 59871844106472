/** @format */

import { useRef, useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React helper functions
// import gradientChartLine from "assets/theme/functions/gradientChartLine";

// GradientLineChart configurations
// import configs from "eco/Charts/LineCharts/GradientLineChart/configs";

// Soft UI Dashboard PRO React base styles
// import colors from "assets/theme/base/colors";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function GradientLineChart({
    title,
    description,
    height,
    chart,
    FarmerAgentGraph,
}) {
    // const [statsData, setStatsData] = useState(null);
    const chartRef = useRef(null);
    // const [chartData, setChartData] = useState({});

    const chartData = {
        labels: Object.keys(FarmerAgentGraph?.agents),
        datasets: [
            {
                label: "Farmers",
                // color: "success",
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                data: Object.values(FarmerAgentGraph?.farmers), // [0, 0, 10]
            },
            {
                label: "Agents",
                // color: "dark",
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
                data: Object.values(FarmerAgentGraph?.agents),
            },
        ],
    };

    const { data, options } = chartData;

    // const updateChartData = (data) => {

    //     setStatsData(chartData);
    // };

    // useEffect(() => {
    //     const chartDatasets = statsData?.datasets
    //         ? statsData?.datasets.map((dataset) => ({
    //               ...dataset,
    //               tension: 0.4,
    //               pointRadius: 0,
    //               borderWidth: 3,
    //               borderColor: colors[dataset.color]
    //                   ? colors[dataset.color || "dark"].main
    //                   : colors.dark.main,
    //               fill: true,
    //               maxBarThickness: 6,
    //               backgroundColor: gradientChartLine(
    //                   chartRef.current.children[0],
    //                   colors[dataset.color]
    //                       ? colors[dataset.color || "dark"].main
    //                       : colors.dark.main
    //               ),
    //           }))
    //         : [];

    //     setChartData(configs(statsData?.labels || [], chartDatasets));
    // }, [statsData]);

    // useEffect(() => {
    //     if (FarmerAgentGraph && FarmerAgentGraph?.length > 0) {
    //         updateChartData(FarmerAgentGraph);
    //     }
    // }, [FarmerAgentGraph]);

    const renderChart = (
        <SoftBox p={2}>
            {title || description ? (
                <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>
                    {title && (
                        <SoftBox mb={1}>
                            <SoftTypography variant='h6'>
                                {title}
                            </SoftTypography>
                        </SoftBox>
                    )}
                    <SoftBox mb={2}>
                        <SoftTypography
                            component='div'
                            variant='button'
                            fontWeight='regular'
                            color='text'
                        >
                            {description}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            ) : null}
            {useMemo(
                () => (
                    <SoftBox ref={chartRef} sx={{ height }}>
                        <Line data={chartData} options={{ responsive: true }} />
                    </SoftBox>
                ),
                [chartData, height, options, data]
            )}
        </SoftBox>
    );

    return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of GradientLineChart
GradientLineChart.defaultProps = {
    title: "",
    description: "",
    height: "19.125rem",
};

// Typechecking props for the GradientLineChart
GradientLineChart.propTypes = {
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default GradientLineChart;
