/** @format */

import React from "react";
import SupportStatus from "../status";

const PendingTask = () => {
    return <SupportStatus status={"Pending"} title={"Pending tasks"} />;
};

export default PendingTask;
