/** @format */

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

import DataTableHeadCell from "eco/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "eco/Tables/DataTable/DataTableBodyCell";

function DataTable({
    entriesPerPage,
    canSearch,
    showTotalEntries,
    table,
    pagination,
    isSorted,
    noEndBorder,
    theData,
}) {
    const defaultValue = entriesPerPage.defaultValue
        ? entriesPerPage.defaultValue
        : 15;
    const entries = entriesPerPage.entries
        ? entriesPerPage.entries
        : [15, 25, 35, 45, 55, 100];
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => theData || [], [theData]);
    const [pageIndexs, setPageIndex] = useState(0);
    const [pageSizes, setPageSizes] = useState(
        entriesPerPage.defaultValue || 10
    );
    const [datas, setData] = useState([]);

    useEffect(() => {
        setData(theData || []);
    }, [theData]);

    const pageCount = Math.ceil(data.length / pageSizes);
    const canNextPages = pageIndexs < pageCount - 1;
    const canPreviousPages = pageIndexs > 0;

    const nextPages = () => {
        if (canNextPage) setPageIndex((prev) => prev + 1);
    };

    const previousPages = () => {
        if (canPreviousPage) setPageIndex((prev) => prev - 1);
    };

    const gotoPages = (page) => {
        if (page >= 0 && page < pageCount) setPageIndex(page);
    };

    const tableInstance = useTable(
        { columns, data, initialState: { pageIndex: 0 } },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;

    // Set the default value for the entries per page when component mounts
    useEffect(
        () => setPageSize(defaultValue || 10),
        [defaultValue, setPageSize]
    );

    // Set the entries per page value based on the select value
    const setEntriesPerPage = ({ value }) => setPageSize(value);

    // Render the paginations
    const renderPagination = pageOptions.map((option, idx) => (
        <SoftPagination
            item
            key={idx} // Use String(option) as the key
            value={String(option + 1)} // Use String(option + 1) as the value
            onClick={() => gotoPages(idx)}
            active={pageIndex === idx}
        >
            {option + 1}
        </SoftPagination>
    ));

    // Handler for the input to set the pagination index
    const handleInputPagination = ({ target: { value } }) => {
        const pageNumber = Number(value);
        if (pageNumber >= 1 && pageNumber <= pageOptions.length) {
            gotoPage(pageNumber - 1);
        }
    };

    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);

    // Setting value for the pagination input
    // Setting value for the pagination input
    const handleInputPaginationValue = (event) =>
        gotoPage(Number(event.target.value - 1));

    // Search input value state
    const [search, setSearch] = useState(globalFilter);

    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);
    // Setting the entries starting point
    const entriesStart =
        pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    return (
        <TableContainer sx={{ boxShadow: "none" }}>
            {entriesPerPage || canSearch ? (
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    p={3}
                >
                    {entriesPerPage && (
                        <SoftBox display='flex' alignItems='center'>
                            <SoftSelect
                                defaultValue={{
                                    value: defaultValue,
                                    label: defaultValue,
                                }}
                                options={entries.map((entry) => ({
                                    value: entry,
                                    label: entry,
                                }))}
                                onChange={setEntriesPerPage}
                                size='small'
                            />
                            <SoftTypography variant='caption' color='secondary'>
                                &nbsp;&nbsp;entries per page
                            </SoftTypography>
                        </SoftBox>
                    )}
                    {canSearch && (
                        <SoftBox width='12rem' ml='auto'>
                            <SoftInput
                                placeholder='Search...'
                                value={search}
                                onChange={({ currentTarget }) => {
                                    setSearch(search);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </SoftBox>
                    )}
                </SoftBox>
            ) : null}
            <Table {...getTableProps()}>
                <SoftBox component='thead'>
                    {headerGroups.map((headerGroup, key) => (
                        <TableRow
                            key={key}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers &&
                                headerGroup.headers.map((column) => (
                                    <DataTableHeadCell key={column.id}>
                                        {column.render("Header")}
                                    </DataTableHeadCell>
                                ))}
                        </TableRow>
                    ))}
                </SoftBox>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow key={key} {...row.getRowProps()}>
                                {row.cells.map((cell, key) => (
                                    <DataTableBodyCell
                                        key={key}
                                        noBorder={
                                            noEndBorder &&
                                            rows.length - 1 === key
                                        }
                                        align={
                                            cell.column.align
                                                ? cell.column.align
                                                : "left"
                                        }
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <SoftBox
                display='flex'
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent='space-between'
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <SoftBox mb={{ xs: 3, sm: 0 }}>
                        <SoftTypography
                            variant='button'
                            color='secondary'
                            fontWeight='regular'
                        >
                            Showing {entriesStart} to {entriesEnd} of{" "}
                            {rows.length} entries
                        </SoftTypography>
                    </SoftBox>
                )}
                {pageOptions.length > 1 && (
                    <SoftPagination
                        variant={
                            pagination.variant ? pagination.variant : "gradient"
                        }
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {canPreviousPage && (
                            <SoftPagination item onClick={() => previousPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>
                                    chevron_left
                                </Icon>
                            </SoftPagination>
                        )}
                        {renderPagination.length > 7 ? (
                            <SoftBox width='5rem' mx={1}>
                                <SoftInput
                                    inputProps={{
                                        type: "number",
                                        min: 1,
                                        max: customizedPageOptions.length,
                                    }}
                                    value={customizedPageOptions[pageIndex]}
                                    onChange={
                                        (handleInputPagination,
                                        handleInputPaginationValue)
                                    }
                                />
                            </SoftBox>
                        ) : (
                            renderPagination
                        )}
                        {canNextPage && (
                            <SoftPagination
                                item
                                onClick={() => nextPage()} // Use nextPage for the next page
                                disabled={!canNextPage}
                            >
                                <Icon sx={{ fontWeight: "bold" }}>
                                    chevron_right
                                </Icon>
                            </SoftPagination>
                        )}
                    </SoftPagination>
                )}
            </SoftBox>
        </TableContainer>
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 15, entries: [15, 25, 35, 45, 55, 100] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;
