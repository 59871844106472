/** @format */

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import SidenavCollapse from "eco/Sidenav/SidenavCollapse";
import SidenavList from "eco/Sidenav/SidenavList";
import SidenavItem from "eco/Sidenav/SidenavItem";
// Custom styles for the Sidenav
import SidenavRoot from "eco/Sidenav/SidenavRoot";
import sidenavLogoLabel from "eco/Sidenav/styles/sidenav";
import logo from "assets/images/logo.png";
import ecoLogo from "assets/images/ecoforest--logo-green.png";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "context";
import styled from "styled-components";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
    const [openCollapse, setOpenCollapse] = useState(false);
    const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, transparentSidenav } = controller;
    const location = useLocation();
    const { pathname } = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const itemName = pathname.split("/").slice(1)[1];

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
        }

        /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapse) => {
        const template = collapse.map(({ name, route, key, href }) =>
            href ? (
                <Link
                    key={key}
                    href={href}
                    target='_blank'
                    rel='noreferrer'
                    sx={{ textDecoration: "none" }}
                >
                    <SidenavItem name={name} nested />
                </Link>
            ) : (
                <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
                    <SidenavItem
                        name={name}
                        active={route === pathname}
                        nested
                    />
                </NavLink>
            )
        );

        return template;
    };

    // Render the all the collpases from the routes.js
    const renderCollapse = (collapses) =>
        collapses.map(({ name, collapse, route, href, key }) => {
            let returnValue;

            if (collapse) {
                returnValue = (
                    <SidenavItem
                        key={key}
                        name={name}
                        active={key === itemName}
                        open={openNestedCollapse === name}
                        onClick={() =>
                            openNestedCollapse === name
                                ? setOpenNestedCollapse(false)
                                : setOpenNestedCollapse(name)
                        }
                    >
                        {renderNestedCollapse(collapse)}
                    </SidenavItem>
                );
            } else {
                returnValue = href ? (
                    <Link
                        href={href}
                        key={key}
                        target='_blank'
                        rel='noreferrer'
                        sx={{ textDecoration: "none" }}
                    >
                        <SidenavItem name={name} active={key === itemName} />
                    </Link>
                ) : (
                    <NavLink
                        to={route}
                        key={key}
                        sx={{ textDecoration: "none" }}
                    >
                        <SidenavItem name={name} active={key === itemName} />
                    </NavLink>
                );
            }
            return <SidenavList key={key}>{returnValue}</SidenavList>;
        });

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(
        ({
            type,
            name,
            icon,
            title,
            collapse,
            noCollapse,
            key,
            href,
            route,
        }) => {
            let returnValue;

            if (type === "collapse") {
                if (href) {
                    returnValue = (
                        <Link
                            href={href}
                            key={key}
                            target='_blank'
                            rel='noreferrer'
                            sx={{ textDecoration: "none" }}
                        >
                            <SidenavCollapse
                                name={name}
                                icon={icon}
                                active={key === collapseName}
                                noCollapse={noCollapse}
                            />
                        </Link>
                    );
                } else if (noCollapse && route) {
                    returnValue = (
                        <NavLink to={route} key={key}>
                            <SidenavCollapse
                                name={name}
                                icon={icon}
                                noCollapse={noCollapse}
                                active={key === collapseName}
                            >
                                {collapse ? renderCollapse(collapse) : null}
                            </SidenavCollapse>
                        </NavLink>
                    );
                } else {
                    returnValue = (
                        <SidenavCollapse
                            key={key}
                            name={name}
                            icon={icon}
                            active={key === collapseName}
                            open={openCollapse === key}
                            onClick={() =>
                                openCollapse === key
                                    ? setOpenCollapse(false)
                                    : setOpenCollapse(key)
                            }
                        >
                            {collapse ? renderCollapse(collapse) : null}
                        </SidenavCollapse>
                    );
                }
            } else if (type === "title") {
                returnValue = (
                    <SoftTypography
                        key={key}
                        display='block'
                        variant='caption'
                        fontWeight='bold'
                        textTransform='uppercase'
                        opacity={0.6}
                        pl={3}
                        mt={2}
                        mb={1}
                        ml={1}
                    >
                        {title}
                    </SoftTypography>
                );
            } else if (type === "divider") {
                returnValue = <Divider key={key} />;
            }

            return returnValue;
        }
    );

    return (
        <SidenavRoot
            {...rest}
            variant='permanent'
            ownerState={{ transparentSidenav, miniSidenav }}
        >
            <SoftBox display='flex' alignItems='center' justifyContent='start'>
                <LogoContainer>
                    <LogoImg alt='Isaro, ecoforest, greenland' src={ecoLogo} />
                </LogoContainer>
            </SoftBox>
            {/* <SoftBox pt={3} pb={1} px={4} textAlign='center'>
                <SoftBox
                    display={{ xs: "block", xl: "none" }}
                    position='absolute'
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{ cursor: "pointer" }}
                >
                    <SoftTypography variant='h6' color='secondary'>
                        <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                    </SoftTypography>
                </SoftBox>
                <SoftBox
                    component={NavLink}
                    to='/'
                    display='flex'
                    alignItems='center'
                >
                    {brand && (
                        <SoftBox
                            component='img'
                            src={brand}
                            alt='Isaro logo'
                            width='2rem'
                        />
                    )}
                    <SoftBox
                        width={!brandName && "100%"}
                        sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                    >
                        <SoftTypography
                            component='h6'
                            variant='button'
                            fontWeight='medium'
                        >
                            {brandName}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox> */}
            <Divider />
            <List>{renderRoutes}</List>
        </SidenavRoot>
    );
}

const LogoContainer = styled.div`
    /* width: 240px;
    height: 240px; */
    padding-left: 15px;
    margin-bottom: -5px;
    margin-top: 25px;
`;

const LogoImg = styled.img`
    width: 70%;
`;

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
    ]),
    // brand: PropTypes.string,
    // brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
