/** @format */

import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import DataTable from "eco/Tables/DataTable";
import { useGetSupportWithoutResponseQuery } from "features/slices/supportSlice";
import React from "react";
import columnData from "../data/column";

const Delayed = () => {
    const {
        data: supports,
        isLoading,
        isFetching,
    } = useGetSupportWithoutResponseQuery();
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox my={3}>
                <LoadingCardSkeleton
                    isLoading={isLoading || isFetching}
                    height='333px'
                >
                    <Card>
                        <DataTable
                            table={columnData}
                            entriesPerPage={false}
                            canSearch
                            theData={supports?.data}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default Delayed;
