/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

// Farmer API endpoints
export const countryApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllCountries: builder.query({
            query: () => "countries",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["country"],
        }),

        getLocationList: builder.query({
            query: (filters) => {
                let apiUrl = "country/location_list";
                if (filters?.level) {
                    apiUrl += `?level=${filters.level}`;
                }
                if (filters?.parentId) {
                    apiUrl += `&parent_id=${filters.parentId}`;
                }

                return apiUrl;
            },

            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000, // Fetch data every 15 minutes
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
        }),

        createCountry: builder.mutation({
            query: (data) => {
                return {
                    url: "countries",
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["country"],
        }),
    }),
});

export const {
    useGetAllCountriesQuery,
    useCreateCountryMutation,
    useGetLocationListQuery,
} = countryApi;

// Country slice
const countrySlice = createSlice({
    name: "country",
    initialState: {
        country: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            countryApi.endpoints.getAllCountries.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
        builder.addMatcher(
            countryApi.endpoints.createCountry.matchFulfilled,
            (state, action) => {
                console.log(action.payload.data);
                // state.country = [action.payload.data, ...state.country];
                // saveTokenToLocalStorage(action?.payload?.token);
                // Update the token in the Redux state
                // state.token = action?.payload?.token;
            }
        );
    },
});

export const countryReducer = countrySlice.reducer;

export default countrySlice;
