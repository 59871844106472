/** @format */

import { Card, Divider, Icon, Menu, MenuItem } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useGetAllCountriesQuery } from "features/slices/countrySlice";
import { useGetAllTasksQuery } from "features/slices/taskSlice";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dataTableData from "./data/dataTableData";
import { fakeTaskData } from "constants/data";
import DataTable from "eco/Tables/DataTable";

const TaskList = () => {
    const { status } = useParams();
    const [selectedCountry, setSelectedCountry] = useState("");
    const { data: CountriesList } = useGetAllCountriesQuery();
    const taskList = fakeTaskData.filter(
        (value) => value.task_status.toString() === status
    );

    console.log(taskList, status);

    const {
        // data: taskList,
        isLoading: taskIsLoading,
        isFetching: taskIsFetching,
    } = useGetAllTasksQuery();

    const navigate = useNavigate();
    const [CountryMenu, setCountryMenu] = useState(null);

    const openCountryMenu = (event) => setCountryMenu(event.currentTarget);
    const closeCountryMenu = () => setCountryMenu(null);

    // Memoized function using useCallback
    const handleCountryFilterChange = useCallback((country) => {
        setSelectedCountry(country);
    }, []);

    const renderCountryMenu = () => (
        <Menu
            anchorEl={CountryMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(CountryMenu)}
            onClose={closeCountryMenu}
            keepMounted
        >
            {CountriesList && CountriesList?.length > 0 && (
                <>
                    {CountriesList.map((country, index) => (
                        <MenuItem
                            id={index}
                            onClick={() => {
                                handleCountryFilterChange({
                                    id: country?.id || "",
                                    name: country?.name || "",
                                });
                                closeCountryMenu();
                            }}
                        >
                            {country?.name}
                        </MenuItem>
                    ))}
                    <Divider sx={{ margin: "0.5rem 0" }} />

                    {selectedCountry?.name && (
                        <MenuItem
                            onClick={() => {
                                handleCountryFilterChange("");
                                closeCountryMenu();
                            }}
                        >
                            All
                        </MenuItem>
                    )}
                </>
            )}
        </Menu>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    mb={2}
                >
                    <SoftButton
                        variant='gradient'
                        color='dark'
                        handleClick={() => navigate("/agent/add-new")}
                    >
                        New Task
                    </SoftButton>

                    <SoftBox display='flex'>
                        <SoftButton
                            variant={CountryMenu ? "contained" : "outlined"}
                            color='secondary'
                            handleClick={openCountryMenu}
                        >
                            {selectedCountry?.name || (
                                <>
                                    Filter by country&nbsp;
                                    <Icon>keyboard_arrow_down</Icon>
                                </>
                            )}
                        </SoftButton>
                        {renderCountryMenu()}
                        <SoftBox ml={1}>
                            <SoftButton variant='outlined' color='secondary'>
                                <Icon>description</Icon>
                                &nbsp;export csv
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>

                <LoadingCardSkeleton
                    isLoading={
                        taskIsLoading || (taskIsFetching && selectedCountry?.id)
                    }
                    height='333px'
                >
                    <Card>
                        <DataTable
                            table={dataTableData}
                            entriesPerPage={5}
                            canSearch
                            theData={taskList.length ? taskList : fakeTaskData}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default TaskList;
