/**
 * eslint-disable react/prop-types
 *
 * @format
 */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import { countries } from "constants/data";
import { Link } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";

const columnData = {
    columns: [
        {
            Header: "Sponsor name",
            accessor: "name",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Country",
            accessor: "country_id",
            Cell: ({ value }) => {
                const country = countries.find((c) => c.id === value);
                return <DefaultCell value={country.name ?? "N/A"} />;
            },
        },
        {
            Header: "Edit",
            accessor: "id",
            Cell: ({ value }) => (
                <Link to={`/sponsor/${value}/update`}>
                    <CreateIcon fontSize='30px' />
                </Link>
            ),
        },
    ],
};

export default columnData;
