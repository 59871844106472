const reportsBarChartData = {
  chart: {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: {
      label: "Loans",
      data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
    },
  },
  items: [
    {
      icon: { color: "primary", component: "library_books" },
      label: "Applications",
      progress: { content: "36K", percentage: 60 },
    },
    {
      icon: { color: "success", component: "touch_app" },
      label: "Given",
      progress: { content: "20K", percentage: 90 },
    },
    {
      icon: { color: "warning", component: "payment" },
      label: "Total loans",
      progress: { content: "$435K", percentage: 100 },
    },
    {
      icon: { color: "error", component: "extension" },
      label: "Paid loans",
      progress: { content: "$217.5K", percentage: 50 },
    },
  ],
};

export default reportsBarChartData;

const data = [{ loan_year: "2023", loan_month: "8", total_loans: "50" }];
