/** @format */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useNavigate } from "react-router-dom";

function IdCell({ id, checked, style, handleClick, url, routeId }) {
    const navigate = useNavigate();
    return (
        <SoftBox display='flex' alignItems='center'>
            <Checkbox defaultChecked={checked} />
            <SoftBox ml={1}>
                <SoftTypography
                    onClick={() =>
                        handleClick
                            ? navigate(`${url}/${routeId ? routeId : id}`)
                            : console.log("")
                    }
                    style={handleClick ? style : {}}
                    variant='caption'
                    fontWeight='medium'
                    color='dark'
                >
                    {id}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
}

// Setting default value for the props of IdCell
IdCell.defaultProps = {
    checked: false,
};

// Typechecking props for the IdCell
IdCell.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    handleClick: PropTypes.func,
    style: PropTypes.object,
    url: PropTypes.string,
};

export default IdCell;
