/** @format */

import React, { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import MuiAlert from "@mui/material/Alert";

// NewAgent page components

// NewAgent layout schemas for form and form feilds
// import validations from './schemas/validations';
// import form from './schema/form';
// import initialValues from './schemas/initialValues';

import validations from "./schema/validations";
import form from "./schema/form";
import initialValues from "./schema/initialValues";

// import { useCreateAgentMutation } from "features/slices/agentSlice";
import { CircularProgress, Snackbar } from "@mui/material";
// import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import AddAgentInfo from "./BasicInfo";
import { useGetAllCountriesQuery } from "features/slices/countrySlice";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

function NewAgent() {
    const { formId, formField } = form;

    const currentValidation = validations[0];
    const [open, setOpen] = useState(false);
    // const [showError, setShowError] = useState(false);
    const { data: CountriesList } = useGetAllCountriesQuery();
    // const toggleShowError = () => setShowError(!showError);

    // const handleClick = () => {
    //     setOpen(true);
    // };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const submitForm = async (values, actions) => {
        // try {
        //     const result = await createAgent(values).unwrap();
        //     if (result?.status) {
        //         actions.setSubmitting(false);
        //         handleClick();
        //         actions.resetForm();
        //     }
        // } catch (err) {
        //     actions.setSubmitting(false);
        //     toggleShowError(true);
        // }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    New agent Added Successfully!
                </Alert>
            </Snackbar>
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={submitForm}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form id={formId} autoComplete='off'>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <AddAgentInfo
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                    CountriesList={
                                                        CountriesList
                                                    }
                                                />

                                                <SoftBox
                                                    mt={2}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            ` Send`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default NewAgent;
