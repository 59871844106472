/** @format */

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useState } from "react";
import styled from "styled-components";
import Menu from "@mui/material/Menu";
import { Button, Card, Divider, Grid, Icon } from "@mui/material";
import { useParams } from "react-router-dom";
import pdfThumb from "../../../../assets/images/pdf-thumbnail.png";
import SoftBadge from "components/SoftBadge";
import MenuItem from "@mui/material/MenuItem";
import SoftButton from "components/SoftButton";
// import { loanStatus } from "constants/data";
import { fileDownloadOptions } from "constants/data";
import DataTable from "eco/Tables/DataTable";
import dataHistoryPaymentColumns from "./data/dataLoanDetailColumn";
import { useGetLoanByIdQuery } from "features/slices/loanSlice";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { loanStatus } from "constants/data";

const LoanDetails = () => {
    const { id } = useParams();
    const [selectedStatusLoan, setSelectedStatusLoan] = useState("");
    const [menu, setMenu] = useState(null);
    const [fileMenu, setFileMenu] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const { data: loan, isLoading } = useGetLoanByIdQuery(id);

    const closeFileMenu = () => setFileMenu(null);
    const openFileMenu = (event) => setFileMenu(event.currentTarget);

    const handleFileMenuChange = (file) => {
        setSelectedFile(file);
    };

    const closeMenu = () => setMenu(null);
    const openMenu = (event) => setMenu(event.currentTarget);

    const handleStatusFilterChange = (loan) => {
        setSelectedStatusLoan(loan);
    };

    const renderFileDownload = (
        <Menu
            anchorEl={fileMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(fileMenu)}
            onClose={closeFileMenu}
            keepMounted
            width={"100%"}
        >
            {fileDownloadOptions && fileDownloadOptions?.length > 0 && (
                <>
                    {fileDownloadOptions.map((file, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                handleFileMenuChange({
                                    id: file?.id || "",
                                    name: file?.name || "",
                                });
                                closeFileMenu();
                            }}
                        >
                            {file?.name}
                        </MenuItem>
                    ))}
                </>
            )}
        </Menu>
    );

    const renderStatus = (value) => {
        switch (value) {
            case "Application_Submitted":
                return (
                    <SoftBadge
                        size='xs'
                        variant='gradient'
                        color='primary'
                        badgeContent='Application Submitted'
                    />
                );
            case "Completed":
                return (
                    <SoftBadge
                        variant='gradient'
                        size='xs'
                        color='success'
                        badgeContent='completed'
                    />
                );
            case "Under_Review":
                return (
                    <SoftBadge
                        variant='gradient'
                        color='warning'
                        size='xs'
                        badgeContent='Under Review'
                    />
                );
            case "Declined":
                return (
                    <SoftBadge
                        variant='gradient'
                        color='error'
                        size='xs'
                        badgeContent='Declined'
                    />
                );
            case "Approved":
                return (
                    <SoftBadge
                        variant='gradient'
                        color='info'
                        size='xs'
                        badgeContent='Approved'
                    />
                );
            case "OnPause":
                return (
                    <SoftBadge
                        variant='gradient'
                        color='info'
                        size='xs'
                        badgeContent='On Pause'
                    />
                );
            case "Ongoing":
                return (
                    <SoftBadge
                        variant='gradient'
                        color='dark'
                        size='xs'
                        badgeContent='Ongoing'
                    />
                );
        }
    };

    const renderStatusMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
            width={"100%"}
        >
            {loanStatus && loanStatus?.length > 0 && (
                <>
                    {loanStatus.map((loan, index) => (
                        <MenuItem
                            key={loan.id}
                            id={index}
                            onClick={() => {
                                handleStatusFilterChange({
                                    id: loan?.id || "",
                                    name: loan?.status || "",
                                });
                                closeMenu();
                            }}
                        >
                            {loan?.status}
                        </MenuItem>
                    ))}
                    <Divider sx={{ margin: "0.5rem 0" }} />

                    {selectedStatusLoan?.status && (
                        <MenuItem
                            onClick={() => {
                                handleStatusFilterChange("");
                                closeMenu();
                            }}
                        >
                            All
                        </MenuItem>
                    )}
                </>
            )}
        </Menu>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox>
                <Card
                    style={{
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                    }}
                >
                    <SoftBox
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <SoftTypography variant='h5' fontWeight='bold'>
                            Loan #{id} details
                        </SoftTypography>
                    </SoftBox>

                    <Container>
                        <InfoContainer>
                            <SoftBox pt={1} pb={3} px={2}>
                                <SoftBox mb={3}>
                                    <Grid
                                        container
                                        spacing={3}
                                        alignItems='center'
                                    >
                                        <Grid item xs={12} md={6}>
                                            <SoftBox
                                                display='flex'
                                                alignItems='center'
                                            >
                                                <SoftBox lineHeight={1}>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Loan Request status:
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            {renderStatus(
                                                                loan?.request_status
                                                            )}
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='bold'
                                                            textTransform='capitalize'
                                                        >
                                                            Loan type: &nbsp;
                                                        </SoftTypography>
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='regular'
                                                            color='text'
                                                        >
                                                            &nbsp;
                                                            {
                                                                loan?.LoanType
                                                                    ?.name
                                                            }
                                                        </SoftTypography>
                                                    </SoftBox>

                                                    <SoftBox
                                                        display='flex'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='bold'
                                                            textTransform='capitalize'
                                                        >
                                                            Payment status:
                                                            &nbsp;
                                                        </SoftTypography>
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='regular'
                                                            color='text'
                                                        >
                                                            &nbsp;
                                                            {
                                                                loan?.payment_status
                                                            }
                                                        </SoftTypography>
                                                    </SoftBox>

                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Amount to pay:
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {loan?.amount_to_pay ||
                                                                    0}{" "}
                                                                FRW
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftBox>
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Amount Paid:
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {loan?.paid_amount ||
                                                                    0}
                                                                FRW
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Payment start date:
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {loan?.payment_start_date ||
                                                                    `none`}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Payment end date:
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {loan?.payment_end_date ||
                                                                    `none`}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftBox>
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Month to pay:
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {
                                                                    loan?.months_to_pay
                                                                }
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftBox>
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Loan rate:
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {parseInt(
                                                                    loan?.loan_rate
                                                                ) || 0}
                                                                %
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>

                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftBox>
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Currency:
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                FRW
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='bold'
                                                            textTransform='capitalize'
                                                        >
                                                            Farmer name: &nbsp;
                                                        </SoftTypography>
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='regular'
                                                            color='text'
                                                        >
                                                            &nbsp;
                                                            {
                                                                loan?.Farmer
                                                                    ?.full_name
                                                            }
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='bold'
                                                            textTransform='capitalize'
                                                        >
                                                            Farmer Location:
                                                            &nbsp;
                                                        </SoftTypography>
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='regular'
                                                            color='text'
                                                        >
                                                            &nbsp;
                                                            {
                                                                loan?.Farmer
                                                                    ?.location_name
                                                            }
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        py={1}
                                                        pr={2}
                                                    >
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='bold'
                                                            textTransform='capitalize'
                                                        >
                                                            Farmer Phone: &nbsp;
                                                        </SoftTypography>
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='regular'
                                                            color='text'
                                                        >
                                                            &nbsp;
                                                            {
                                                                loan?.Farmer
                                                                    ?.phone
                                                            }
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <SoftBox>
                                                <Subcontainer>
                                                    <PdfContainer>
                                                        <PdfImage
                                                            src={pdfThumb}
                                                            alt='pdf'
                                                        />
                                                    </PdfContainer>
                                                    <SoftBox display='flex'>
                                                        <SoftButton
                                                            variant={
                                                                menu
                                                                    ? "contained"
                                                                    : "outlined"
                                                            }
                                                            color='secondary'
                                                            handleClick={
                                                                openFileMenu
                                                            }
                                                        >
                                                            {selectedFile?.name || (
                                                                <>
                                                                    Filter by
                                                                    status&nbsp;
                                                                    <Icon>
                                                                        keyboard_arrow_down
                                                                    </Icon>
                                                                </>
                                                            )}
                                                        </SoftButton>
                                                        {renderFileDownload}
                                                    </SoftBox>
                                                    {selectedFile && (
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            variant='contained'
                                                        >
                                                            download
                                                        </Button>
                                                    )}
                                                </Subcontainer>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </SoftBox>
                        </InfoContainer>
                    </Container>
                </Card>
                <Card
                    style={{
                        marginTop: "1rem",
                        padding: "1rem",
                    }}
                >
                    <SoftBox mb={3}>
                        <SoftBox mb={3}>
                            <SoftTypography variant='h6' fontWeight='bold'>
                                Payment Histories
                            </SoftTypography>
                        </SoftBox>
                        <LoadingCardSkeleton isLoading={isLoading}>
                            <DataTable
                                table={dataHistoryPaymentColumns}
                                entriesPerPage={false}
                                canSearch={true}
                                theData={loan?.payment_history}
                            />
                        </LoadingCardSkeleton>
                    </SoftBox>
                </Card>
            </SoftBox>
        </DashboardLayout>
    );
};

const Container = styled.div`
    padding: 20px 40px;
    display: flex;
    gap: 1rem;
`;

const InfoContainer = styled.div`
    flex: 1;
`;

const Subcontainer = styled.div`
    max-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const PdfContainer = styled.div`
    max-width: 250px;
    height: 250px;
    border-radius: 10px;
`;

const PdfImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export default LoanDetails;
