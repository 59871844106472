/** @format */

const form = {
    formId: "change-password",
    formField: {
        password: {
            name: "password",
            label: "New password",
            type: "password",
            placeholder: "******",
            errorMsg: "Password is required.",
            invalidMsg: "Your password should be more than 6 characters.",
        },
        repeatPassword: {
            name: "repeatPassword",
            label: "Repeat password",
            type: "password",
            placeholder: "******",
            errorMsg: "Password is required.",
            invalidMsg: "Your password doesn't match.",
        },
    },
};

export default form;
