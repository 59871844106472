// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import TableCell from "@mui/material/TableCell";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

//Routing
import { useNavigate } from "react-router-dom";
import SoftButton from "components/SoftButton";

function CountryTableCell({
  title,
  content,
  image,
  noBorder,
  handleClick,
  select,
  ...rest
}) {
  let template;
  const navigate = useNavigate();

  if (image) {
    template = (
      <TableCell
        {...rest}
        align="left"
        width="30%"
        sx={{ border: noBorder && 0 }}>
        <SoftButton
          variant="text"
          // handleClick={() =>
          //   title === "Country"
          //     ? navigate(`/dashboard/country/${content}`)
          //     : null
          // }
          handleClick={() => handleClick(select)}
          // handleClick={() => console.log(select, "the title and content")}
          display="flex"
          alignItems="center"
          width="max-content">
          <SoftBox
            component="img"
            src={image}
            alt={content}
            width="1.5rem"
            height="auto"
          />{" "}
          <SoftBox display="flex" flexDirection="column" ml={3}>
            <SoftTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              textTransform="capitalize">
              {title}:
            </SoftTypography>
            <SoftTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize">
              {content}
            </SoftTypography>
          </SoftBox>
        </SoftButton>
      </TableCell>
    );
  } else {
    template = (
      <TableCell {...rest} align="center" sx={{ border: noBorder && 0 }}>
        <SoftBox
          onClick={() =>
            title === "country"
              ? navigate(`/dashboard/country/${content}`)
              : null
          }
          display="flex"
          flexDirection="column">
          <SoftTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            textTransform="capitalize">
            {title}:
          </SoftTypography>
          <SoftTypography
            variant="button"
            fontWeight="medium"
            textTransform="capitalize">
            {content}
          </SoftTypography>
        </SoftBox>
      </TableCell>
    );
  }

  return template;
}

// Setting default values for the props of CountryTableCell
CountryTableCell.defaultProps = {
  image: "",
  noBorder: false,
};

// Typechecking props for the CountryTableCell
CountryTableCell.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default CountryTableCell;
