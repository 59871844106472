/** @format */

import Footer from "eco/Footer";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import form from "./schema/form";
import validations from "./schema/validations";
import MuiAlert from "@mui/material/Alert";
import { CircularProgress, Grid, Snackbar, Card } from "@mui/material";
import { Form, Formik } from "formik";
import SoftBox from "components/SoftBox";
import initialValues from "./schema/initialValues";
import ProjectBaseInfo from "./ProjectBaseInfo";
import SoftButton from "components/SoftButton";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import { useUpdateProjectMutation } from "features/slices/projectSlice";
import { useGetProjectByIdQuery } from "features/slices/projectSlice";
import { countries } from "constants/data";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const UpdateProject = () => {
    const { id } = useParams();
    const [updateProject, { isLoading, isError, error }] =
        useUpdateProjectMutation();
    const { data: project, isLoading: projectLoading } =
        useGetProjectByIdQuery(id);
    const { formId, formField } = form;

    const currentValidation = validations[0];
    const [open, setOpen] = React.useState(false);
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(!showError);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const submitForm = async (values, actions) => {
        console.log(values);
        try {
            const result = await updateProject({ values, id }).unwrap();
            if (result?.status) {
                actions.setSubmitting(false);
                handleClick();
                actions.resetForm();
            }
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    New project created Successfully!
                </Alert>
            </Snackbar>
            {!projectLoading && (
                <SoftBox py={3} mb={20}>
                    <Grid
                        container
                        justifyContent='center'
                        sx={{ height: "100%" }}
                    >
                        <Grid item xs={12} lg={8}>
                            <Formik
                                initialValues={{
                                    ...initialValues,
                                    project_name: project?.data?.project_name,
                                }}
                                validationSchema={currentValidation}
                                onSubmit={submitForm}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <Form id={formId} autoComplete='off'>
                                        <Card sx={{ height: "100%" }}>
                                            <SoftBox p={2}>
                                                <SoftBox>
                                                    <ProjectBaseInfo
                                                        formData={{
                                                            values,
                                                            touched,
                                                            formField,
                                                            errors,
                                                            setFieldValue,
                                                        }}
                                                        CountriesList={
                                                            countries
                                                        }
                                                    />

                                                    <SoftBox
                                                        mt={2}
                                                        width='100%'
                                                        display='flex'
                                                        justifyContent='space-between'
                                                    >
                                                        <SoftButton
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            type='submit'
                                                            variant='gradient'
                                                            color='dark'
                                                        >
                                                            {isSubmitting ? (
                                                                <CircularProgress
                                                                    size={24}
                                                                    style={{
                                                                        color: "#ffffff",
                                                                    }}
                                                                />
                                                            ) : (
                                                                ` Send`
                                                            )}
                                                        </SoftButton>
                                                    </SoftBox>

                                                    {showError &&
                                                        !isLoading &&
                                                        isError && (
                                                            <ErrorMessageResponse>
                                                                {
                                                                    error?.data
                                                                        ?.message
                                                                }
                                                            </ErrorMessageResponse>
                                                        )}
                                                </SoftBox>
                                            </SoftBox>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </SoftBox>
            )}

            <Footer />
        </DashboardLayout>
    );
};

export default UpdateProject;
