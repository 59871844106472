/** @format */

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useAddProjectForestMutation } from "features/slices/projectSlice";
import MuiAlert from "@mui/material/Alert";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import validations from "./schema/validations";
import form from "./schema/form";
import { Card, CircularProgress, Grid, Snackbar } from "@mui/material";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import ProjectForestForm from "./ProjectForestForm";
import { Formik, Form } from "formik";
import initialValues from "./schema/initialValues";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const CreateProjectForest = () => {
    const { id, country, organization } = useParams();
    const [createProjectForest, { isLoading, isError, error }] =
        useAddProjectForestMutation();
    const { formId, formField } = form;

    const [location, setLocation] = useState([]);

    const navigate = useNavigate();

    const currentValidation = validations[0];
    const [open, setOpen] = React.useState(false);
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(!showError);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const submitForm = async (values, actions) => {
        const forestValues = {
            ...values,
            country_id: country,
            organization_id: organization,
            project_id: id,
            location_name: location.join(", "),
        };

        try {
            const result = await createProjectForest(forestValues).unwrap();
            if (result?.status) {
                actions.setSubmitting(false);
                handleClick();
                actions.resetForm();
            }
            setTimeout(() => {
                navigate(`/project/${id}/details`);
            }, 2000);
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    Project forest created Successfully!
                </Alert>
            </Snackbar>
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={submitForm}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form id={formId} autoComplete='off'>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <ProjectForestForm
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                    country_id={country}
                                                    id={id}
                                                    location={location}
                                                    setLocation={setLocation}
                                                />

                                                <SoftBox
                                                    mt={2}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            ` Send`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {showError &&
                                                    !isLoading &&
                                                    isError && (
                                                        <ErrorMessageResponse>
                                                            {
                                                                error?.data
                                                                    ?.message
                                                            }
                                                        </ErrorMessageResponse>
                                                    )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
        </DashboardLayout>
    );
};

export default CreateProjectForest;
