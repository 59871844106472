import * as Yup from 'yup';
import formElements from './form';

const {
  formField: { name, political_name, phone_code, money_code, h_to_cover, code },
} = formElements;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [code.name]: Yup.string().required(code.errorMsg),
    [phone_code.name]: Yup.number().required(phone_code.errorMsg),
    [political_name.name]: Yup.string().required(political_name.errorMsg),
    [money_code.name]: Yup.string().required(money_code.errorMsg),
    [h_to_cover.name]: Yup.number().required(h_to_cover.errorMsg),
  }),
];

export default validations;
