/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

export const sponsorApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllSponsors: builder.query({
            query: () => {
                return "sponsors";
            },
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000,
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ["sponsors"],
        }),
        getSponsorById: builder.query({
            query: (id) => {
                return `sponsors/${id}`;
            },
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000,
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ["sponsors"],
        }),
        createSponsor: builder.mutation({
            query: (values) => {
                return {
                    url: "sponsors",
                    method: "POST",
                    body: values,
                };
            },
            invalidatesTags: ["sponsors"],
        }),
        updateSponsor: builder.mutation({
            query: ({ values, id }) => {
                return {
                    url: `sponsors/${id}`,
                    method: "PATCH",
                    body: values,
                };
            },
            invalidatesTags: ["sponsors"],
        }),
    }),
});

export const {
    useGetAllSponsorsQuery,
    useGetSponsorByIdQuery,
    useCreateSponsorMutation,
    useUpdateSponsorMutation,
} = sponsorApi;

const sponsorSlice = createSlice({
    name: "sponsor",
    initialState: {
        sponsor: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            sponsorApi.endpoints.getAllSponsors.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
        builder.addMatcher(
            sponsorApi.endpoints.createSponsor.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
        builder.addMatcher(
            sponsorApi.endpoints.getSponsorById.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
        builder.addMatcher(
            sponsorApi.endpoints.updateSponsor.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
    },
});

export const sponsorReducer = sponsorSlice.reducer;

export default sponsorSlice;
