/** @format */

// @mui core components
import React from "react";

import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

// Settings page components
import FormField from "components/FormField";
import { useGetAllOrganizationsQuery } from "features/slices/organizationSlice";
import { useGetAllSponsorsQuery } from "features/slices/sponsorSlice";

function ProjectBaseInfo({ formData, CountriesList }) {
    const { formField, values, errors, touched, setFieldValue } = formData;
    const { data: organizations } = useGetAllOrganizationsQuery();

    const { data: sponsors } = useGetAllSponsorsQuery();
    return (
        <>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>Project</SoftTypography>
            </SoftBox>
            <SoftBox component='form' pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.project_name.type}
                            label={formField.project_name.label}
                            name={formField.project_name.name}
                            value={values.project_name}
                            placeholder={formField.project_name.placeholder}
                            error={errors.project_name && touched.project_name}
                            success={
                                values?.project_name?.length > 0 &&
                                !errors?.project_name
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Country
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.country_id}
                            error={errors.country_id && touched.country_id}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.country_id.name,
                                    event.target.value
                                );
                            }}
                        >
                            {formField.country_id.options?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Sponsor
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.sponsor} // Set default to the first option's ID
                            error={errors.sponsor && touched.sponsor}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.sponsor.name,
                                    event.target.value
                                );
                            }}
                        >
                            {sponsors?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Organization
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.organization_id} // Set default to the first option's ID
                            error={
                                errors.organization_id &&
                                touched.organization_id
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.organization_id.name,
                                    event.target.value
                                );
                            }}
                        >
                            {organizations?.data?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
}

export default ProjectBaseInfo;
