/** @format */

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";

import Footer from "eco/Footer";

// General page components
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";

// Data
import { useParams } from "react-router-dom";
import { useGetTreesByForestIdQuery } from "features/slices/forest";
import Map from "components/GoodMap";

function ForestTrees() {
    const { id } = useParams();

    const {
        data: trees,
        isLoading: treesIsFetching,
        isSuccess: treesIsSuccess,
    } = useGetTreesByForestIdQuery(id, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mt={3}>
                <LoadingCardSkeleton isLoading={treesIsFetching} height='193px'>
                    <Grid container spacing={3}>
                        {treesIsSuccess &&
                        trees.length > 0 &&
                        trees[0]?.forest_trees?.length > 0 ? (
                            <Map data={trees[0]?.forest_trees} />
                        ) : null}
                    </Grid>
                </LoadingCardSkeleton>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ForestTrees;
