/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

// Farmer API endpoints
export const agentApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllAgents: builder.query({
            query: () => "agents",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["agents"],
        }),
        getAgentById: builder.query({
            query: (id) => `agents/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["agents"],
        }),
        getAgentStatistics: builder.query({
            query: () => "agents/statistics",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getAgentStatisticsById: builder.query({
            query: (id) => `supports/agent/${id}/statistics`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getAgentOTP: builder.query({
            query: (phone) => `agents/resend-otp/${phone}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        sendAgentPassword: builder.mutation({
            query: (id) => {
                return {
                    url: `agents/resend-password/${id}`,
                    method: "PUT",
                };
            },
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
    }),
});

export const {
    useGetAllAgentsQuery,
    useGetAgentByIdQuery,
    useGetAgentStatisticsQuery,
    useGetAgentOTPQuery,
    useGetAgentStatisticsByIdQuery,
    useSendAgentPasswordMutation,
} = agentApi;

// Agent slice
const agentSlice = createSlice({
    name: "agent",
    initialState: {
        agent: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            agentApi.endpoints.getAllAgents.matchFulfilled,
            (state, action) => {
                state.agent = action.payload;
            }
        );
        builder.addMatcher(
            agentApi.endpoints.getAgentById.matchFulfilled,
            (state, action) => {
                state.agent = action.payload;
            }
        );
        builder.addMatcher(
            agentApi.endpoints.getAgentStatistics.matchFulfilled,
            (state, action) => {
                state.agent = action.payload;
            }
        );
        builder.addMatcher(
            agentApi.endpoints.getAgentOTP.matchFulfilled,
            (state, action) => {
                state.agent = action.payload;
            }
        );
        builder.addMatcher(
            agentApi.endpoints.sendAgentPassword.matchFulfilled,
            (state, action) => {
                state.agent = action.payload;
            }
        );
    },
});

export const agentReducer = agentSlice.reducer;

export default agentSlice;
