/** @format */

import { useState } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

//  components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import ecoLogo from "assets/images/ecoforest--logo-white.png";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import ErrorMessageDisplay from "components/ErrorMessage/ErrorMessageForm";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Formik
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import { useLoginUserMutation } from "features/slices/userSlice";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import useAuth from "context/useAuth";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("must be a valid email address")
        .required("Email is Required"),
    password: Yup.string().required("Password is Required"),
});

function SignInIllustration() {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [rememberMe, setRememberMe] = useState(false);
    const [showError, setShowError] = useState(false);

    const [loginUser, { isLoading, isError, error }] = useLoginUserMutation();

    const handleSetRememberMe = () => setRememberMe(!rememberMe);
    const toggleShowError = () => setShowError(!showError);

    return (
        <IllustrationLayout
            title='Sign In'
            description='Enter your Email and password to Sign in'
            illustration={{
                image: ecoLogo,
                title: '"Welcome to Ecoforest"',
                description:
                    "Stay powerful and win the battle against climate change with the new transformative technology.",
            }}
        >
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const response = await loginUser(values).unwrap();
                        if (response && response?.status) {
                            setAuth(response?.token);
                            navigate(from, { replace: true });
                        }
                    } catch (err) {
                        toggleShowError(true);
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, errors }) => (
                    <Form>
                        <SoftBox>
                            <SoftBox mb={2}>
                                <Field
                                    type='Email'
                                    name='email'
                                    as={SoftInput}
                                    placeholder='e.g johndoe@gmail.com'
                                    size='large'
                                />
                                <ErrorMessageDisplay
                                    errors={errors}
                                    field='email'
                                    errorColor='#f44336'
                                    fontSize='14px'
                                    spacing='8px'
                                />
                            </SoftBox>
                            <SoftBox mb={2}>
                                <Field
                                    type='password'
                                    name='password'
                                    as={SoftInput}
                                    placeholder='Password'
                                    size='large'
                                />
                                <ErrorMessageDisplay
                                    errors={errors}
                                    field='password'
                                    errorColor='#f44336'
                                    fontSize='14px'
                                    spacing='8px'
                                />
                            </SoftBox>
                            <SoftBox display='flex' alignItems='center'>
                                <Switch
                                    checked={rememberMe}
                                    onChange={handleSetRememberMe}
                                />
                                <SoftTypography
                                    variant='button'
                                    fontWeight='regular'
                                    onClick={handleSetRememberMe}
                                    sx={{
                                        cursor: "pointer",
                                        userSelect: "none",
                                    }}
                                >
                                    &nbsp;&nbsp;Remember me
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox mt={4} mb={1}>
                                <SoftButton
                                    type='submit'
                                    variant='gradient'
                                    color='info'
                                    size='large'
                                    fullWidth
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            size={24}
                                            style={{ color: "#ffffff" }}
                                        />
                                    ) : (
                                        ` sign in`
                                    )}
                                </SoftButton>
                            </SoftBox>

                            {showError && !isLoading && isError && (
                                <ErrorMessageResponse>
                                    {error?.data?.message}
                                </ErrorMessageResponse>
                            )}
                        </SoftBox>
                    </Form>
                )}
            </Formik>

            <SoftBox display='flex' alignItems='center' justifyContent='end'>
                <SoftTypography
                    variant='button'
                    fontWeight='regular'
                    color='info'
                    onClick={() =>
                        navigate("/reset-password", { replace: true })
                    }
                    sx={{ cursor: "pointer", userSelect: "none" }}
                >
                    Reset password
                </SoftTypography>
            </SoftBox>
        </IllustrationLayout>
    );
}

export default SignInIllustration;
