/** @format */

import { apiSlice } from "features/api/apiSliceV1";
import { createSlice } from "@reduxjs/toolkit";

const releaseForestApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getForestRelease: builder.query({
            query: () => `/forest-release/`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["forest-release"],
        }),
        getForestReleaseById: builder.query({
            query: (id) => `/forest-release/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["forest-release"],
        }),
    }),
});

const releaseForestSlice = createSlice({
    name: "forestRelease",
    initialState: {
        forestRelease: null,
    },
    reducers: {},
    extraReducers: (builder) => {},
});

export const { useGetForestReleaseQuery, useGetForestReleaseByIdQuery } =
    releaseForestApi;

export default releaseForestSlice;
