import React from "react";
import styled, { keyframes } from "styled-components";

const slideDown = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ErrorContainer = styled.div`
  border: 1px solid #e57373;
  background-color: #fce8e6;
  color: #d32f2f;
  padding: 10px;
  border-radius: 4px;
  margin-top: 8px;
  font-size: 14px;
  animation: ${slideDown} 0.3s ease-in-out;

  /* Add more styling to enhance the look */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;

  svg {
    font-size: 18px;
  }
`;

const ErrorMessageResponse = ({ children }) => {
  return <ErrorContainer>{children}</ErrorContainer>;
};

export default ErrorMessageResponse;
