/** @format */

import React from "react";
import LoanStatusTable from "../LoanStatusTable";

const DeclinedLoans = () => {
    return <LoanStatusTable status={"Declined"} title={"Declined loans"} />;
};

export default DeclinedLoans;
