/** @format */

import * as Yup from "yup";
import form from "./form";

const {
    formField: {
        name,
        contact_phone,
        province,
        person_contact,
        contact_email,
        headline,
        sub_text,
        cta,
        currency,
        currency_symbol,
        price_per_tree,
        rate,
        website,
        district,
        cell,
        sector,
        village,
        country_id,
    },
} = form;

const validations = [
    Yup.object().shape({
        [name.name]: Yup.string(),
        [person_contact.name]: Yup.string(),
        [contact_email.name]: Yup.string()
            .email()
            .required(contact_email.errorMsg),
        [contact_phone.name]: Yup.string().matches(
            /^[0-9]{10}$/,
            "Phone number must be exactly 10 digits"
        ),
        [website.name]: Yup.string(),
        [headline.name]: Yup.string(),
        [sub_text.name]: Yup.string(),
        [cta.name]: Yup.string(),
        [currency.name]: Yup.string(),
        [currency_symbol.name]: Yup.string(),
        [price_per_tree.name]: Yup.number().min(1, "price must greater than 0"),
        [rate.name]: Yup.number()
            .min(0, "rate must be greater than 0")
            .max(100, "rate must be less than 100"),
        [cell.name]: Yup.string(),
        [district.name]: Yup.string(),
        [sector.name]: Yup.string(),
        [village.name]: Yup.string(),
        [province.name]: Yup.string(),
        [country_id.name]: Yup.string(),
    }),
];

export default validations;
