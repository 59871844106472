/** @format */

import React from "react";
// prop-type is a library for typechecking of props

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "../FormField";

const ChangePasswordInfo = ({ formData }) => {
    const { formField, values, errors, touched } = formData;
    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant='h5' fontWeight='bold'>
                    Change password
                </SoftTypography>
            </SoftBox>
            <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.password.type}
                            label={formField.password.label}
                            name={formField.password.name}
                            value={values.password}
                            placeholder={formField.password.placeholder}
                            error={errors.password && touched.password}
                            success={
                                values?.password?.length > 0 &&
                                !errors?.password
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.repeatPassword.type}
                            label={formField.repeatPassword.label}
                            name={formField.repeatPassword.name}
                            value={values.repeatPassword}
                            placeholder={formField.repeatPassword.placeholder}
                            error={
                                errors.repeatPassword && touched.repeatPassword
                            }
                            success={
                                values?.repeatPassword?.length > 0 &&
                                !errors?.repeatPassword
                            }
                        />
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
};

export default ChangePasswordInfo;
