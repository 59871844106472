/** @format */

import { useEffect, useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Doughnut } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import ReportsDoughnutChartItem from "eco/Charts/DoughnutCharts/ReportsDoughnutChart/ReportsDoughnutChartItem";

// ReportsDoughnutChart configurations
import configs from "eco/Charts/DoughnutCharts/ReportsDoughnutChart/configs";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";

function ReportsDoughnutChart({
    title,
    count,
    chart,
    tooltip,
    FarmersInformation,
    loading,
}) {
    const [chartData, setChartData] = useState(null);

    const reportsDoughnutChartData = (FarmersInformation) => {
        // Create the labels and data arrays
        const labels = [
            "No Loans",
            "Application submitted",
            "Loans under review",
            "On Going",
            "Declined",
        ];

        const datas = [
            parseFloat(FarmersInformation?.No_Loans),
            parseFloat(FarmersInformation?.Application_Submitted),
            parseFloat(FarmersInformation?.Under_Review),
            parseFloat(FarmersInformation?.Ongoing),
            parseFloat(FarmersInformation?.Declined),
        ];

        // Define the colors for each dataset
        const backgroundColors = [
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
        ];

        // Create the final structure
        const chartData = {
            labels: labels,
            datasets: {
                label: "Farmers status",
                backgroundColors: backgroundColors,
                data: datas,
            },
        };

        setChartData(chartData);
    };

    const { data, options } = configs(
        chartData?.labels || [],
        chartData?.datasets || {}
    );

    const renderItems =
        chartData?.labels && chartData?.datasets
            ? chartData?.labels.map((label, key) => (
                  <ReportsDoughnutChartItem
                      color={
                          chartData?.datasets.backgroundColors
                              ? chartData?.datasets.backgroundColors[key]
                              : "dark"
                      }
                      title={label}
                      key={label}
                      percentage={`${
                          chartData?.datasets.data
                              ? chartData?.datasets.data[key]
                              : 0
                      }`}
                      hasBorder={key !== chartData?.labels.length - 1}
                  />
              ))
            : null;

    useEffect(() => {
        if (FarmersInformation) reportsDoughnutChartData(FarmersInformation);
    }, [FarmersInformation]);
    const DoughnutContent = useMemo(
        () => (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                    <SoftBox
                        height='100%'
                        textAlign='center'
                        position='relative'
                    >
                        <SoftBox
                            height={{ xs: "65%", sm: "100%" }}
                            mt={{ xs: 6, sm: 0 }}
                        >
                            <Doughnut data={data} options={options} />
                        </SoftBox>
                        <SoftBox
                            mt={{ xs: 0, sm: -15.25 }}
                            position='relative'
                            top={{ xs: "-8.25rem", sm: 0 }}
                        >
                            <SoftTypography variant='h4' fontWeight='medium'>
                                {count.number}
                            </SoftTypography>
                            <SoftTypography
                                variant='button'
                                color='text'
                                textTransform='uppercase'
                                fontWeight='medium'
                            >
                                {count.text}
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={7}>
                    {renderItems}
                </Grid>
            </Grid>
        ),
        [count.number, count.text, data, options, renderItems]
    );

    return (
        <LoadingCardSkeleton isLoading={loading} height='270px'>
            <Card>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    pt={2}
                    px={2}
                >
                    <SoftTypography variant='h6'>{title}</SoftTypography>
                    <Tooltip title={tooltip} placement='bottom' arrow>
                        <SoftButton
                            variant='outlined'
                            color='secondary'
                            size='small'
                            circular
                            iconOnly
                        >
                            <Icon>priority_high</Icon>
                        </SoftButton>
                    </Tooltip>
                </SoftBox>
                <SoftBox p={2}>{DoughnutContent}</SoftBox>
            </Card>
        </LoadingCardSkeleton>
    );
}

// Setting default values for the props of ReportsDoughnutChart
ReportsDoughnutChart.defaultProps = {
    tooltip: "",
};

// Typechecking props for the ReportsDoughnutChart
ReportsDoughnutChart.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.shape({
        number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string.isRequired,
    }).isRequired,
    chart: PropTypes.shape({
        labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        datasets: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.array,
                PropTypes.string,
            ])
        ).isRequired,
    }).isRequired,
    tooltip: PropTypes.string,
};

export default ReportsDoughnutChart;
