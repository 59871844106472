/** @format */

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import DataTable from "eco/Tables/DataTable";

// Data
import dataTableData from "./data/dataTableData";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "features/slices/userSlice";
import { useGetAllCountriesQuery } from "features/slices/countrySlice";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";

function Users() {
    const [selectedCountry, setSelectedCountry] = useState("");

    const { data: CountriesList } = useGetAllCountriesQuery();
    const {
        data: usersData,
        isLoading: usersIsLoading,
        isFetching: usersIsFetching,
    } = useGetUsersQuery(selectedCountry?.id, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });
    const navigate = useNavigate();

    const [CountryMenu, setCountryMenu] = useState(null);

    const openCountryMenu = (event) => setCountryMenu(event.currentTarget);
    const closeCountryMenu = () => setCountryMenu(null);
    // Function to handle country filter change
    const handleCountryFilterChange = (country) => {
        setSelectedCountry(country);
    };

    const renderCountryMenu = (
        <Menu
            anchorEl={CountryMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(CountryMenu)}
            onClose={closeCountryMenu}
            keepMounted
        >
            {CountriesList && CountriesList?.length > 0 && (
                <>
                    {CountriesList.map((country, index) => (
                        <MenuItem
                            id={index}
                            onClick={() => {
                                handleCountryFilterChange({
                                    id: country?.id || "",
                                    name: country?.name || "",
                                });
                                closeCountryMenu();
                            }}
                        >
                            {country?.name}
                        </MenuItem>
                    ))}
                    <Divider sx={{ margin: "0.5rem 0" }} />

                    {selectedCountry?.name && (
                        <MenuItem
                            onClick={() => {
                                handleCountryFilterChange("");
                                closeCountryMenu();
                            }}
                        >
                            All
                        </MenuItem>
                    )}
                </>
            )}
        </Menu>
    );
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    mb={2}
                >
                    <SoftButton
                        variant='gradient'
                        color='info'
                        handleClick={() => {
                            navigate("/users/add-new");
                        }}
                    >
                        New user
                    </SoftButton>

                    {/* <SoftBox display='flex'>
                        <SoftButton
                            variant={CountryMenu ? "contained" : "outlined"}
                            color='secondary'
                            handleClick={openCountryMenu}
                        >
                            {selectedCountry?.name || (
                                <>
                                    Filter by country&nbsp;
                                    <Icon>keyboard_arrow_down</Icon>
                                </>
                            )}
                        </SoftButton>
                        {renderCountryMenu}

                        <SoftBox ml={1}>
                            <SoftButton variant='outlined' color='secondary'>
                                <Icon>description</Icon>
                                &nbsp;export csv
                            </SoftButton>
                        </SoftBox>
                    </SoftBox> */}
                </SoftBox>

                <LoadingCardSkeleton
                    isLoading={
                        usersIsLoading ||
                        (usersIsFetching && selectedCountry?.id)
                    }
                    height='333px'
                >
                    <Card>
                        <DataTable
                            table={dataTableData}
                            entriesPerPage={false}
                            canSearch
                            theData={usersData}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Users;
