/** @format */

import * as Yup from "yup";
import form from "./form";

const {
    formField: {
        name,
        contact_phone,
        province,
        person_contact,
        contact_email,
        headline,
        sub_text,
        cta,
        currency,
        currency_symbol,
        price_per_tree,
        rate,
        website,
        district,
        cell,
        sector,
        village,
        country_id,
    },
} = form;

const validations = [
    Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [person_contact.name]: Yup.string().required(person_contact.errorMsg),
        [contact_email.name]: Yup.string()
            .email()
            .required(contact_email.errorMsg),
        [contact_phone.name]: Yup.string()
            .required(contact_phone.errorMsg)
            .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
        [headline.name]: Yup.string().required(headline.errorMsg),
        [sub_text.name]: Yup.string().required(sub_text.errorMsg),
        [cta.name]: Yup.string().required(cta.errorMsg),
        [currency.name]: Yup.string().required(currency.errorMsg),
        [currency_symbol.name]: Yup.string().required(currency_symbol.errorMsg),
        [price_per_tree.name]: Yup.number()
            .min(1, "price must greater than 0")
            .required(price_per_tree.errorMsg),
        [rate.name]: Yup.number()
            .min(0, "rate must be greater than 0")
            .max(100, "rate must be less than 100")
            .required(rate.errorMsg),
        [website.name]: Yup.string().required(website.errorMsg),
        [cell.name]: Yup.string().required("This field is required"),
        [district.name]: Yup.string().required("This field is required"),
        [sector.name]: Yup.string().required("This field is required"),
        [village.name]: Yup.string().required("This field is required"),
        [province.name]: Yup.string().required("This field is required"),
        [country_id.name]: Yup.string().required(country_id.errorMsg),
    }),
];

export default validations;
