const form = {
  formId: "new-agent-form",
  formField: {
    first_name: {
      name: "first_name",
      label: "First Name",
      type: "text",
      placeholder: "e.g. John",
      errorMsg: "First name is required.",
    },
    last_name: {
      name: "last_name",
      label: "Last Name",
      type: "text",
      placeholder: "e.g. Doe",
      errorMsg: "Last name is required.",
    },

    dob: {
      name: "dob",
      label: "Date of birth",
      type: "date",
      placeholder: "",
      errorMsg: "Date of birth is required.",
      invalidMsg: "Date of birth is invalid",
    },
    whatsapp_phone: {
      name: "whatsapp_phone",
      label: "Whatsapp phone",
      type: "string",
      placeholder: "eg. 0788xxxxxx",
      errorMsg: "whatsapp phone is required.",
      invalidMsg: "Your Whatsapp phone is invalid",
    },
    momo_phone: {
      name: "momo_phone",
      label: "Momo phone",
      type: "string",
      placeholder: "eg. 0788xxxxxx",
      errorMsg: "Password is required.",
    },
    education: {
      name: "education",
      label: "education",
      type: "select",
      options: [
        { id: "primary", name: "Primary" },
        { id: "secondary", name: "Secondary" },
        { id: "university", name: "University" },
      ],
      errorMsg: "Education is required.",
    },
    location_name: {
      name: "location_name",
      label: "Location",
      type: "text",
      placeholder: "e.g. Kigali, Rwanda, Kimironko",
      errorMsg: "Location is required.",
    },
    country_id: {
      name: "country_id",
      label: "Country",
      type: "select",
      options: [
        { id: "1", name: "Rwanda" },
        { id: "2", name: "Burundi" },
        { id: "3", name: "Kenya" },
        { id: "4", name: "Tanzania" },
      ],
      errorMsg: "Country is required.",
    },
    level: {
      name: "level",
      label: "Agent level",
      type: "select",
      options: [
        { id: "province", name: "Province" },
        { id: "district", name: "District" },
        { id: "sector", name: "Sector" },
        { id: "cell", name: "Cell" },
        { id: "village", name: "Village" },
      ],
      errorMsg: "Level is required.",
      invalidMsg: "Level can't be more than 5 and less than 1",
    },
    province: {
      name: "province",
      label: "Province",
      type: "select",
      options: [
        { id: "1", name: "Kigali" },
        { id: "2", name: "East" },
        { id: "3", name: "West" },
      ],
      errorMsg: "This field is required.",
      invalidMsg: "Level can't be more than 5 and less than 1",
    },
    district: {
      name: "district",
      label: "District",
      type: "select",
      options: [
        { id: "1", name: "District 1" },
        { id: "2", name: "District 2" },
        { id: "3", name: "District 3" },
      ],
      errorMsg: "This field is required.",
    },
    sector: {
      name: "sector",
      label: "Sector",
      type: "select",
      options: [
        { id: "1", name: "Sector 1" },
        { id: "2", name: "Sector 2" },
        { id: "1", name: "Sector 3" },
      ],
      errorMsg: "This field is required.",
    },
    cell: {
      name: "cell",
      label: "Cell",
      type: "select",
      options: [
        { id: "1", name: "Cell 1" },
        { id: "2", name: "Cell 2" },
        { id: "3", name: "Cell 3" },
      ],
      errorMsg: "This field is required.",
    },
    village: {
      name: "village",
      label: "Vilage",
      type: "select",
      options: [
        { id: "1", name: "Village 1" },
        { id: "2", name: "Village 2" },
        { id: "3", name: "Village 3" },
      ],
      errorMsg: "This field is required.",
    },
  },
};

export default form;
