import { Skeleton } from "@mui/material";

function LoadingCardSkeleton({
  variant = "rectangular",
  height = "290px",
  animation = "pulse",
  children,
  skeletonStyles = {},
  isLoading,
}) {
  return (
    <div>
      {isLoading ? (
        <Skeleton
          variant={variant}
          height={height}
          animation={animation}
          style={{
            backgroundColor: "#ffffff",
            backgroundClip: "border-box",
            border: "0 solid rgba(0, 0, 0, 0.125)",
            borderRadius: "1rem",
            boxShadow: "0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            ...skeletonStyles, // Allow additional styles to be passed via props
          }}
        />
      ) : (
        children // Render the content when loading is false
      )}
    </div>
  );
}

export default LoadingCardSkeleton;
