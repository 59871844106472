/** @format */

import { apiSlice } from "features/api/apiSliceV1";
import { createSlice } from "@reduxjs/toolkit";

const paymentApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllPayment: builder.query({
            query: () => `/impacts/payments/all`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getPaymentByDateRange: builder.query({
            query: ({ id, startDate, endDate }) => {
                let url = `/impacts/payments/from/${startDate}/to/${endDate}/org/${id}`;
                if (!startDate || !endDate) {
                    url = `/impacts/payments/org/${id}`;
                }

                return url;
            },
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getPaymentByOrganization: builder.query({
            query: ({ id, startDate, endDate }) => {
                let url = `/impacts/payments/org/${id}`;
                if (startDate && endDate) {
                    url = `impacts/payments/from/${startDate}/to/${endDate}/org/${id}`;
                }
                return url;
            },
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
    }),
});

///

export const {
    useGetAllPaymentQuery,
    useGetPaymentByDateRangeQuery,
    useGetPaymentByOrganizationQuery,
} = paymentApi;

const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        payments: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            paymentApi.endpoints.getAllPayment.matchFulfilled,
            (state, action) => {
                state.payments = action.payload;
            }
        );
    },
});

export default paymentSlice;
