/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import styled from "styled-components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

const allPaymentColumn = {
    columns: [
        {
            Header: "Ref.",
            accessor: "flw_ref",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Name",
            accessor: "names",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Phone number",
            accessor: "phone_number",
            Cell: ({ value }) => (
                <DefaultCell value={value ?? "Phone number"} />
            ),
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ value }) => <DefaultCell value={value ?? "Phone"} />,
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Currency",
            accessor: "organization?.currency",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Trial Deadline",
            accessor: "trial_deadline",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Transaction ID",
            accessor: "transaction_id",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Trial Deadline",
            accessor: "status",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Organization name",
            accessor: "organization",
            Cell: ({ value }) => (
                <DefaultCell value={value?.name ?? "Address"} />
            ),
        },

        {
            Header: "More",
            accessor: "org",
            Cell: ({ value, row }) => (
                <div style={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow={false} title='view organization'>
                        <NavLink
                            style={{ fontSize: "25px" }}
                            to={`/payment/organization/${value}`}
                        >
                            <RemoveRedEyeIcon fontSize='inherit' />
                        </NavLink>
                    </Tooltip>
                </div>
            ),
        },
    ],
};

export const NavLink = styled(Link)`
    text-decoration: none;
    color: black;
    &:hover {
        text-decoration: underline !important;
    }
`;

export const Logo = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50px;
`;

export default allPaymentColumn;
