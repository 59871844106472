/** @format */

import * as Yup from "yup";
import form from "./form";

const {
    formField: { Country, name },
} = form;

const validations = [
    Yup.object().shape({
        [name.name]: Yup.string().required(name.errorMsg),
        [Country.name]: Yup.string().required(Country.errorMsg),
    }),
];

export default validations;
