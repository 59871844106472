/** @format */

import { setToken } from "features/slices/userSlice";
import { createContext, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [auth, setAuth] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add isLoading state

    const handleLogin = useCallback(async () => {
        const isLogedIn = await localStorage.getItem("auth-token");
        handleToken(isLogedIn);
    });

    const handleToken = (data) => {
        if (data) {
            setAuth(data);
            dispatch(setToken(data));
        } else {
            navigate("/");
        }

        setIsLoading(false);
    };

    useEffect(() => {
        handleLogin();
    }, []);

    if (isLoading) {
        return (
            <LoadingContainer>
                <LoadingMessage>App Loading...</LoadingMessage>
            </LoadingContainer>
        ); // Show loading state until token validation is complete
    }

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const LoadingMessage = styled.p`
    font-size: 20px;
`;
