/** @format */

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React from "react";

const UserDetails = () => {
    return (
        <DashboardLayout>
            <DashboardNavbar />
        </DashboardLayout>
    );
};

export default UserDetails;
