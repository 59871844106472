/** @format */

import form from "./form";

const {
    formField: { project_name, sponsor, organization_id, country_id },
} = form;

const initialValues = {
    [project_name.name]: "",
    [sponsor.name]: "",
    [country_id.name]: "",
    [organization_id.name]: "",
};

export default initialValues;
