/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import styled from "styled-components";
import treeIcon from "assets/images/tree-icon.png";

const style = { paddingBottom: 10, fontSize: 14, fontWeight: 400 };
export const apiKey = process.env.REACT_APP_GOOGLE_MAP_API;
const Map = ({ height = "60vh", center, data }) => {
    const [selected, setSelected] = useState(null);
    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                id='example-map_trees'
                mapContainerStyle={{ height: `${height}`, width: "100%" }}
                zoom={13}
                center={{
                    lat: center.lat,
                    lng: center.long,
                }}
            >
                {data.map((location, index) => (
                    <Marker
                        key={index}
                        options={{ icon: treeIcon }}
                        position={{
                            lat: location.lat,
                            lng: location.long,
                        }}
                        onClick={() => {
                            setSelected({
                                ...location,

                                position: {
                                    lat: location.lat,
                                    lng: location.long,
                                },
                            });
                        }}
                    />
                ))}

                {selected && (
                    <InfoWindow
                        paddingTop='5px'
                        position={selected.position}
                        onCloseClick={() => {
                            setSelected(null);
                        }}
                    >
                        <PopUpStyle>
                            <h4>Forest name</h4>
                            <p style={style}>Mama</p>
                            <h4>Hectars covered</h4>
                            <p style={style}>100 ha</p>
                            <h4>Number of trees</h4>
                            <p style={style}>100</p>
                            <h4>Dead trees</h4>
                            <p style={style}>200</p>
                            <h4>Replaced trees</h4>
                            <p style={style}>{0}</p>
                            <h4>Replaced trees</h4>
                            <p style={style}>{"Rwanda, kigali city"}</p>
                            <h4>Planted date</h4>
                            <p style={style}>{"2023/12/01"}</p>
                            <h4>Geolocation</h4>
                            <p style={style}>
                                {selected?.lat}, {selected?.lng}
                            </p>
                            <h4>Forest age</h4>
                            <p style={style}>{"2 years"}</p>

                            <h4>Revenue</h4>
                            <p style={style}>100 FRW</p>

                            <h4>View more</h4>
                            <p style={style}>
                                <Link
                                    to={`/forest/${selected.id}/details/`}
                                    style={{
                                        color: "blue",
                                        display: "block",
                                        paddingTop: 5,
                                    }}
                                >
                                    More details{" "}
                                </Link>
                            </p>
                        </PopUpStyle>
                    </InfoWindow>
                )}
            </GoogleMap>
        </LoadScript>
    );
};

const PopUpStyle = styled.div`
    width: 400px;
`;

export default Map;
