/** @format */

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React components
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import Footer from "eco/Footer";
import DataTable from "eco/Tables/DataTable";
import Header from "../../profile/components/Header";

// Data
import loanData from "../data/loanData";

function FarmerLoans() {
    const [menu, setMenu] = useState(null);
    const [typeMenu, settypeMenu] = useState(null);

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const opentypeMenu = (event) => settypeMenu(event.currentTarget);
    const closetypeMenu = () => settypeMenu(null);

    const renderStatusMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <MenuItem onClick={closeMenu}>Pending</MenuItem>
            <MenuItem onClick={closeMenu}>Paid</MenuItem>
            <MenuItem onClick={closeMenu}>Declined</MenuItem>
            <MenuItem onClick={closeMenu}>Request</MenuItem>
        </Menu>
    );

    const renderTypeMenu = (
        <Menu
            anchorEl={typeMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(typeMenu)}
            onClose={closetypeMenu}
            keepMounted
        >
            <MenuItem onClick={closetypeMenu}>Ifumbire</MenuItem>
            <MenuItem onClick={closetypeMenu}>Ibiti</MenuItem>
            <MenuItem onClick={closetypeMenu}>Umuti</MenuItem>
        </Menu>
    );

    return (
        <DashboardLayout>
            <Header />
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    mb={2}
                >
                    <SoftButton variant='gradient' color='info'>
                        Add Loan
                    </SoftButton>

                    <SoftBox display='flex'>
                        <SoftButton
                            variant={typeMenu ? "contained" : "outlined"}
                            color='secondary'
                            onClick={opentypeMenu}
                        >
                            Filter by type&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </SoftButton>
                        {renderTypeMenu}

                        <SoftBox ml={1}>
                            <SoftButton
                                variant={menu ? "contained" : "outlined"}
                                color='secondary'
                                onClick={openMenu}
                            >
                                filters by status&nbsp;
                                <Icon>keyboard_arrow_down</Icon>
                            </SoftButton>
                            {renderStatusMenu}
                        </SoftBox>

                        <SoftBox ml={1}>
                            <SoftButton variant='outlined' color='secondary'>
                                <Icon>description</Icon>
                                &nbsp;export csv
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
                <Card>
                    <DataTable
                        table={loanData}
                        theData={[
                            {
                                id: "1021",
                                loan_type: "Ifumbire",
                                loan_duration: "1 year",
                                loan: "$200",
                                total_payment: "$50",
                                forest: "Ibisheke farm",
                            },
                            {
                                id: "1021",
                                loan_type: "Gereveriya (Trees)",
                                loan_duration: "6 months",
                                loan: "$400",
                                total_payment: "$130",
                                forest: "Gereveriya farm",
                            },
                        ]}
                        entriesPerPage={true}
                        canSearch
                    />
                </Card>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default FarmerLoans;
