/** @format */

import { Alert, CircularProgress, Snackbar } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useUpdateSponsorMutation } from "features/slices/sponsorSlice";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import form from "./schema/form";
import { useGetSponsorByIdQuery } from "features/slices/sponsorSlice";
import validations from "./schema/validation";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import SponsorFormInfo from "./SponsorFormInfo";

const UpdateSponsor = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [updateSponsor, { isLoading, isError, error }] =
        useUpdateSponsorMutation({ id });

    const { data, isLoading: isLoadingSponsor } = useGetSponsorByIdQuery(id);

    const { formId, formField } = form;

    const [showError, setShowError] = useState(false);
    const [open, setOpen] = useState(false);
    const toggleShowError = () => setShowError(!showError);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const currentValidation = validations[0];
    const handleSubmit = async (values, actions) => {
        try {
            const result = await updateSponsor({ values, id }).unwrap();
            if (result?.status) {
                actions.setSubmitting(false);
                handleClick();
                actions.resetForm();
            }
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        } finally {
            actions.setSubmitting(false);
            navigate("/sponsor/all");
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    Sponsor updated!
                </Alert>
            </Snackbar>
            <SoftBox>
                {!isLoadingSponsor && (
                    <Formik
                        initialValues={{
                            name: data?.name,
                            country_id: data?.country_id,
                        }}
                        validationSchema={currentValidation}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form id={formId} autoComplete='off'>
                                <SoftBox p={2}>
                                    <SoftBox>
                                        <SponsorFormInfo
                                            formData={{
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                                setFieldValue,
                                            }}
                                        />
                                        <SoftBox
                                            mt={2}
                                            width='100%'
                                            display='flex'
                                            justifyContent='space-between'
                                        >
                                            <SoftButton
                                                disabled={isSubmitting}
                                                type='submit'
                                                variant='gradient'
                                                color='dark'
                                            >
                                                {isSubmitting ? (
                                                    <CircularProgress
                                                        size={24}
                                                        style={{
                                                            color: "#ffffff",
                                                        }}
                                                    />
                                                ) : (
                                                    ` Send`
                                                )}
                                            </SoftButton>
                                            {showError &&
                                                !isLoading &&
                                                isError && (
                                                    <ErrorMessageResponse>
                                                        {error?.data?.message}
                                                    </ErrorMessageResponse>
                                                )}
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                            </Form>
                        )}
                    </Formik>
                )}
            </SoftBox>
        </DashboardLayout>
    );
};

export default UpdateSponsor;
