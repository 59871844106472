/**
 * eslint-disable react/prop-types
 *
 * @format
 */

import IdCell from "components/dataTableComponents/IdCell";
import DefaultCell from "components/dataTableComponents/DefaultCell";

const harvestData = {
    columns: [
        {
            Header: "farmer",
            accessor: "farmer",
            Cell: ({ value }) => (
                <DefaultCell value={value.full_name ?? "N/A"} />
            ),
        },
        {
            Header: "Forest",
            accessor: "forest",
            Cell: ({ value }) => <DefaultCell value={value.name ?? "N/A"} />,
        },
        {
            Header: "Agent",
            accessor: "agent",
            Cell: ({ value }) => (
                <DefaultCell value={value.full_name ?? "N/A"} />
            ),
        },
        {
            Header: "Job male",
            accessor: "job_male",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Job female",
            accessor: "job_female",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Revenue",
            accessor: "revenue",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Currency",
            accessor: "currency",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Year and month joined",
            accessor: "ym",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
    ],
};

export default harvestData;
