import * as Yup from "yup";
import formElement from "layouts/pages/users/new-user/schemas/form";

const {
  formField: {
    first_name,
    last_name,
    phone,
    email,
    password,
    repeatPassword,
    role,
    country_id,
    location,
    dob,
  },
} = formElement;

const validations = [
  Yup.object().shape({
    [first_name.name]: Yup.string().required(first_name.errorMsg),
    [last_name.name]: Yup.string().required(last_name.errorMsg),
    [phone.name]: Yup.string()
      .required(phone.errorMsg)
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),

    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [role.name]: Yup.string().required(role.errorMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
    [role.name]: Yup.string().required(role.errorMsg),
    [country_id.name]: Yup.string().required(country_id.errorMsg),
    [location.name]: Yup.string().required(location.errorMsg),
    [dob.name]: Yup.date().required(dob.errorMsg),
  }),
];

export default validations;
