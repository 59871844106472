/** @format */

import React, { useState } from "react";
// prop-type is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

import FormField from "../formField";
import { useGetAllProvinceByCountryIdQuery } from "features/slices/provinceSlice";
import { useGetDistrictsByProvinceIdQuery } from "features/slices/districtSlice";
import { useGetSectorsByDistrictIdQuery } from "features/slices/sectorSlice";
import { useGetCellsBySectorIdQuery } from "features/slices/cellSlice";
import { useGetVillagesByCellIdQuery } from "features/slices/villageSlice";

function FarmerInfo({ formData, setLocation, location }) {
    const { formField, values, errors, touched, setFieldValue } = formData;
    const [provinceId, setProvinceId] = useState({
        id: null,
        name: null,
    });
    const [districtId, setDistrictId] = useState({
        id: null,
        name: null,
    });
    const [sectocId, setSectorId] = useState({
        id: null,
        name: null,
    });
    const [cellId, setCellId] = useState({
        id: null,
        name: null,
    });

    // Queries for fetching location data

    const { data: provinces } = useGetAllProvinceByCountryIdQuery();
    const { data: districts, isLoading: loadingDistricts } =
        useGetDistrictsByProvinceIdQuery(provinceId.id);
    const { data: sectors } = useGetSectorsByDistrictIdQuery(districtId.id);
    const { data: cells } = useGetCellsBySectorIdQuery(sectocId.id);
    const { data: villages } = useGetVillagesByCellIdQuery(cellId.id);
    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant='h5' fontWeight='bold'>
                    Farmer
                </SoftTypography>
                <SoftTypography
                    variant='button'
                    fontWeight='regular'
                    color='text'
                >
                    Mandatory informations
                </SoftTypography>
            </SoftBox>
            <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.first_name.type}
                            label={formField.first_name.label}
                            name={formField.first_name.name}
                            value={values.first_name}
                            placeholder={formField.first_name.placeholder}
                            error={errors.first_name && touched.first_name}
                            success={
                                values?.first_name?.length > 0 &&
                                !errors?.first_name
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.last_name.type}
                            label={formField.last_name.label}
                            name={formField.last_name.name}
                            value={values.last_name}
                            placeholder={formField.last_name.placeholder}
                            error={errors.last_name && touched.last_name}
                            success={
                                values?.last_name?.length > 0 &&
                                !errors?.last_name
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Education
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.education}
                            error={errors.education && touched.education}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.education.name,
                                    event.target.value
                                );
                            }}
                        >
                            {formField.education.options.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.name}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.phone.type}
                            label={formField.phone.label}
                            name={formField.phone.name}
                            value={values.phone}
                            placeholder={formField.phone.placeholder}
                            error={errors.phone && touched.phone}
                            success={
                                values?.phone?.length > 0 && !errors?.phone
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.dob.type}
                            label={formField.dob.label}
                            name={formField.dob.name}
                            value={values.dob}
                            placeholder={formField.dob.placeholder}
                            error={errors.dob && touched.dob}
                            success={values?.dob?.length > 0 && !errors?.dob}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Marital Status
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.marital_status}
                            error={
                                errors.marital_status && touched.marital_status
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.marital_status.name,
                                    event.target.value
                                );
                            }}
                        >
                            {formField.marital_status.options.map(
                                (roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.name}
                                    >
                                        {roleOption.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Country
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.country_id}
                            error={errors.country_id && touched.country_id}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.country_id.name,
                                    event.target.value
                                );
                                setFieldValue(formField.province.name, "");
                                setLocation([
                                    ...location,
                                    formField.country_id.options.find(
                                        (item) => item.id === event.target.value
                                    ).name,
                                ]);
                            }}
                        >
                            {formField.country_id.options.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Province
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.province.name} // Set default to the first option's ID
                            error={errors.province && touched.province}
                            disabled={
                                !values.country_id ||
                                (provinces && provinces.data?.length <= 0)
                            }
                            onChange={(event) => {
                                setProvinceId({
                                    id: event.target.value,
                                    name: provinces?.data?.find(
                                        (item) => item.id === event.target.value
                                    ).province,
                                });
                                setFieldValue(
                                    formField.province.name,
                                    event.target.value
                                );
                                setFieldValue(formField.province.id, "");
                                setLocation([
                                    ...location,
                                    provinces?.data?.find(
                                        (item) => item.id === event.target.value
                                    ).province,
                                ]);
                            }}
                        >
                            {provinces?.data?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.province}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                District
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.district.name} // Set default to the first option's ID
                            error={errors.district && touched.district}
                            disabled={
                                !districts ||
                                (districts && districts?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.district.name,
                                    event.target.value
                                );
                                setDistrictId({
                                    id: event.target.value,
                                    name: districts?.find(
                                        (item) => item.id === event.target.value
                                    ).district,
                                });
                                setLocation([
                                    ...location,
                                    districts?.find(
                                        (item) => item.id === event.target.value
                                    ).district,
                                ]);
                            }}
                        >
                            {!loadingDistricts &&
                                districts?.map((district) => (
                                    <MenuItem
                                        key={district.id}
                                        value={district.id}
                                    >
                                        {district.district}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Sector
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.sector} // Set default to the first option's ID
                            error={errors.sector && touched.sector}
                            disabled={
                                !sectors || (sectors && sectors?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.sector.name,
                                    event.target.value
                                );
                                setSectorId({
                                    id: event.target.value,
                                    name: sectors?.find(
                                        (item) => item.id === event.target.value
                                    ).sector,
                                });
                                setLocation([
                                    ...location,
                                    sectors?.find(
                                        (item) => item.id === event.target.value
                                    ).sector,
                                ]);
                            }}
                        >
                            {sectors?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.sector}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Cell
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.cell} // Set default to the first option's ID
                            error={errors.cell && touched.cell}
                            disabled={!cells || (cells && cells?.length <= 0)}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.cell.name,
                                    event.target.value
                                );
                                setCellId({
                                    id: event.target.value,
                                    name: cells?.find(
                                        (item) => item.id === event.target.value
                                    ).cell,
                                });
                                setLocation([
                                    ...location,
                                    cells?.find(
                                        (item) => item.id === event.target.value
                                    ).cell,
                                ]);
                            }}
                        >
                            {cells?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.cell}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Village
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.village} // Set default to the first option's ID
                            error={errors.village && touched.village}
                            disabled={
                                !villages || (villages && villages?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.village.name,
                                    event.target.value
                                );
                                setLocation([
                                    ...location,
                                    villages?.find(
                                        (item) => item.id === event.target.value
                                    ).village,
                                ]);
                            }}
                        >
                            {villages?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.village}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
}

export default FarmerInfo;
