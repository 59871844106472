/** @format */

import form from "./form";

const {
    formField: {
        name,
        province,
        headline,
        sub_text,
        cta,
        currency,
        currency_symbol,
        price_per_tree,
        rate,
        contact_phone,
        person_contact,
        contact_email,
        website,
        district,
        cell,
        sector,
        village,
        country_id,
    },
} = form;

const initialValues = {
    [name.name]: "",
    [website.name]: "",
    [province.name]: "",
    [contact_phone.name]: "",
    [headline.name]: "",
    [sub_text.name]: "",
    [cta.name]: "",
    [currency.name]: "RWF",
    [currency_symbol.name]: "RWF",
    [price_per_tree.name]: 3,
    [rate.name]: 1,
    [person_contact.name]: "",
    [contact_email.name]: "",
    [country_id.name]: "",
    [district.name]: "",
    [sector.name]: "",
    [cell.name]: "",
    [village.name]: "",
};

export default initialValues;
