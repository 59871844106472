/** @format */

import React from "react";
import LoanStatusTable from "../LoanStatusTable";

const CompletedLoans = () => {
    return <LoanStatusTable status={"Completed"} title={"Completed loans"} />;
};

export default CompletedLoans;
