/** @format */

import { Card } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useParams } from "react-router-dom";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import DataTable from "eco/Tables/DataTable";
import { useGetPaymentByDateRangeQuery } from "features/slices/paymentSlice";
import React, { useState } from "react";
import organizationPaymentColumn from "./data/organizationPaymentColumn";

import styled from "styled-components";

const PaymentOrganization = () => {
    const { values } = breakpoints;
    const { id } = useParams();
    const [paymentRange, setPaymentRange] = useState({
        startDate: null,
        endDate: null,
    });
    const { data, isLoading } = useGetPaymentByDateRangeQuery({
        id: id,
        ...paymentRange,
    });

    const payments = data && data?.data;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Organization Payments
                </SoftTypography>

                <SoftBox my={3}>
                    <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                        <Card>
                            <SoftBox
                                py={4}
                                px={2}
                                display='flex'
                                alignItems='end'
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "1rem",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: ".325rem",
                                            alignItems: "center",
                                        }}
                                    >
                                        <label
                                            style={{
                                                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                                                fontSize: "1rem",
                                            }}
                                        >
                                            FROM
                                        </label>
                                        <DatePicker
                                            type='date'
                                            value={paymentRange.startDate}
                                            onChange={(e) => {
                                                setPaymentRange({
                                                    ...paymentRange,
                                                    startDate: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: ".325rem",
                                            alignItems: "center",
                                        }}
                                    >
                                        <label
                                            style={{
                                                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                                                fontSize: "1rem",
                                                alignItems: "center",
                                            }}
                                        >
                                            TO
                                        </label>
                                        <DatePicker
                                            type='date'
                                            name='endDate'
                                            value={paymentRange.endDate}
                                            onChange={(e) => {
                                                setPaymentRange({
                                                    ...paymentRange,
                                                    endDate: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </SoftBox>
                            <DataTable
                                table={organizationPaymentColumn}
                                entriesPerPage={5}
                                canSearch
                                theData={payments}
                            />
                        </Card>
                    </LoadingCardSkeleton>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
};

export const DatePicker = styled.input`
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    border: 0.0625rem solid #d2d6da;
    border-radius: 10px;
    color: #495057;
    padding: 0.5rem 0.75rem;
`;

export default PaymentOrganization;
