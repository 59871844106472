/** @format */

import { apiSlice } from "features/api/apiSliceV1";
import { createSlice } from "@reduxjs/toolkit";

const treeSpeciesApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllTreeSpecies: builder.query({
            query: () => "/tree-species/",
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            providesTags: ["treeSpecies"],
        }),
        getTreeSpecieById: builder.query({
            query: (id) => `/tree-species/${id}`,
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
        }),
        updateTreeSpecies: builder.mutation({
            query: ({ id, values }) => {
                return {
                    url: `/tree-species/${id}`,
                    method: "PUT",
                    body: values,
                };
            },
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            invalidatesTags: ["treeSpecies"],
        }),
    }),
});

export const {
    useGetAllTreeSpeciesQuery,
    useGetTreeSpecieByIdQuery,
    useUpdateTreeSpeciesMutation,
} = treeSpeciesApi;

const treeSpecieSlice = createSlice({
    name: "treeSpecies",
    initialState: {
        treeSpecies: null,
    },
    reducers: {},
    extraReducers: (builder) => {},
});

export default treeSpecieSlice;
