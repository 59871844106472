/**
 * eslint-disable react/prop-types
 *
 * @format
 */

import IdCell from "components/dataTableComponents/IdCell";
import DefaultCell from "components/dataTableComponents/DefaultCell";
import { Link } from "react-router-dom";
import styled from "styled-components";

const dataTableData = {
    columns: [
        {
            Header: "id",
            accessor: "farmer_code",
            Cell: ({ value, row }) => (
                <IdCell
                    handleClick={true}
                    url='/farmers/farmer-details'
                    style={{ cursor: "pointer" }}
                    routeId={`${row?.id + 1}`}
                    id={value}
                />
            ),
        },
        {
            Header: "farmer",
            accessor: "full_name",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Education",
            accessor: "education",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Address",
            accessor: "location_name",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "phone",
            accessor: "phone",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Action",
            accessor: "id",
            Cell: ({ value }) => (
                <RedirectLink to={`/farmers/farmer-details/${value}`}>
                    More details
                </RedirectLink>
            ),
        },
    ],
};

const RedirectLink = styled(Link)`
    text-decoration: none;
    color: #006afc;
    &:hover {
        text-decoration: underline !important;
    }
`;

export default dataTableData;
