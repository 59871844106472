/** @format */

import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

import NotificationItem from "eco/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarDesktopMenu,
    navbarMobileMenu,
} from "eco/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
    useSoftUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import useAuth from "context/useAuth";
import { clearUserAndToken } from "features/slices/userSlice";
import { useDispatch } from "react-redux";

function DashboardNavbar({ absolute, light, isMini }) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
        controller;
    const [openMenu, setOpenMenu] = useState(false);
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const Storedispatch = useDispatch();

    const handleLogout = () => {
        Storedispatch(clearUserAndToken());
        setAuth(null);
        navigate("/login", { replace: true });
    };

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(
                dispatch,
                (fixedNavbar && window.scrollY === 0) || !fixedNavbar
            );
        }

        /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () =>
            window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () =>
        setOpenConfigurator(dispatch, !openConfigurator);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
            <NotificationItem
                image={<img src={team2} alt='person' />}
                title={["New message", "from Laur"]}
                date='13 minutes ago'
                onClick={handleCloseMenu}
            />
            <NotificationItem
                image={<img src={logoSpotify} alt='person' />}
                title={["New album", "by Travis Scott"]}
                date='1 day'
                onClick={handleCloseMenu}
            />
            <NotificationItem
                color='secondary'
                image={
                    <Icon
                        fontSize='small'
                        sx={{ color: ({ palette: { white } }) => white.main }}
                    >
                        payment
                    </Icon>
                }
                title={["", "Payment successfully completed"]}
                date='2 days'
                onClick={handleCloseMenu}
            />
        </Menu>
    );

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color='inherit'
            sx={(theme) =>
                navbar(theme, { transparentNavbar, absolute, light })
            }
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SoftBox
                    color='inherit'
                    mb={{ xs: 1, md: 0 }}
                    sx={(theme) => navbarRow(theme, { isMini })}
                >
                    {/* <Breadcrumbs
            icon='home'
            title={route[route.length - 1]}
            route={route}
            light={light}
          /> */}
                    <Icon
                        fontSize='medium'
                        sx={navbarDesktopMenu}
                        onClick={handleMiniSidenav}
                    >
                        {miniSidenav ? "menu_open" : "menu"}
                    </Icon>
                </SoftBox>
                {isMini ? null : (
                    <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <SoftBox pr={1}>
                            <SoftInput
                                placeholder='Type here...'
                                icon={{
                                    component: "search",
                                    direction: "left",
                                }}
                            />
                        </SoftBox>
                        <SoftBox color={light ? "white" : "inherit"}>
                            <IconButton sx={navbarIconButton} size='small'>
                                <Icon
                                    sx={({ palette: { dark, white } }) => ({
                                        color: light ? white.main : dark.main,
                                    })}
                                >
                                    account_circle
                                </Icon>
                                <SoftTypography
                                    variant='button'
                                    fontWeight='medium'
                                    color={light ? "white" : "dark"}
                                    onClick={handleLogout}
                                >
                                    Sign Out
                                </SoftTypography>
                            </IconButton>
                            <IconButton
                                size='small'
                                color='inherit'
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon
                                    className={
                                        light ? "text-white" : "text-dark"
                                    }
                                >
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                            <IconButton
                                size='small'
                                color='inherit'
                                sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                            >
                                <Icon>settings</Icon>
                            </IconButton>
                            <IconButton
                                size='small'
                                color='inherit'
                                sx={navbarIconButton}
                                aria-controls='notification-menu'
                                aria-haspopup='true'
                                variant='contained'
                                onClick={handleOpenMenu}
                            >
                                <Icon
                                    className={
                                        light ? "text-white" : "text-dark"
                                    }
                                >
                                    notifications
                                </Icon>
                            </IconButton>
                            {renderMenu()}
                        </SoftBox>
                    </SoftBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
