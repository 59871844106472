/** @format */

import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useCallback, useState } from "react";
import { Card, Divider, Icon, Menu, MenuItem } from "@mui/material";
import { useGetAllCountriesQuery } from "features/slices/countrySlice";
import { useGetAllOrganizationsQuery } from "features/slices/organizationSlice";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import organizationColumns from "./data/organizationColumns";
import DataTable from "eco/Tables/DataTable";
import OrgCard from "eco/Cards/OrgCard";
import { useGetOrganizationsStatQuery } from "features/slices/organizationSlice";
import breakpoints from "assets/theme/base/breakpoints";
import SoftTypography from "components/SoftTypography";

const Organization = () => {
    const [selectedCountry, setSelectedCountry] = useState("");
    const { data: organization } = useGetOrganizationsStatQuery();

    const { values } = breakpoints;

    const {
        data: OrganizationList,
        isLoading: OrganizationLoading,
        isFetching: organizationIsFetching,
    } = useGetAllOrganizationsQuery();
    const { data: CountriesList } = useGetAllCountriesQuery();
    const navigate = useNavigate();
    const [CountryMenu, setCountryMenu] = useState(null);

    const openCountryMenu = (event) => setCountryMenu(event.currentTarget);
    const closeCountryMenu = () => setCountryMenu(null);

    // Memoized function using useCallback
    const handleCountryFilterChange = useCallback((country) => {
        setSelectedCountry(country);
    }, []);

    const renderCountryMenu = () => (
        <Menu
            anchorEl={CountryMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(CountryMenu)}
            onClose={closeCountryMenu}
            keepMounted
        >
            {CountriesList && CountriesList?.length > 0 && (
                <>
                    {CountriesList.map((country, index) => (
                        <MenuItem
                            id={index}
                            onClick={() => {
                                handleCountryFilterChange({
                                    id: country?.id || "",
                                    name: country?.name || "",
                                });
                                closeCountryMenu();
                            }}
                        >
                            {country?.name}
                        </MenuItem>
                    ))}
                    <Divider sx={{ margin: "0.5rem 0" }} />

                    {selectedCountry?.name && (
                        <MenuItem
                            onClick={() => {
                                handleCountryFilterChange("");
                                closeCountryMenu();
                            }}
                        >
                            All
                        </MenuItem>
                    )}
                </>
            )}
        </Menu>
    );
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Organization Statistics
                </SoftTypography>

                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Organizations'
                                        count={
                                            organization?.data?.organizations
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Beneficiaries'
                                        count={
                                            organization?.data?.beneficiaries
                                        }
                                        percentage=''
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Projects'
                                        count={organization?.data?.projects}
                                        percentage=''
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <SoftButton
                                        variant='gradient'
                                        color='dark'
                                        handleClick={() =>
                                            navigate("/organization/create-new")
                                        }
                                    >
                                        New Organization
                                    </SoftButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    mb={2}
                >
                    <div style={{ marginTop: "1.3rem" }}>
                        <SoftTypography
                            variant={
                                window.innerWidth < values.sm ? "h3" : "h2"
                            }
                            textTransform='capitalize'
                            fontWeight='bold'
                            gutterBottom
                        >
                            Agent Detail
                        </SoftTypography>
                    </div>
                </SoftBox>
                <LoadingCardSkeleton
                    isLoading={
                        OrganizationLoading ||
                        (organizationIsFetching && selectedCountry?.id)
                    }
                    height='333px'
                >
                    <Card>
                        <DataTable
                            table={organizationColumns}
                            entriesPerPage={5}
                            canSearch
                            theData={OrganizationList?.data}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default Organization;
