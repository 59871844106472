/** @format */

import SoftBox from "components/SoftBox";
import {
    CircularProgress,
    Snackbar,
    Alert as MuiAlert,
    Grid,
    Card,
} from "@mui/material";

import Footer from "eco/Footer";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import validations from "./validation";
import form from "./form";

import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import { useUpdateOrganizationMutation } from "features/slices/organizationSlice";
import { Formik, Form } from "formik";
import OrganizationEditInfo from "./OrganizationEditInfo";
import SoftButton from "components/SoftButton";
import { useGetOrganizationByIdQuery } from "features/slices/organizationSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const EditOrganization = () => {
    const { id } = useParams();

    const [updateOrganization, { isLoading, isError, error }] =
        useUpdateOrganizationMutation();
    const { formId, formField } = form;

    const {
        data,
        isLoading: isLoadingOrgDetail,
        refetch,
    } = useGetOrganizationByIdQuery(id);
    const organization = data && data.data;

    const [initialValues, setInitialValues] = useState(null);

    const currentValidation = validations[0];
    const [open, setOpen] = useState(false);
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(!showError);
    const navigate = useNavigate();
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const submitForm = async (values, actions) => {
        const newValue = {
            name: values.name,
            person_contact: values.person_contact,
            contact_phone: values.contact_phone,
            contact_email: values.contact_email,
            website: values.website,
            headline: values.headline,
            sub_text: values.sub_text,
            cta: values.cta,
            currenncy: values.currency,
            currency_symbol: values.currency_symbol,
            price_per_tree: values.price_per_tree,
            rate: values.rate,
            country_id: values.country_id,
            province: organization?.district,
            cell: organization?.cell,
            sector: organization?.sector,
            village: organization?.village,
        };

        try {
            const result = await updateOrganization({
                values: newValue,
                id,
            }).unwrap();
            if (result?.status) {
                actions.setSubmitting(false);
                handleClick();
                refetch();
                actions.resetForm();
            }
            setTimeout(() => {
                navigate(`/organization/list`);
            }, 2000);
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        }
    };

    useEffect(() => {
        if (!isLoadingOrgDetail && organization) {
            setInitialValues({
                name: organization?.name || "",
                person_contact: organization?.person_contact || "",
                contact_phone: organization?.contact_phone || "",
                contact_email: organization?.contact_email || "",
                website: organization?.website || "",
                country_id: organization?.country_id || "",
                province: organization?.district || "",
                headline: organization?.headline || "",
                sub_text: organization?.sub_text || "",
                cta: organization?.cta || "",
                currency: organization?.currenncy || "",
                currency_symbol: organization?.currency_symbol || "RWF",
                price_per_tree: organization?.price_per_tree || 3,
                rate: organization?.rate || 0,
            });
        }
    }, [isLoadingOrgDetail, organization]);

    if (isLoadingOrgDetail || !initialValues) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SoftBox>
                    <div
                        style={{
                            height: "60vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                </SoftBox>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    Organization updated!!
                </Alert>
            </Snackbar>
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={submitForm}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                                setValues,
                            }) => (
                                <Form
                                    encType='multipart/form-data'
                                    id={formId}
                                    autoComplete='off'
                                >
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <OrganizationEditInfo
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                />

                                                <SoftBox
                                                    mt={2}
                                                    ml={3}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            `Update`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {showError &&
                                                    !isLoading &&
                                                    isError && (
                                                        <ErrorMessageResponse>
                                                            {
                                                                error?.data
                                                                    ?.message
                                                            }
                                                        </ErrorMessageResponse>
                                                    )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default EditOrganization;
