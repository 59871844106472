/** @format */

import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import OrgCard from "eco/Cards/OrgCard";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React from "react";
import columnDataReleaseForest from "./data/mainScheme";
import { useGetForestReleaseQuery } from "features/slices/forestReleaseSlice";
import DataTable from "eco/Tables/DataTable";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { Card } from "react-bootstrap";
import breakpoints from "assets/theme/base/breakpoints";

const ForestRelease = () => {
    const { data, isLoading } = useGetForestReleaseQuery();
    const forests = data && data?.data;
    const { values } = breakpoints;
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                {/* <div style={{ marginTop: "1.3rem" }}>
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Forest Release Statistics
                    </SoftTypography>
                </div> */}
                {/* <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Planted trees'
                                        count={totalPlantingTrees}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Dead trees'
                                        count={totalDeadTrees}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Replaced trees'
                                        count={totalDeadTrees}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Survived trees'
                                        count={
                                            totalPlantingTrees - totalDeadTrees
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox> */}
            </SoftBox>
            <div style={{ marginTop: "1.3rem" }}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Forest Release List
                </SoftTypography>
            </div>
            <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                <Card>
                    <DataTable
                        table={columnDataReleaseForest}
                        entriesPerPage={5}
                        canSearch
                        theData={forests}
                    />
                </Card>
            </LoadingCardSkeleton>
        </DashboardLayout>
    );
};

export default ForestRelease;
