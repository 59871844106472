// Soft UI Dashboard PRO React base styles
import borders from 'assets/theme/base/borders';
import boxShadows from 'assets/theme/base/boxShadows';

const { borderRadius } = borders;
const { xxl } = boxShadows;

const dialog = {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;
