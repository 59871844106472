/** @format */

import React from "react";
import SupportStatus from "../status";

const InProgress = () => {
    return <SupportStatus status={"In_progress"} title={"Tasks in progress"} />;
};

export default InProgress;
