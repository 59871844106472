/** @format */

function collapseItem(theme, ownerState) {
    const {
        palette,
        transitions,
        breakpoints,
        boxShadows,
        borders,
        functions,
    } = theme;
    const { active, transparentSidenav } = ownerState;

    const { dark, white, text, transparent } = palette;
    const { xxl } = boxShadows;
    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
        background:
            active && transparentSidenav ? white.main : transparent.main,
        color: active ? dark.main : text.main,
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(
            16
        )}`,
        margin: `0 ${pxToRem(16)}`,
        borderRadius: borderRadius.md,
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
        boxShadow: active && transparentSidenav ? xxl : "none",
        [breakpoints.up("xl")]: {
            boxShadow: () => {
                if (active) {
                    return transparentSidenav ? xxl : "none";
                }

                return "none";
            },
            transition: transitions.create("box-shadow", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
            }),
        },
    };
}

function collapseIconBox(theme, ownerState) {
    const {
        palette,
        transitions,
        breakpoints,
        boxShadows,
        borders,
        functions,
    } = theme;
    const { active, transparentSidenav, sidenavColor } = ownerState;

    const { white, info, light, gradients } = palette;
    const { md } = boxShadows;
    const { borderRadius } = borders;
    const { pxToRem } = functions;

    return {
        background: () => {
            if (active) {
                return sidenavColor === "default"
                    ? info.main
                    : palette[sidenavColor].main;
            }

            return light.main;
        },
        minWidth: pxToRem(32),
        minHeight: pxToRem(32),
        borderRadius: borderRadius.md,
        display: "grid",
        placeItems: "center",
        boxShadow: md,
        transition: transitions.create("margin", {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
        }),

        [breakpoints.up("xl")]: {
            background: () => {
                let background;

                if (!active) {
                    background = transparentSidenav ? white.main : light.main;
                } else if (sidenavColor === "default") {
                    background = info.main;
                } else if (sidenavColor === "warning") {
                    background = gradients.warning.main;
                } else {
                    background = palette[sidenavColor].main;
                }

                return background;
            },
        },

        "& svg, svg g": {
            fill: active ? white.main : gradients.dark.state,
        },
    };
}

const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
    color: active ? white.main : gradients.dark.state,
});

function collapseText(theme, ownerState) {
    const { typography, transitions, breakpoints, functions } = theme;
    const { miniSidenav, transparentSidenav, active } = ownerState;

    const { size, fontWeightMedium, fontWeightRegular } = typography;
    const { pxToRem } = functions;

    return {
        marginLeft: pxToRem(12.8),

        [breakpoints.up("xl")]: {
            opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
            maxWidth:
                miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
            marginLeft:
                miniSidenav || (miniSidenav && transparentSidenav)
                    ? 0
                    : pxToRem(12.8),
            transition: transitions.create(["opacity", "margin"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
            }),
        },

        "& span": {
            fontWeight: active ? fontWeightMedium : fontWeightRegular,
            fontSize: size.sm,
            lineHeight: 0,
        },
    };
}

function collapseArrow(theme, ownerState) {
    const { palette, typography, transitions, breakpoints, functions } = theme;
    const { noCollapse, transparentSidenav, miniSidenav, open } = ownerState;

    const { dark, gradients } = palette;
    const { size } = typography;
    const { pxToRem, rgba } = functions;

    return {
        fontSize: `${size.md} !important`,
        fontWeight: 700,
        marginBottom: pxToRem(-1),
        transform: open ? "rotate(0)" : "rotate(-180deg)",
        color: open ? dark.main : rgba(gradients.dark.state, 0.4),
        transition: transitions.create(["color", "transform", "opacity"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
        }),

        [breakpoints.up("xl")]: {
            display:
                noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
                    ? "none !important"
                    : "block !important",
        },
    };
}

export {
    collapseItem,
    collapseIconBox,
    collapseIcon,
    collapseText,
    collapseArrow,
};
