/** @format */
import React, { useState } from "react";
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import styled from "styled-components";
import { apiKey } from "constants/data";
import { calculateAge } from "constants";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import TimelineItem from "eco/Timeline/TimelineItem";
import treeIcon from "assets/images/tree-icon.png";
import mango from "assets/images/mango.jpg";

const style = { paddingBottom: 10, fontSize: 14, fontWeight: 400 };

const TreesMap = ({ data, center, height = "60vh" }) => {
    const [selected, setSelected] = useState(null);
    console.log(data);
    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                id='example-map_trees'
                mapContainerStyle={{ height: `${height}`, width: "100%" }}
                zoom={13}
                center={{
                    lat: center.latitude * 1,
                    lng: center.longitude * 1,
                }}
            >
                {data?.map((location) => (
                    <Marker
                        key={location.id}
                        options={{ icon: treeIcon }}
                        position={{
                            lat: location?.tree_location?.coordinates[1] * 1,
                            lng: location?.tree_location?.coordinates[0] * 1,
                        }}
                        onClick={() => {
                            setSelected({
                                ...location,

                                position: {
                                    lat:
                                        location?.tree_location.coordinates[1] *
                                        1,
                                    lng:
                                        location?.tree_location.coordinates[0] *
                                        1,
                                },
                            });
                        }}
                    />
                ))}

                {selected && (
                    <InfoWindow
                        paddingTop='5px'
                        position={selected.position}
                        onCloseClick={() => {
                            setSelected(null);
                        }}
                    >
                        <PopUpStyle>
                            <ImagesContainer>
                                <MainImageContainer>
                                    <Photo src={mango} alt='last taken' />
                                </MainImageContainer>
                            </ImagesContainer>
                            <div>
                                <h4>Tree species</h4>
                                <p style={style}>
                                    {selected?.tree_species.tree_specy}
                                </p>
                                <h4>Tree age</h4>
                                <p style={style}>
                                    {calculateAge(selected?.age)}
                                </p>
                                <h4>Tree life cycle</h4>
                                <p style={style}>
                                    {selected?.tree_life_cycle.life_cycle}
                                </p>
                                <SoftTypography
                                    variant='h6'
                                    fontWeight='medium'
                                >
                                    Life cycle history
                                </SoftTypography>
                                <SoftBox mt={2}>
                                    {selected?.life_cycle_history?.map(
                                        (life_cyle) => (
                                            <TimelineItem
                                                key={life_cyle.id}
                                                color='secondary'
                                                icon='inventory_2'
                                                title={life_cyle.lifecycle}
                                                dateTime={life_cyle.created_at}
                                            />
                                        )
                                    )}
                                </SoftBox>
                            </div>
                        </PopUpStyle>
                    </InfoWindow>
                )}
            </GoogleMap>
        </LoadScript>
    );
};

const PopUpStyle = styled.div`
    width: 400px;
`;

const ImagesContainer = styled.div``;

const MainImageContainer = styled.div`
    max-width: 350px;
    height: 250px;
`;

const Photo = styled.img`
    width: 100%;
    height: 100%;
`;

export default TreesMap;
