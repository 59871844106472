/** @format */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";

import Footer from "eco/Footer";

// General page components
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";

// Data
import { useParams } from "react-router-dom";
import Map from "components/GoodMap";
import OrgCard from "eco/Cards/OrgCard";
import { useGetGetFarmerForestStatQuery } from "features/slices/farmerSlice";
import { useGetFarmerForestQuery } from "features/slices/farmerSlice";

function FarmerForests() {
    const { id } = useParams();
    const center = {
        lat: -1.935114,
        long: 30.082111,
    };

    const { data: farmerForest, isLoading: isLoadingForest } =
        useGetFarmerForestQuery(id);

    const { data: farmerForestStat, isLoading } =
        useGetGetFarmerForestStatQuery();

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <SoftBox pt={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"200px"}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            title='Total revenue'
                                            count={
                                                farmerForestStat?.total_evenue ||
                                                0
                                            }
                                            percentage='Planted'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"200px"}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            title='Restored Land'
                                            count={
                                                farmerForestStat?.restored_land
                                            }
                                            percentage='FRW'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"200px"}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            title='Total trees'
                                            count={
                                                farmerForestStat?.total_trees
                                            }
                                            percentage='FRW'
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"200px"}
                                        isLoading={isLoading}
                                    >
                                        <OrgCard
                                            title='Total Job Created'
                                            count={
                                                farmerForestStat?.totalJobsCreated ||
                                                0
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            <SoftBox mt={3}>
                <LoadingCardSkeleton isLoading={isLoadingForest} height='193px'>
                    <Card container spacing={3} style={{ padding: 10 }}>
                        <Map center={center} data={farmerForest?.data} />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default FarmerForests;
