/** @format */

import SoftBox from "components/SoftBox";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import { Card, CircularProgress, Grid, Snackbar } from "@mui/material";
import validations from "./validation";
import form from "./form";
import { Formik, Form } from "formik";
import { useGetTreeSpecieByIdQuery } from "features/slices/treeSpeciesSlice";
import EditSpecyInfo from "./EditSpecyInfo";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateTreeSpeciesMutation } from "features/slices/treeSpeciesSlice";
import SoftButton from "components/SoftButton";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const EditTreeSpecies = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading: isLoadingDetail } = useGetTreeSpecieByIdQuery(id);
    const [initialValues, setInitialValues] = useState(null);
    const [open, setOpen] = useState(false);
    const { formId, formField } = form;
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(!showError);
    const [updateTreeSpecy, { isLoading, isError, error }] =
        useUpdateTreeSpeciesMutation();

    const treeSpecy = data && data?.data;

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const currentValidation = validations[0];

    const submitForm = async (values, actions) => {
        const newValue = {
            tree_class: values.tree_class,
            tree_specy: values.tree_specy,
            description: values.description,
        };

        try {
            const result = await updateTreeSpecy({
                values: newValue,
                id,
            }).unwrap();
            if (result?.status) {
                actions.setSubmitting(false);
                handleClick();
                actions.resetForm();
            }
            setTimeout(() => {
                navigate(`/tree-species/all`);
            }, 2000);
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        }
    };

    useEffect(() => {
        if (!isLoadingDetail && treeSpecy) {
            setInitialValues({
                tree_class: treeSpecy?.tree_class || "",
                tree_specy: treeSpecy?.tree_specy || "",
                description: treeSpecy?.description || "",
            });
        }
    }, [isLoadingDetail, treeSpecy]);

    if (isLoadingDetail || !initialValues) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SoftBox>
                    <div
                        style={{
                            height: "60vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </div>
                </SoftBox>
            </DashboardLayout>
        );
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    Tree species updated!!
                </Alert>
            </Snackbar>
            <SoftBox pt={3}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={submitForm}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                                setValues,
                            }) => (
                                <Form
                                    encType='multipart/form-data'
                                    id={formId}
                                    autoComplete='off'
                                >
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <EditSpecyInfo
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                />

                                                <SoftBox
                                                    mt={2}
                                                    ml={3}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            `Update`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {showError &&
                                                    !isLoading &&
                                                    isError && (
                                                        <ErrorMessageResponse>
                                                            {
                                                                error?.data
                                                                    ?.message
                                                            }
                                                        </ErrorMessageResponse>
                                                    )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
        </DashboardLayout>
    );
};

export default EditTreeSpecies;
