/**
 * eslint-disable react/prop-types
 *
 * @format
 */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import { format } from "date-fns";

const dataHistoryPaymentColumns = {
    columns: [
        {
            Header: "Payment amount",
            accessor: "amount",
            Cell: ({ value }) => <DefaultCell value={`FRW ${value}`} />,
        },
        {
            Header: "Date",
            accessor: "payment_date",
            Cell: ({ value }) => {
                const date = new Date(value);
                const formatDate = format(date, "dd-MM-yyyy");
                return <DefaultCell value={formatDate} />;
            },
        },
        {
            Header: "Payment Status",
            accessor: "status",
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
    ],
};

export default dataHistoryPaymentColumns;
