/** @format */
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftBox from "components/SoftBox";
import DefaultCell from "components/dataTableComponents/DefaultCell";
import { countries } from "constants/data";
import { Link } from "react-router-dom";

const columnData = {
    columns: [
        {
            Header: "Support",
            accessor: "support",
            Cell: ({ value }) => <DefaultCell value={value?.name ?? "N/A"} />,
        },
        {
            Header: "Farmer",
            accessor: "farmer",
            Cell: ({ value }) => (
                <DefaultCell value={value?.full_name ?? "N/A"} />
            ),
        },
        {
            Header: "Comment",
            accessor: "comment",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }) => {
                const renderStatus = () => {
                    switch (value) {
                        case "Progress":
                            return (
                                <SoftBadgeDot
                                    size='xs'
                                    color='primary'
                                    badgeContent='in progress'
                                />
                            );
                        case "Completed":
                            return (
                                <SoftBadgeDot
                                    size='xs'
                                    color='success'
                                    badgeContent='completed'
                                />
                            );
                        case "Pending":
                            return (
                                <SoftBadgeDot
                                    color='warning'
                                    size='xs'
                                    badgeContent='pending'
                                />
                            );
                        case "Declined":
                            return (
                                <SoftBadgeDot
                                    color='error'
                                    size='xs'
                                    badgeContent='pending'
                                />
                            );
                    }
                };
                return <SoftBox ml={-1.325}>{renderStatus()}</SoftBox>;
            },
        },
        {
            Header: "Country",
            accessor: "country_id",
            Cell: ({ value }) => {
                const country = countries.find((c) => c.id === value);
                return <DefaultCell value={country.name ?? "N/A"} />;
            },
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value }) => (
                <Link to={`/support/${value}/details`}>More details</Link>
            ),
        },
    ],
};

export default columnData;
