/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

// Dashboard API endpoints
export const dashboardApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGeneralStatistics: builder.query({
            query: (id) => {
                if (id) {
                    return `dashboard/general_statistics/${id}`;
                } else {
                    return "dashboard/general_statistics";
                }
            },
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000,
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getCountryHectaresCovered: builder.query({
            query: () => "dashboard/hectares_covered_by_country",
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000,
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getLoanGraph: builder.query({
            query: (id) => {
                if (id) {
                    return `dashboard/loans_by_graph/${id}`;
                } else {
                    return "dashboard/loans_by_graph";
                }
            },
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000,
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getLoanCalculations: builder.query({
            query: (id) => {
                if (id) {
                    return `dashboard/loans_by_calculations/${id}`;
                } else {
                    return "dashboard/loans_by_calculations";
                }
            },
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000,
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
        }),
        getFarmerAgentGraph: builder.query({
            query: (id) => {
                if (id) {
                    return `dashboard/farmer_agent_by_graph/${id}`;
                } else {
                    return "dashboard/farmer_agent_by_graph";
                }
            },
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000,
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
        }),
    }),
});

export const {
    useGetGeneralStatisticsQuery,
    useGetCountryHectaresCoveredQuery,
    useGetLoanCalculationsQuery,
    useGetLoanGraphQuery,
    useGetFarmerAgentGraphQuery,
} = dashboardApi;

// Dashboard slice
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        dashboard: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            dashboardApi.endpoints.getGeneralStatistics.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
        builder.addMatcher(
            dashboardApi.endpoints.getCountryHectaresCovered.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
    },
});

export const dashboardReducer = dashboardSlice.reducer;

export default dashboardSlice;
