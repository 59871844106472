/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import { apiKey } from ".";
import styled from "styled-components";
import SoftBox from "components/SoftBox";
import tree from "assets/images/shapes/tree.jpg";

const style = { paddingBottom: 10, fontSize: 14, fontWeight: 400 };

const TreesMap = ({ height = "80vh", data, center }) => {
    const [selected, setSelected] = useState(null);

    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                id='example-map_trees'
                mapContainerStyle={{ height: `${height}`, width: "100%" }}
                zoom={9}
                center={{
                    lat: center.lat,
                    lng: center.long,
                }}
            >
                {data.map((location, index) => (
                    <Marker
                        key={index}
                        position={{
                            lat: location.lat,
                            lng: location.long,
                        }}
                        options={{
                            icon: tree,
                        }}
                        onClick={() => {
                            setSelected({
                                ...location,
                                position: {
                                    lat: location.lat,
                                    lng: location.long,
                                },
                            });
                        }}
                    />
                ))}

                {selected && (
                    <InfoWindow
                        style={{ padding: "1rem" }}
                        position={selected.position}
                        onCloseClick={() => {
                            setSelected(null);
                        }}
                    >
                        <PopUpStyle>
                            <SoftBox>
                                <h4>Tree height</h4>
                                <p style={style}>10</p>
                                <h4>Tree age</h4>
                                <p style={style}>2 years</p>
                                <h4> Agent</h4>
                                <p style={style}>Fabrice</p>

                                <h4>Status</h4>
                                <p style={style}>{0}</p>
                                <h4>Tree location</h4>
                                <p style={style}>{"Rwanda, kigali city"}</p>
                                <h4>Planted date</h4>
                                <p style={style}>{"2023/12/01"}</p>
                                <h4>Geolocation</h4>
                                <p style={style}>
                                    {selected?.lat}, {selected?.lng}
                                </p>
                                <SoftBox>
                                    <Link
                                        to={`/farmer/forest/${selected.id}/trees`}
                                    >
                                        More details
                                    </Link>
                                </SoftBox>
                            </SoftBox>
                        </PopUpStyle>
                    </InfoWindow>
                )}
            </GoogleMap>
        </LoadScript>
    );
};

const PopUpStyle = styled.div`
    width: 400px;
`;

export default TreesMap;
