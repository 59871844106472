/** @format */

// @mui material components
import { Divider, Icon, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import OrgCard from "eco/Cards/OrgCard";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import DataTable from "eco/Tables/DataTable";
import { useGetAllCountriesQuery } from "features/slices/countrySlice";
import { useState, useCallback } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import columnDataProject from "./data/columnData";
import { useGetAllProjectQuery } from "features/slices/projectSlice";
import breakpoints from "assets/theme/base/breakpoints";
import SoftTypography from "components/SoftTypography";

function General() {
    const [selectedCountry, setSelectedCountry] = useState("");
    const { data: CountriesList } = useGetAllCountriesQuery();

    const { values } = breakpoints;

    const {
        data: projects,
        isLoading: isProjectLoading,
        isFetching: projectFetching,
    } = useGetAllProjectQuery();
    console.log(projects);
    const [CountryMenu, setCountryMenu] = useState(null);
    const openCountryMenu = (event) => setCountryMenu(event.currentTarget);
    const closeCountryMenu = () => setCountryMenu(null);

    const navigate = useNavigate();
    // Memoized function using useCallback
    const handleCountryFilterChange = useCallback((country) => {
        setSelectedCountry(country);
    }, []);

    // const renderCountryMenu = () => (
    //     <Menu
    //         anchorEl={CountryMenu}
    //         anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    //         transformOrigin={{ vertical: "top", horizontal: "left" }}
    //         open={Boolean(CountryMenu)}
    //         onClose={closeCountryMenu}
    //         keepMounted
    //     >
    //         {CountriesList && CountriesList?.length > 0 && (
    //             <>
    //                 {CountriesList.map((country, index) => (
    //                     <MenuItem
    //                         id={index}
    //                         onClick={() => {
    //                             handleCountryFilterChange({
    //                                 id: country?.id || "",
    //                                 name: country?.name || "",
    //                             });
    //                             closeCountryMenu();
    //                         }}
    //                     >
    //                         {country?.name}
    //                     </MenuItem>
    //                 ))}
    //                 <Divider sx={{ margin: "0.5rem 0" }} />

    //                 {selectedCountry?.name && (
    //                     <MenuItem
    //                         onClick={() => {
    //                             handleCountryFilterChange("");
    //                             closeCountryMenu();
    //                         }}
    //                     >
    //                         All
    //                     </MenuItem>
    //                 )}
    //             </>
    //         )}
    //     </Menu>
    // );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Project Statistics
                </SoftTypography>

                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        count={30}
                                        suffix=''
                                        title='Loan collected'
                                        href={"/loans/all"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        count={1000}
                                        suffix=''
                                        title='Tree recorded'
                                        href={"#"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        count={132}
                                        suffix=''
                                        title='Trees Replaced'
                                        href={"#"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        count={882}
                                        suffix=''
                                        title='Trees survived'
                                        href={"#"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    mb={2}
                >
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Project List
                    </SoftTypography>

                    <SoftBox display='flex'>
                        <SoftButton
                            variant='gradient'
                            color='dark'
                            handleClick={() => navigate("/project/new")}
                        >
                            New project
                        </SoftButton>
                        {/* <SoftButton
                            ml={2}
                            variant={CountryMenu ? "contained" : "outlined"}
                            color='secondary'
                            handleClick={openCountryMenu}
                        >
                            {selectedCountry?.name || (
                                <>
                                    Filter by country&nbsp;
                                    <Icon>keyboard_arrow_down</Icon>
                                </>
                            )}
                        </SoftButton>
                        {renderCountryMenu()} */}
                    </SoftBox>
                </SoftBox>

                <LoadingCardSkeleton
                    isLoading={isProjectLoading || projectFetching}
                    height='333px'
                >
                    <Card>
                        <DataTable
                            table={columnDataProject}
                            entriesPerPage={5}
                            canSearch
                            theData={projects?.data}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
}

export default General;
