/** @format */

import React from "react";
import SupportStatus from "../status";

const DeclinedTasks = () => {
    return <SupportStatus status={"Declined"} title={"Declined Tasks"} />;
};

export default DeclinedTasks;
