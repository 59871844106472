/** @format */

import * as yup from "yup";
import form from "./form";

const {
    formField: { password, repeatPassword },
} = form;

const validations = yup.object().shape({
    [password.name]: yup
        .string()
        .required(password.errorMsg)
        .min(6, password.invalidMsg),
    [repeatPassword.name]: yup
        .string()
        .required(repeatPassword.errorMsg)
        .min(6, repeatPassword.invalidMsg),
});

export default validations;
