/** @format */

import {
    Card,
    CircularProgress,
    Divider,
    Grid,
    Snackbar,
    Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import MuiAlert from "@mui/material/Alert";
import SoftButton from "components/SoftButton";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import OrgCard from "eco/Cards/OrgCard";
import { useGetOrganizationByIdQuery } from "features/slices/organizationSlice";
import { useGetOrganizationStatByIdQuery } from "features/slices/organizationSlice";
import { ADMIN_BASE_URL } from "constants";
import breakpoints from "assets/theme/base/breakpoints";
import DoneIcon from "@mui/icons-material/Done";
import { useUpdateOrganizationLogoMutation } from "features/slices/organizationSlice";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import { useGetPaymentByOrganizationQuery } from "features/slices/paymentSlice";
import DataTable from "eco/Tables/DataTable";
import allPaymentColumn from "../payments/data/allPaymentColumns";
import SoftInput from "components/SoftInput";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const OrganizationDetail = () => {
    const { id } = useParams();
    const { values } = breakpoints;
    const [open, setOpen] = useState(false);
    const [openLogo, setOpenLogo] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [logo, setLogo] = useState("");
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(!showError);
    const { data, isLoading: LoadingPayment } =
        useGetPaymentByOrganizationQuery(id);
    const payments = data && data?.data;

    const handleClick = () => {
        setOpenLogo(true);
    };

    const {
        data: organization,
        isLoading,
        refetch,
    } = useGetOrganizationByIdQuery(id);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenLogo(false);
    };
    const [previewUrl, setPreviewUrl] = useState(null);

    const [updateLogo, { isLoading: updateLoading, isError, error }] =
        useUpdateOrganizationLogoMutation();

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogo(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleUpdateLogo = async () => {
        const formData = new FormData();
        formData.append("logo", logo);
        try {
            const response = await updateLogo({
                id: id,
                values: formData,
            }).unwrap();
            if (response?.status === "success") {
                handleClick();
            }
        } catch (error) {
            toggleShowError(true);
        } finally {
            refetch();
        }
    };

    const navigate = useNavigate();

    const { data: organizationStat } = useGetOrganizationStatByIdQuery(id);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                message='Copied to clibboard'
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
            />

            <Snackbar
                open={openLogo}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    Logo updated!!!
                </Alert>
            </Snackbar>

            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Organization statistics
                </SoftTypography>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoading}
                                        height='133px'
                                    >
                                        <OrgCard
                                            count={
                                                organizationStat?.data?.projects
                                            }
                                            suffix=''
                                            title='Number of projects'
                                            href={"#"}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoading}
                                        height='133px'
                                    >
                                        <OrgCard
                                            count={
                                                organizationStat?.data
                                                    ?.beneficiaries
                                            }
                                            suffix=''
                                            title='Beneficiaries'
                                            href={"#"}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Card
                                        style={{
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            padding: "2.8rem",
                                        }}
                                    >
                                        <SoftButton
                                            variant='gradient'
                                            color='dark'
                                            handleClick={() =>
                                                navigate(`/project/new`)
                                            }
                                        >
                                            New project
                                        </SoftButton>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            {!isLoading && (
                <SoftBox>
                    <Card
                        style={{
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: "2rem",
                        }}
                    >
                        <SoftTypography variant='h4' fontWeight='bold'>
                            Organization details
                        </SoftTypography>
                        <Container>
                            <InfoContainer>
                                <SoftBox pt={1} pb={3} px={2}>
                                    <SoftBox mb={3}>
                                        <Grid
                                            container
                                            spacing={3}
                                            alignItems='center'
                                        >
                                            <Grid item xs={12} md={6}>
                                                <SoftBox
                                                    display='flex'
                                                    alignItems='center'
                                                >
                                                    <SoftBox mr={2}>
                                                        <ImageContainer>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",
                                                                    gap: "0.5rem",
                                                                }}
                                                            >
                                                                <ImageFrame>
                                                                    <Image
                                                                        src={
                                                                            previewUrl
                                                                                ? previewUrl
                                                                                : ADMIN_BASE_URL +
                                                                                  organization
                                                                                      ?.data
                                                                                      ?.logo
                                                                        }
                                                                        alt='Gold Glasses'
                                                                    />
                                                                </ImageFrame>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    <UpLoad htmlFor='logo'>
                                                                        UpLoad
                                                                        Logo
                                                                    </UpLoad>
                                                                    <FileUpload
                                                                        type='file'
                                                                        onChange={
                                                                            handleImageChange
                                                                        }
                                                                        accept='image/*'
                                                                        name='logo'
                                                                        id='logo'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",
                                                                }}
                                                            >
                                                                <SoftButton
                                                                    handleClick={
                                                                        handleUpdateLogo
                                                                    }
                                                                    color='dark'
                                                                >
                                                                    {updateLoading ? (
                                                                        <CircularProgress
                                                                            size={
                                                                                24
                                                                            }
                                                                            style={{
                                                                                color: "#ffffff",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        ` update logo`
                                                                    )}
                                                                </SoftButton>
                                                                {showError &&
                                                                    isError && (
                                                                        <ErrorMessageResponse>
                                                                            {
                                                                                error
                                                                                    ?.data
                                                                                    ?.message
                                                                            }
                                                                        </ErrorMessageResponse>
                                                                    )}
                                                            </div>
                                                        </ImageContainer>
                                                    </SoftBox>
                                                    <SoftBox lineHeight={1}>
                                                        <SoftTypography
                                                            variant='h6'
                                                            fontWeight='medium'
                                                        >
                                                            {
                                                                organization
                                                                    ?.data?.name
                                                            }
                                                        </SoftTypography>
                                                        <SoftBox mb={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {
                                                                    organization
                                                                        ?.data
                                                                        ?.location_name
                                                                }
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                sx={{ textAlign: "right" }}
                                            >
                                                <SoftButton
                                                    variant='gradient'
                                                    color='info'
                                                >
                                                    new organization
                                                </SoftButton>
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                    <Divider />
                                    <SoftBox mt={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <SoftTypography
                                                    variant='h6'
                                                    fontWeight='medium'
                                                >
                                                    Contact Info
                                                </SoftTypography>
                                                <SoftBox mt={2}>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Email:{" "}
                                                        <span>
                                                            {
                                                                organization
                                                                    ?.data
                                                                    ?.contact_email
                                                            }
                                                        </span>
                                                    </SoftTypography>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Phone:{" "}
                                                        {
                                                            organization?.data
                                                                ?.contact_phone
                                                        }
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <SoftTypography
                                                    variant='h6'
                                                    fontWeight='medium'
                                                >
                                                    Other Info
                                                </SoftTypography>
                                                <SoftBox mt={2}>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Head Line:{" "}
                                                        <span>
                                                            {organization?.data
                                                                ?.headline ||
                                                                "none"}
                                                        </span>
                                                    </SoftTypography>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Sub Text:{" "}
                                                        {organization?.data
                                                            ?.sub_text ||
                                                            "none"}
                                                    </SoftTypography>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Price per tree: RWF
                                                        {
                                                            organization?.data
                                                                ?.price_per_tree
                                                        }
                                                    </SoftTypography>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        CTA:{" "}
                                                        {organization?.data
                                                            ?.cta || "-"}
                                                    </SoftTypography>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Currency:{" "}
                                                        {
                                                            organization?.data
                                                                ?.currenncy
                                                        }
                                                    </SoftTypography>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Currency Symbol:{" "}
                                                        {
                                                            organization?.data
                                                                ?.currency_symbol
                                                        }
                                                    </SoftTypography>

                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Rate:{" "}
                                                        {organization?.data
                                                            ?.rate || 0}
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <SoftBox>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Links info
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftBox mt={2}>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Website:{" "}
                                                        <LinkSocial>
                                                            click here
                                                        </LinkSocial>
                                                    </SoftTypography>
                                                    <SoftTypography
                                                        variant='h6'
                                                        fontWeight='medium'
                                                    >
                                                        Link:{" "}
                                                        <LinkSocial>
                                                            public link
                                                        </LinkSocial>
                                                    </SoftTypography>
                                                    <CopyButton
                                                        text={"Impact Link"}
                                                        url={`https://ecoforest.green/impacts/${organization?.data?.slug}`}
                                                        setOpen={setOpen}
                                                    />
                                                    <CopyButton
                                                        text={"QRCode Link"}
                                                        url={`https://ecoforest.green/plant/${organization?.data?.slug}/trees`}
                                                        setOpen={setOpen}
                                                    />
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                </SoftBox>
                            </InfoContainer>
                        </Container>
                    </Card>
                    {payments && payments?.length !== 0 && (
                        <SoftBox pt={3}>
                            <SoftTypography
                                variant={
                                    window.innerWidth < values.sm ? "h3" : "h2"
                                }
                                textTransform='capitalize'
                                fontWeight='bold'
                                gutterBottom
                            >
                                Payments
                            </SoftTypography>
                            <div
                                style={{
                                    display: "flex",
                                    maxWidth: "420px",
                                    fontFamily: "Poppins",
                                    gap: "1rem",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "3px",
                                    }}
                                >
                                    <label className='text-sm'>FROM</label>
                                    <SoftInput
                                        type='date'
                                        name='start'
                                        value={startDate}
                                        onChange={(e) =>
                                            setStartDate(e.target.value)
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "3px",
                                    }}
                                >
                                    <label>TO</label>
                                    <SoftInput
                                        type='date'
                                        name='end'
                                        value={endDate}
                                        onChange={(e) =>
                                            setEndDate(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <SoftBox my={3}>
                                <LoadingCardSkeleton
                                    isLoading={LoadingPayment}
                                    height='333px'
                                >
                                    <Card>
                                        <DataTable
                                            table={allPaymentColumn}
                                            entriesPerPage={5}
                                            canSearch
                                            theData={payments}
                                        />
                                    </Card>
                                </LoadingCardSkeleton>
                            </SoftBox>
                        </SoftBox>
                    )}
                </SoftBox>
            )}
        </DashboardLayout>
    );
};

function CopyButton({ text, setOpen, url }) {
    const [isClicked, setIsClicked] = useState(false);
    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(url);
        setIsClicked(true);
    };
    return (
        <Tooltip title='click to copy'>
            <CopyButtonContainer onClick={handleClick}>
                <CopyButtonText>{text}</CopyButtonText>
                {isClicked ? <DoneIcon /> : <ContentCopyIcon color='action' />}
            </CopyButtonContainer>
        </Tooltip>
    );
}

const CopyButtonContainer = styled.div`
    margin-top: 0.5rem;
    padding: 0.625rem 1rem;
    background: #f4f4f4;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
`;

const ImageContainer = styled.div`
    max-width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.625rem;
`;

const ImageFrame = styled.div`
    width: 100%;
    height: 200px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const UpLoad = styled.label`
    font-family: Roboto, Helvetica, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    text-align: center;
    padding: 0.6rem 1.5rem;
    border: 1px solid #bfbfbd;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

const FileUpload = styled.input`
    display: none;
`;

const CopyButtonText = styled.span`
    font-family: Roboto, Helvetica, Arial, Helvetica, sans-serif;
    font-size: 1rem;
`;

const Container = styled.div`
    display: flex;
    gap: 2rem;
`;

const InfoContainer = styled.div`
    flex: 1;
`;

const LinkSocial = styled(Link)`
    color: #040404;
    font-weight: lighter;
    &:hover {
        text-decoration: underline !important;
    }
`;

export default OrganizationDetail;
