/** @format */

const reportsDoughtnutChartData = {
    labels: ["Loans", "Application submitted", "Loans under review"],
    datasets: {
        label: "Farmers status",
        backgroundColors: ["primary", "secondary", "info"],
        data: [15, 20, 13],
    },
};

export default reportsDoughtnutChartData;
