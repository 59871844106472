/** @format */

const Farmerform = {
    formId: "new-farmer-form",
    formField: {
        first_name: {
            name: "first_name",
            label: "First Name",
            type: "text",
            placeholder: "e.g. John",
            errorMsg: "First name is required.",
        },
        last_name: {
            name: "last_name",
            label: "Last Name",
            type: "text",
            placeholder: "e.g. Doe",
            errorMsg: "Last name is required.",
        },
        dob: {
            name: "dob",
            label: "Date of Birthday",
            type: "date",
            placeholder: "eg. 1970-01-01",
            errorMsg: "Date is required.",
        },
        education: {
            name: "education",
            label: "Education",
            type: "select",
            options: [
                { id: "1", name: "primary" },
                { id: "2", name: "ordinary" },
                { id: "3", name: "high school" },
                { id: "4", name: "university" },
            ],
            errorMsg: "Education is required.",
        },
        marital_status: {
            name: "marital_status",
            label: "Marital status",
            type: "select",
            options: [
                { id: "1", name: "single" },
                { id: "2", name: "married" },
                { id: "3", name: "divorced" },
                { id: "4", name: "separated" },
                { id: "5", name: "widowed" },
            ],
            errorMsg: "Marital status is required.",
        },
        phone: {
            name: "phone",
            label: "Phone",
            type: "string",
            placeholder: "eg. 0788xxxxxx",
            errorMsg: "Phone is required.",
            invalidMsg: "Your phone is invalid",
        },
        province: {
            name: "province",
            label: "Province",
            type: "select",
            options: [],
            errorMsg: "Province is required.",
        },

        district: {
            name: "district",
            label: "District",
            type: "select",
            options: [],
            errorMsg: "District is required.",
        },
        sector: {
            name: "sector",
            label: "Sector",
            type: "select",
            options: [],
            errorMsg: "Sector is required.",
        },
        cell: {
            name: "cell",
            label: "Cell",
            type: "select",
            options: [],
            errorMsg: "Cell is required.",
        },

        village: {
            name: "village",
            label: "Village",
            type: "select",
            options: [],
            errorMsg: "Village is required.",
        },
        country_id: {
            name: "country_id",
            label: "Country",
            type: "select",
            options: [
                { id: 1, name: "Rwanda" },
                // { id: 2, name: "Burundi" },
                // { id: 3, name: "Kenya" },
                // { id: 4, name: "Tanzania" },
            ],
            errorMsg: "Country is required.",
        },
    },
};

export default Farmerform;
