/** @format */

export const geolocations = [
    {
        country: "Rwanda",
        levelsNum: 5,
        locations: [
            {
                level: 1,
                name: "province",
            },
            {
                level: 2,
                name: "district",
            },
            {
                level: 3,
                name: "sector",
            },
            {
                level: 4,
                name: "cell",
            },
            {
                level: 5,
                name: "village",
            },
        ],
    },
];

export const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (age > 0) {
        return `${age} years`;
    } else if (monthDiff > 0) {
        return `${monthDiff} months`;
    } else {
        return `${dayDiff} days`;
    }
};

const IMG_BASE_URL_LOCAL = "http://localhost:5002/";
export const ADMIN_BASE_URL = "http://ecoforest.green/";
export const MOBILE_BASE_URL = "http://roots.ecoforest.green/";
export const BASE_API_URL = "https://ecoforest.green/api/v1";
