/** @format */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://ecoforest.green/api/v1/",
        prepareHeaders: (headers, { getState }) => {
            const state = getState();
            const token = state?.user?.token;
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: [
        "users",
        "farmers",
        "agents",
        "country",
        "task",
        "loanType",
        "fertilizerType",
        "province",
        "cell",
        "district",
        "sector",
        "village",
        "project",
        "support",
        "payment",
        "treeSpecies",
        "forest-release",
    ],
    endpoints: (builder) => ({}),
});
