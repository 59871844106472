/** @format */

import { Card, Divider, Grid, Icon, Menu, MenuItem } from "@mui/material";
import SoftBox from "components/SoftBox";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import OrgCard from "eco/Cards/OrgCard";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import DataTable from "eco/Tables/DataTable";
import { useGetAllSupportsQuery } from "features/slices/supportSlice";
import { useGetSupportStatisticsQuery } from "features/slices/supportSlice";
import React, { useCallback, useState } from "react";
import columnData from "./data/column";
import SoftButton from "components/SoftButton";
import { supportStatus } from "constants/data";

const SupportList = () => {
    const { data: supportStats, isLoading: isLoadingStat } =
        useGetSupportStatisticsQuery();

    const [CountryMenu, setCountryMenu] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState({
        id: "",
        name: "",
    });
    const openCountryMenu = (event) => setCountryMenu(event.currentTarget);
    const closeCountryMenu = () => setCountryMenu(null);

    // Memoized function using useCallback
    const handleCountryFilterChange = useCallback((country) => {
        setSelectedCountry(country);
    }, []);

    const {
        data: supports,
        isLoadingSupport,
        isFetching,
    } = useGetAllSupportsQuery(selectedCountry?.name);

    const renderCountryMenu = () => (
        <Menu
            anchorEl={CountryMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(CountryMenu)}
            onClose={closeCountryMenu}
            keepMounted
        >
            {supportStatus && supportStatus?.length > 0 && (
                <>
                    {supportStatus.map((status, index) => (
                        <MenuItem
                            id={index}
                            onClick={() => {
                                handleCountryFilterChange({
                                    id: status.id || "",
                                    name: status.status || "",
                                });
                                closeCountryMenu();
                            }}
                        >
                            {status.status}
                        </MenuItem>
                    ))}
                    <Divider sx={{ margin: "0.5rem 0" }} />

                    {selectedCountry?.name && (
                        <MenuItem
                            onClick={() => {
                                handleCountryFilterChange({ name: "", id: 0 });
                                closeCountryMenu();
                            }}
                        >
                            All
                        </MenuItem>
                    )}
                </>
            )}
        </Menu>
    );
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingStat}
                                        height='150px'
                                    >
                                        <OrgCard
                                            title='Completed'
                                            count={
                                                supportStats?.data
                                                    .completedCount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingStat}
                                        height='150px'
                                    >
                                        <OrgCard
                                            title='Pending'
                                            count={
                                                supportStats?.data.pendingCount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingStat}
                                        height='150px'
                                    >
                                        <OrgCard
                                            title='In Process'
                                            count={
                                                supportStats?.data
                                                    ?.inprogresscount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingStat}
                                        height='150px'
                                    >
                                        <OrgCard
                                            title='On Hold'
                                            count={
                                                supportStats?.data?.obholdcount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoadingStat}
                                        height='150px'
                                    >
                                        <OrgCard
                                            title='Declined'
                                            count={
                                                supportStats?.data
                                                    ?.declinedcount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='end'
                    alignItems='flex-start'
                    mb={2}
                >
                    <SoftBox display='flex'>
                        <SoftButton
                            variant={CountryMenu ? "contained" : "outlined"}
                            color='secondary'
                            handleClick={openCountryMenu}
                        >
                            {selectedCountry?.name || (
                                <>
                                    Filter by status&nbsp;
                                    <Icon>keyboard_arrow_down</Icon>
                                </>
                            )}
                        </SoftButton>
                        {renderCountryMenu()}
                        <SoftBox ml={1}>
                            <SoftButton variant='outlined' color='secondary'>
                                <Icon>description</Icon>
                                &nbsp;export csv
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
                <LoadingCardSkeleton
                    isLoading={isLoadingSupport || isFetching}
                    height='333px'
                >
                    <Card>
                        <DataTable
                            table={columnData}
                            entriesPerPage={false}
                            canSearch
                            theData={supports?.data}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default SupportList;
