/** @format */

import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import DataTable from "eco/Tables/DataTable";
import breakpoints from "assets/theme/base/breakpoints";
import { useGetAllPaymentQuery } from "features/slices/paymentSlice";
import React from "react";
import allPaymentColumn from "./data/allPaymentColumns";

const Payment = () => {
    const { data, isLoading } = useGetAllPaymentQuery();
    const payments = data && data?.data;
    const { values } = breakpoints;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Payments
                </SoftTypography>
                <SoftBox my={3}>
                    <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                        <Card>
                            <DataTable
                                table={allPaymentColumn}
                                entriesPerPage={5}
                                canSearch
                                theData={payments}
                            />
                        </Card>
                    </LoadingCardSkeleton>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
};

export default Payment;
