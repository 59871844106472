/** @format */

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "eco/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav } from "context";

// Images
import NewAgent from "layouts/pages/agents/AddAgent";

//
import FarmerDetails from "layouts/pages/farmers/farmer-details";
import SignInIllustration from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/reset-password";
import FarmerLoans from "layouts/pages/farmers/loans";
import FarmerHarvests from "layouts/pages/farmers/harvests";
import CountryDashboard from "layouts/dashboards/default/CountryDashboard";
import Settings from "layouts/pages/account/settings";
import RequireAuth from "context/RequireAuth";

import CountryList from "layouts/pages/country";
import FarmerForests from "layouts/pages/farmers/farmeForests";
import ForestTrees from "layouts/pages/forests/ForestTrees";
import BreadcrumbApp from "layouts/pages/test";
import OrganizationDetail from "layouts/pages/organization/OrganizationDetail";
import AgentDetail from "layouts/pages/agents/AgentDetail";
import TaskList from "layouts/pages/task";
import TaskDetail from "layouts/pages/task/TaskDetail";
import LoanDetails from "layouts/pages/loans/loanDetails";

import UpdateSponsor from "layouts/pages/sponsor/UpdateSponsor";
import UpdateProject from "layouts/pages/projects/UpdateProject";
import ProjectDetail from "layouts/pages/projects/project-detail";
import CreateProjectBeneficiary from "layouts/pages/projects/beneficiary";
import CreateProjectForest from "layouts/pages/projects/forest";
import FarmerTrees from "layouts/pages/farmers/farmer-trees";
import ForestDetails from "layouts/pages/forests/forest-detail";
import DetailsSupport from "layouts/pages/supports/details";
import EditOrganization from "layouts/pages/organization/edit-orginazation";
import PaymentOrganization from "layouts/pages/payments/PaymentOrganization";
import TreeSpeciesDetail from "layouts/pages/tree-species/TreeSpeciesDetail";
import EditTreeSpecies from "layouts/pages/tree-species/edit";
import ForestReleaseDetail from "layouts/pages/forests/forest-release/ForestReleaseDetail";
import UserDetails from "layouts/pages/users/details/UserDetails";
import UserEdit from "layouts/pages/users/edit";
import ChangePassword from "layouts/pages/users/change-password";

export default function App() {
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, direction, layout, sidenavColor } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { pathname } = useLocation();

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);
    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return (
                    <Route
                        exact
                        path={route.route}
                        element={route.component}
                        key={route.key}
                    />
                );
            }

            return null;
        });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {layout === "dashboard" && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        routes={routes}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                    {/* <Configurator />
          {configsButton} */}
                </>
            )}
            <Routes>
                <Route path='/'>
                    {/*   Public Routes */}
                    <Route path='/login' element={<SignInIllustration />} />
                    <Route path='/reset-password' element={<ResetPassword />} />
                    <Route path='/test' element={<BreadcrumbApp />} />

                    {/* Pivate Routes */}

                    <Route element={<RequireAuth />}>
                        {getRoutes(routes)}
                        <Route
                            path='/farmers/farmer-details/:id'
                            exact
                            key='farmers'
                            element={<FarmerDetails />}
                        />
                        <Route
                            path='/farmers/:id/forest/upcoming-harvests'
                            exact
                            key='farmers'
                            element={<FarmerDetails />}
                        />
                        <Route
                            path='/farmers/:id/forest/harvests'
                            exact
                            key='farmers'
                            element={<FarmerHarvests />}
                        />
                        <Route
                            path='/tasks/:id/details'
                            exact
                            key='tasks'
                            element={<TaskDetail />}
                        />

                        <Route
                            path='/project/:id/update'
                            exact
                            key='project'
                            element={<UpdateProject />}
                        />

                        <Route
                            path='/project/:id/new/beneficiary/organization/:orgID/country/:countryID/sponsor/:sponsorID'
                            exact
                            key='project'
                            element={<CreateProjectBeneficiary />}
                        />

                        <Route
                            path='/project/:id/new/forest/country/:country/organization/:organization'
                            exact
                            key='project'
                            element={<CreateProjectForest />}
                        />

                        <Route
                            path='/forest/:id/details'
                            exact
                            key='forest'
                            element={<ForestDetails />}
                        />

                        <Route
                            path='/forest-release/:id/details'
                            exact
                            key='forest'
                            element={<ForestReleaseDetail />}
                        />

                        <Route
                            path='/support/:id/details'
                            exact
                            key='support'
                            element={<DetailsSupport />}
                        />

                        <Route
                            path='/project/:id/details'
                            exact
                            key='project'
                            element={<ProjectDetail />}
                        />

                        <Route
                            path='/loans/details/:id'
                            exact
                            key='loans'
                            element={<LoanDetails />}
                        />
                        <Route
                            path='/farmers/:id/loans'
                            exact
                            key='farmers'
                            element={<FarmerLoans />}
                        />
                        <Route
                            path='/sponsor/:id/update'
                            exact
                            key='sponsor'
                            element={<UpdateSponsor />}
                        />
                        <Route
                            path='/farmers/:id/forests'
                            exact
                            key='farmers'
                            element={<FarmerForests />}
                        />
                        <Route
                            path='/farmers/:id/trees/total'
                            exact
                            key='farmers'
                            element={<FarmerTrees />}
                        />
                        <Route
                            path='/agents/details/:id'
                            exact
                            key='agents'
                            element={<AgentDetail />}
                        />

                        <Route
                            path='/dashboard/country/:country'
                            exact
                            key='dashboard'
                            element={<CountryDashboard />}
                        />
                        <Route
                            path='/users/account/:id'
                            exact
                            key='users'
                            element={<Settings />}
                        />

                        <Route
                            path='/countries'
                            exact
                            key='countries'
                            element={<CountryList />}
                        />

                        <Route
                            path='/farmer/forest/:id/trees'
                            exact
                            key='farmers'
                            element={<ForestTrees />}
                        />
                        <Route
                            path='/tasks/list/:status'
                            exact
                            key='tasks'
                            element={<TaskList />}
                        />

                        <Route
                            path='/organization/details/:id'
                            exact
                            key='organization'
                            element={<OrganizationDetail />}
                        />

                        <Route
                            path='/payment/organization/:id'
                            exact
                            key='payment'
                            element={<PaymentOrganization />}
                        />

                        <Route
                            path='/tree-species/detail/:id'
                            exact
                            key='tree-species'
                            element={<TreeSpeciesDetail />}
                        />

                        <Route
                            path='/tree-species/edit/:id'
                            exact
                            key='tree-species'
                            element={<EditTreeSpecies />}
                        />

                        <Route
                            path='/organization/edit/:id'
                            exact
                            key='organization_edit'
                            element={<EditOrganization />}
                        />

                        <Route
                            path='/agent/add-new'
                            exact
                            key='agents'
                            element={<NewAgent />}
                        />
                        <Route
                            path='/users/details/:id'
                            exact
                            key='users'
                            element={<UserDetails />}
                        />

                        <Route
                            path='/users/edit/:id'
                            exact
                            key='users'
                            element={<UserEdit />}
                        />
                        <Route
                            path='/users/change-password/:id'
                            exact
                            key='users'
                            element={<ChangePassword />}
                        />
                    </Route>

                    {/* <Route path="*" element={<Navigate to="/dashboards/default" />} /> */}
                </Route>
            </Routes>
        </ThemeProvider>
    );
}
