/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import { NavLink } from "layouts/pages/organization/data/organizationColumns";
// import { Link } from "react-router-dom";

const columnDataForest = {
    columns: [
        {
            Header: "Forest name",
            accessor: "name",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Forest size",
            accessor: "forest_size",
            Cell: ({ value }) => (
                <DefaultCell value={`${value} hectars` ?? "size"} />
            ),
        },
        {
            Header: "Location",
            accessor: "location_name",
            Cell: ({ value }) => <DefaultCell value={value ?? "location"} />,
        },
        {
            Header: "Planting trees",
            accessor: "planting_tree",
            Cell: ({ value }) => (
                <DefaultCell value={value ?? "planting trees"} />
            ),
        },
        {
            Header: "Dead trees",
            accessor: "dead_tree",
            Cell: ({ value }) => <DefaultCell value={value ?? "dead trees"} />,
        },
        {
            Header: "Replaced trees",
            accessor: "replaced_tree",
            Cell: ({ value }) => (
                <DefaultCell value={value ?? "replaced trees"} />
            ),
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value, row }) => (
                <NavLink to={`/forest/${value}/details`}>More detail</NavLink>
            ),
        },
    ],
};

export default columnDataForest;
