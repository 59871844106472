const hectaresCovered = [
  {
    flag: "https://flagsapi.com/KE/shiny/32.png",
    country: "Kenya",
    forest: 0,
    trees: 123,
    hectares: 1353,
  },
  {
    flag: "https://flagsapi.com/AM/shiny/32.png",
    country: "Armenia",
    forest: 0,
    trees: null,
    hectares: 1353,
  },
];

export default hectaresCovered;
