/** @format */

import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

// Settings page components
import FormField from "components/FormField";

const EditSpecyInfo = ({ formData }) => {
    const { formField, values, errors, touched, setFieldValue } = formData;
    return (
        <>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>Tree Specy</SoftTypography>
                <SoftBox component='form' pb={3} px={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <FormField
                                type={formField.tree_specy.type}
                                label={formField.tree_specy.label}
                                name={formField.tree_specy.name}
                                value={values.tree_specy}
                                placeholder={formField.tree_specy.placeholder}
                                error={errors.tree_specy && touched.tree_specy}
                                success={
                                    values?.tree_specy?.length > 0 &&
                                    !errors?.tree_specy
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display='inline-block'
                            >
                                <SoftTypography
                                    component='label'
                                    variant='caption'
                                    fontWeight='bold'
                                    textTransform='capitalize'
                                >
                                    Tree class
                                </SoftTypography>
                            </SoftBox>
                            <Select
                                input={<SoftInput />}
                                value={values.tree_class}
                                error={errors.tree_class && touched.tree_class}
                                onChange={(event) => {
                                    setFieldValue(
                                        formField.tree_class.name,
                                        event.target.value
                                    );
                                    console.log(
                                        event.target.value,
                                        "option values"
                                    );
                                    setFieldValue(formField.tree_class.id, "");
                                }}
                            >
                                {formField.tree_class.options?.map(
                                    (roleOption) => (
                                        <MenuItem
                                            key={roleOption.id}
                                            value={roleOption.name}
                                        >
                                            {roleOption.name}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <FormField
                                type={formField.description.type}
                                label={formField.description.label}
                                name={formField.description.name}
                                value={values.description}
                                placeholder={formField.description.placeholder}
                                error={
                                    errors.description && touched.description
                                }
                                success={
                                    values?.description?.length > 0 &&
                                    !errors?.description
                                }
                            />
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </>
    );
};

export default EditSpecyInfo;
