/** @format */

import form from "./form";

const {
    formField: { beneficiary },
} = form;

const initialValues = {
    [beneficiary.name]: "",
};

export default initialValues;
