/**
 * eslint-disable react/prop-types
 *
 * @format
 */
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftBox from "components/SoftBox";
import DefaultCell from "components/dataTableComponents/DefaultCell";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const dataTableData = {
    columns: [
        {
            Header: "Request status",
            accessor: "request_status",
            Cell: ({ value }) => {
                const renderStatus = () => {
                    switch (value) {
                        case "Application_Submitted":
                            return (
                                <SoftBadgeDot
                                    size='xs'
                                    color='primary'
                                    badgeContent='Application Submitted'
                                />
                            );
                        case "Completed":
                            return (
                                <SoftBadgeDot
                                    size='xs'
                                    color='success'
                                    badgeContent='completed'
                                />
                            );
                        case "Under_Review":
                            return (
                                <SoftBadgeDot
                                    color='warning'
                                    size='xs'
                                    badgeContent='Under Review'
                                />
                            );
                        case "Declined":
                            return (
                                <SoftBadgeDot
                                    color='error'
                                    size='xs'
                                    badgeContent='Declined'
                                />
                            );
                        case "Approved":
                            return (
                                <SoftBadgeDot
                                    color='info'
                                    size='xs'
                                    badgeContent='Approved'
                                />
                            );
                        case "OnPause":
                            return (
                                <SoftBadgeDot
                                    color='info'
                                    size='xs'
                                    badgeContent='On Pause'
                                />
                            );
                        case "Ongoing":
                            return (
                                <SoftBadgeDot
                                    color='dark'
                                    size='xs'
                                    badgeContent='Ongoing'
                                />
                            );
                    }
                };
                return <SoftBox ml={-1.325}>{renderStatus()}</SoftBox>;
            },
        },
        {
            Header: "Farmer",
            accessor: "Farmer",
            Cell: ({ value }) => <DefaultCell value={value?.full_name} />,
        },
        {
            Header: "Loan amount",
            accessor: "amount_to_pay",
            Cell: ({ value }) => <DefaultCell value={value || "0"} />,
        },
        {
            Header: "Paid amount",
            accessor: "paid_amount",
            Cell: ({ value }) => <DefaultCell value={value || "0"} />,
        },
        {
            Header: "Loan rate",
            accessor: "loan_rate",
            Cell: ({ value }) => <DefaultCell value={value || "0"} />,
        },
        {
            Header: "Month to paid",
            accessor: "months_to_pay",
            Cell: ({ value }) => <DefaultCell value={value || "0"} />,
        },

        {
            Header: "Payment start date",
            accessor: "payment_start_date",
            Cell: ({ value }) => {
                const date = new Date(value);
                const formatDate = format(date, "dd-MM-yyyy");
                return <DefaultCell value={formatDate} />;
            },
        },
        {
            Header: "Payment end date",
            accessor: "payment_end_date",
            Cell: ({ value }) => {
                const date = new Date(value);
                const formatDate = format(date, "dd-MM-yyyy");
                return <DefaultCell value={formatDate} />;
            },
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value }) => (
                <Link to={`/loans/details/${value}`}>more details</Link>
            ),
        },
    ],
};

export default dataTableData;
