/** @format */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

function DataTableHeadCell({ width, children, sorted, align, ...rest }) {
    const { light } = colors;
    const { borderWidth } = borders;

    return (
        <SoftBox
            component='th'
            width={width}
            borderBottom={`${borderWidth[1]} solid ${light.main}`}
            py={1.5}
            px={3}
        >
            <SoftBox
                {...rest}
                position='relative'
                textAlign={align}
                color='secondary'
                opacity={0.7}
                sx={({ typography: { size, fontWeightBold } }) => ({
                    fontSize: size.xxs,
                    fontWeight: fontWeightBold,
                    textTransform: "uppercase",
                    cursor: sorted && "pointer",
                    userSelect: sorted && "none",
                })}
            >
                {children}
            </SoftBox>
        </SoftBox>
    );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
    width: "auto",
    sorted: "none",
    align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node.isRequired,
    sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
    align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
