import * as Yup from "yup";
import form from "./form";

const {
  formField: {
    first_name,
    last_name,
    whatsapp_phone,
    momo_phone,
    education,
    // location_name,
    level,
    dob,
    province,
    district,
    sector,
    village,
    country_id,
  },
} = form;

const validations = [
  Yup.object().shape({
    [first_name.name]: Yup.string().required(first_name.errorMsg),
    [last_name.name]: Yup.string().required(last_name.errorMsg),
    [whatsapp_phone.name]: Yup.string()
      .required(whatsapp_phone.errorMsg)
      .matches(/^[1-9]{10}$/, "Phone number must be exactly 10 digits"),
    [momo_phone.name]: Yup.string()
      .required(momo_phone.errorMsg)
      .matches(/^[1-9]{10}$/, "MOMO number must be exactly 10 digits"),
    [education.name]: Yup.string().required(education.errorMsg),
    [level.name]: Yup.string()
      .required(level.errorMsg)
      .oneOf(["province", "district", "sector", "cell", "village"]),
    [province.name]: Yup.string().required("This field is required"),
    [district.name]: Yup.string().required("This field is required"),
    [sector.name]: Yup.string().required("This field is required"),
    [village.name]: Yup.string().required("This field is required"),
    [dob.name]: Yup.date().required(dob.errorMsg),
    [country_id.name]: Yup.string().required(country_id.errorMsg),
  }),
];

export default validations;
