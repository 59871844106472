/** @format */

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import { useAddProjectBeneficiaryMutation } from "features/slices/projectSlice";
import { CircularProgress, Grid, Snackbar, Card } from "@mui/material";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import SoftButton from "components/SoftButton";
import BenefiaciaryForm from "./BenefiaciaryForm";
import { Form, Formik } from "formik";
import SoftBox from "components/SoftBox";

import form from "./schema/form";
import initialValues from "./schema/initialeValues";
import validations from "./schema/validations";
import { useParams, useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const CreateProjectBeneficiary = () => {
    const { id, orgID, countryID, sponsorID } = useParams();
    console.log(
        id,
        "ID",
        orgID,
        "ORG,",
        countryID,
        "COuntry",
        sponsorID,
        "sponsor"
    );
    const [CreateProjectBeneficiary, { isLoading, isError, error }] =
        useAddProjectBeneficiaryMutation();
    console.log(error);
    const { formId, formField } = form;

    const navigate = useNavigate();

    const currentValidation = validations[0];
    const [open, setOpen] = React.useState(false);
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(!showError);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const submitForm = async (values, actions) => {
        const benef = {
            ...values,
            organization_id: orgID,
            sponsor: sponsorID,
            country_id: countryID,
            project_id: id,
        };

        try {
            const result = await CreateProjectBeneficiary(benef).unwrap();
            if (result?.status) {
                actions.setSubmitting(false);
                handleClick();
                actions.resetForm();
            }
            // setTimeout(() => {
            //     navigate(`/project/${id}/details`);
            // }, 2000);
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    Project beneficiary created Successfully!
                </Alert>
            </Snackbar>
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={submitForm}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form id={formId} autoComplete='off'>
                                    <div
                                        style={{
                                            backgroundColor: "#FFF",
                                            boxShadow:
                                                "-2px 5px 5px 0px rgba(209,208,208)",
                                            borderRadius: "20px",
                                        }}
                                    >
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <BenefiaciaryForm
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                />

                                                <SoftBox
                                                    mt={2}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            ` Send`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {showError &&
                                                    !isLoading &&
                                                    isError && (
                                                        <ErrorMessageResponse>
                                                            {
                                                                error?.data
                                                                    ?.message
                                                            }
                                                        </ErrorMessageResponse>
                                                    )}
                                            </SoftBox>
                                        </SoftBox>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
        </DashboardLayout>
    );
};

export default CreateProjectBeneficiary;
