/** @format */

// For Sectors
import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

export const sectorApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSectorsByDistrictId: builder.query({
            query: (districtId) => `sectors/district/${districtId}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providersTags: ["sector"],
        }),
    }),
});

export const { useGetSectorsByDistrictIdQuery } = sectorApi;

const sectorTypeSlice = createSlice({
    name: "sector",
    initialState: {
        sectors: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            sectorApi.endpoints.getSectorsByDistrictId.matchFulfilled,
            (state, action) => {
                state.sectors = action.payload;
            }
        );
    },
});

export const sectorTypeReducer = sectorTypeSlice.reducer;

export default sectorTypeSlice;
