/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";

const organizationPaymentColumn = {
    columns: [
        {
            Header: "Ref.",
            accessor: "flw_ref",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Organization",
            accessor: "organization",
            Cell: ({ value }) => (
                <DefaultCell value={value?.name ?? "Address"} />
            ),
        },
        {
            Header: "Name",
            accessor: "names",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Phone number",
            accessor: "phone_number",
            Cell: ({ value }) => (
                <DefaultCell value={value ?? "Phone number"} />
            ),
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ value }) => <DefaultCell value={value ?? "Phone"} />,
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Currency",
            accessor: "organization?.currency",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Trial Deadline",
            accessor: "trial_deadline",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
    ],
};

export default organizationPaymentColumn;
