/** @format */

import * as Yup from "yup";
import form from "./form";

const {
    formField: {
        name,
        forest_size,
        province,
        district,
        sector,
        cell,
        village,
        beneficiary,
    },
} = form;

const validations = [
    Yup.object().shape({
        [name.name]: Yup.string().required("This field is required"),
        [forest_size.name]: Yup.number().required("This field is required"),
        [beneficiary.name]: Yup.string().required("This field is required"),
        [cell.name]: Yup.string().required("This field is required"),
        [district.name]: Yup.string().required("This field is required"),
        [sector.name]: Yup.string().required("This field is required"),
        [village.name]: Yup.string().required("This field is required"),
        [province.name]: Yup.string().required("This field is required"),
    }),
];

export default validations;
