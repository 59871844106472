/**
 * eslint-disable react/prop-types
 *
 * @format
 */

import SoftBadgeDot from "components/SoftBadgeDot";
import SoftBox from "components/SoftBox";
import DefaultCell from "components/dataTableComponents/DefaultCell";
import { format } from "date-fns";

const taskDetailColumns = {
    columns: [
        {
            Header: "Task status",
            accessor: "task_status",
            Cell: ({ value }) => (
                <SoftBox ml={-1.325}>
                    {value ? (
                        <SoftBadgeDot size='xs' badgeContent='completed' />
                    ) : (
                        <SoftBadgeDot
                            color='error'
                            size='xs'
                            badgeContent='pending'
                        />
                    )}
                </SoftBox>
            ),
        },
        {
            Header: "Todo Date",
            accessor: "todo_date",
            Cell: ({ value }) => {
                const date = new Date(value);
                const formatDate = format(date, "dd-MM-yyyy");
                return <DefaultCell value={formatDate} />;
            },
        },
    ],
};

export default taskDetailColumns;
