/** @format */

const form = {
    formId: "edit-tree-species-form",
    formField: {
        tree_specy: {
            name: "tree_specy",
            label: "Tree specy",
            type: "text",
            placeholder: "e.g. Orange",
            errorMsg: "tree specy is required.",
        },
        description: {
            name: "description",
            label: "Description",
            type: "text",
            placeholder: "e.g. Some description",
            errorMsg: "Description is required.",
        },
        tree_class: {
            name: "tree_class",
            label: "Tree class",
            options: [
                { id: "1", name: "trees" },
                { id: "2", name: "fruits" },
            ],
            errorMsg: "Tree is required.",
        },
    },
};

export default form;
