/** @format */

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import { useNavigate, useParams } from "react-router-dom";
import SoftBox from "components/SoftBox";
import { useGetProjectByIdQuery } from "features/slices/projectSlice";
import { Card, Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import styled from "styled-components";
import SoftButton from "components/SoftButton";
import { useGetProjectByBeneficiaryQuery } from "features/slices/projectSlice";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import DataTable from "eco/Tables/DataTable";
import columnProjectBeneficiary from "../data/columnProjectBeneficiary";
import { useGetProjectByForestQuery } from "features/slices/projectSlice";
import columnDataProjectForest from "../data/columnDataProjectForest";

function ProjectDetail() {
    const { id } = useParams();
    const { data: project } = useGetProjectByIdQuery(id);
    const {
        data: beneficiaries,
        isLoading: beneficiairyLoading,
        isFetching: beneficiaryFetching,
    } = useGetProjectByBeneficiaryQuery(id);

    const {
        data: forests,
        isLoading: forestLoading,
        isFetching: forestFetching,
    } = useGetProjectByForestQuery(id);

    const navigate = useNavigate();
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox display='flex' flexDirection='column' gap={"2rem"}>
                <Card
                    style={{
                        padding: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                    }}
                >
                    <SoftBox
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <SoftBox
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            style={{
                                with: "100%",
                                flex: 1,
                            }}
                        >
                            <SoftTypography variant='h4' fontWeight='bold'>
                                Project #{id} details
                            </SoftTypography>
                            <SoftButton
                                variant='gradient'
                                color='info'
                                handleClick={() => navigate("/project/new")}
                            >
                                New project
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                    <Container>
                        <InfoContainer>
                            <SoftBox pt={1} pb={3} px={2}>
                                <SoftBox mb={3}>
                                    <Grid
                                        container
                                        spacing={3}
                                        alignItems='center'
                                    >
                                        <Grid item xs={12} md={6}>
                                            <SoftBox
                                                display='flex'
                                                alignItems='center'
                                            >
                                                <SoftBox lineHeight={1}>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Project name:
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {
                                                                    project
                                                                        ?.data
                                                                        ?.project_name
                                                                }
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Organization :
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {
                                                                    project
                                                                        ?.data
                                                                        ?.organization
                                                                        ?.name
                                                                }
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Sponsor:
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {
                                                                    project
                                                                        ?.data
                                                                        ?.the_sponsor
                                                                        ?.name
                                                                }
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftBox>
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Country:
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {
                                                                    project
                                                                        ?.data
                                                                        ?.country
                                                                        .name
                                                                }
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </SoftBox>
                        </InfoContainer>
                    </Container>
                </Card>

                <Card
                    style={{
                        padding: "2rem",
                    }}
                >
                    <SoftBox>
                        <SoftBox
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <SoftTypography>
                                Project Beneficiaries
                            </SoftTypography>
                            <SoftButton
                                variant='gradient'
                                color='dark'
                                handleClick={() =>
                                    navigate(
                                        `/project/${id}/new/beneficiary/organization/${project?.data?.organization_id}/country/${project?.data?.country?.id}/sponsor/${project?.data?.sponsor}`
                                    )
                                }
                            >
                                new beneficiary
                            </SoftButton>
                        </SoftBox>

                        <SoftBox mt={3}>
                            <LoadingCardSkeleton
                                isLoading={
                                    beneficiairyLoading || beneficiaryFetching
                                }
                                height='333px'
                            >
                                <DataTable
                                    table={columnProjectBeneficiary}
                                    entriesPerPage={5}
                                    canSearch
                                    theData={beneficiaries?.data}
                                />
                            </LoadingCardSkeleton>
                        </SoftBox>
                    </SoftBox>
                </Card>

                <Card
                    style={{
                        padding: "2rem",
                    }}
                >
                    <SoftBox>
                        <SoftBox
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <SoftTypography>Project forest</SoftTypography>
                            <SoftButton
                                variant='gradient'
                                color='dark'
                                handleClick={() =>
                                    navigate(
                                        `/project/${id}/new/forest/country/${project?.data?.country?.id}/organization/:${project?.data?.organization_id}`
                                    )
                                }
                            >
                                new forest
                            </SoftButton>
                        </SoftBox>

                        <SoftBox mt={3}>
                            <LoadingCardSkeleton
                                isLoading={forestLoading || forestFetching}
                                height='333px'
                            >
                                <DataTable
                                    table={columnDataProjectForest}
                                    entriesPerPage={5}
                                    canSearch
                                    theData={forests?.data}
                                />
                            </LoadingCardSkeleton>
                        </SoftBox>
                    </SoftBox>
                </Card>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

const Container = styled.div`
    padding: 20px 40px;
    display: flex;
    gap: 1rem;
`;

const InfoContainer = styled.div`
    flex: 1;
`;

export default ProjectDetail;
