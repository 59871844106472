/** @format */

import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";

function BreadcrumbApp() {
    const geolocations = [
        {
            country: "Rwanda",
            levelsNum: 5,
            locations: [
                { level: 1, name: "province" },
                { level: 2, name: "district" },
                { level: 3, name: "sector" },
                { level: 4, name: "cell" },
                { level: 5, name: "village" },
            ],
        },
        {
            country: "USA",
            levelsNum: 2,
            locations: [
                { level: 1, name: "State" },
                { level: 2, name: "City" },
            ],
        },
    ];

    const [currentLevel, setCurrentLevel] = useState(0);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const handleClick = (country = "Rwanda") => {
        const location = geolocations.filter(
            (el) => el.country.toLowerCase() === country.toLowerCase()
        );

        if (currentLevel < location[0].levelsNum) {
            const newBreadcrumb = location[0].locations[currentLevel];
            setBreadcrumbs([...breadcrumbs, newBreadcrumb.name]);
            // setData[];
            setCurrentLevel(currentLevel + 1);
        }
    };

    const handleBreadcrumbClick = (index) => {
        const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
        setBreadcrumbs(newBreadcrumbs);
        setCurrentLevel(index + 1);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={7}>
                            <div>
                                <button onClick={handleClick}>
                                    Add Breadcrumb
                                </button>
                                <div>
                                    {breadcrumbs.map((breadcrumb, index) => (
                                        <span key={index}>
                                            <span
                                                onClick={() =>
                                                    handleBreadcrumbClick(index)
                                                }
                                            >
                                                {breadcrumb}
                                            </span>
                                            {index < breadcrumbs.length - 1 &&
                                                " > "}
                                        </span>
                                    ))}
                                </div>

                                <div></div>
                            </div>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default BreadcrumbApp;
