/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

const sessionApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSessionCount: builder.query({
            query: (period) => `sessions/${period}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getSessionCountRange: builder.query({
            query: ({ startDate, endDate }) =>
                `sessions/between-dates/startDate/${startDate}/endDate/${endDate}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
    }),
});

export const { useGetSessionCountQuery, useGetSessionCountRangeQuery } =
    sessionApi;

const sessionSlice = createSlice({
    name: "session",
    initialState: {
        session: null,
    },
    reducers: {},
});

export default sessionSlice;
