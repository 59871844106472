/** @format */

import { useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import Footer from "eco/Footer";
import DataTable from "eco/Tables/DataTable";
import Header from "../../profile/components/Header";

// Data
import harvestData from "../data/harvestData";
import { useGetFamerHarvestQuery } from "features/slices/farmerSlice";
import { useParams, useNavigate } from "react-router-dom";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";

function FarmerHarvests() {
    const { id } = useParams();
    const [typeMenu, settypeMenu] = useState(null);
    const navigate = useNavigate();
    const { data: farmerHarvests, isLoading } = useGetFamerHarvestQuery(id);

    const opentypeMenu = (event) => settypeMenu(event.currentTarget);
    const closetypeMenu = () => settypeMenu(null);

    const renderTypeMenu = (
        <Menu
            anchorEl={typeMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(typeMenu)}
            onClose={closetypeMenu}
            keepMounted
        >
            <MenuItem onClick={closetypeMenu}>Food</MenuItem>
            <MenuItem onClick={closetypeMenu}>Medicine</MenuItem>
            <MenuItem onClick={closetypeMenu}>Other</MenuItem>
        </Menu>
    );

    return (
        <DashboardLayout>
            <Header />
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    mb={2}
                >
                    <SoftButton
                        handleClick={() => {
                            navigate(`/farmers/farmer-details/${id}`);
                        }}
                        variant='gradient'
                        color='info'
                    >
                        Return
                    </SoftButton>

                    <SoftBox display='flex'>
                        <SoftButton
                            variant={typeMenu ? "contained" : "outlined"}
                            color='secondary'
                            onClick={opentypeMenu}
                        >
                            Filter by product&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </SoftButton>
                        {renderTypeMenu}

                        <SoftBox ml={1}>
                            <SoftButton variant='outlined' color='secondary'>
                                <Icon>description</Icon>
                                &nbsp;export csv
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
                <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                    <Card>
                        <DataTable
                            table={harvestData}
                            entriesPerPage={false}
                            canSearch
                            theData={farmerHarvests?.data}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default FarmerHarvests;
