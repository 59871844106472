/** @format */

import { Card, Grid } from "@mui/material";
import TreesMap from "components/GoodMap/TreesMap";
import SoftBox from "components/SoftBox";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { forestCoordonate } from "constants/data";
import OrgCard from "eco/Cards/OrgCard";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useGetforestByFarmerIdQuery } from "features/slices/forest";
import React from "react";
import { useNavigate } from "react-router-dom";

const FarmerTrees = () => {
    const { id } = useNavigate();
    const { isLoading: ForestIsFetching } = useGetforestByFarmerIdQuery(id, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });
    const center = {
        lat: -1.942155,
        long: 29.2209719,
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Total trees'
                                        count={663}
                                        percentage='Planted'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Total revenue'
                                        count={259864}
                                        percentage='FRW'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Hectares restored'
                                        count={49}
                                        percentage='FRW'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard title='Tasks' count={90} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>

            <SoftBox mt={3}>
                <LoadingCardSkeleton
                    isLoading={ForestIsFetching}
                    height='193px'
                >
                    <Card container spacing={3} style={{ padding: 10 }}>
                        <TreesMap center={center} data={forestCoordonate} />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default FarmerTrees;
