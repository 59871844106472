/* eslint-disable react/prop-types */
import IdCell from "components/dataTableComponents/IdCell";
import DefaultCell from "components/dataTableComponents/DefaultCell";

const dataTableData = {
  columns: [
    // {
    //   Header: "id",
    //   accessor: "id",
    //   Cell: ({ value }) => (
    //     <IdCell
    //       handleClick={true}
    //       url={`/users/account`}
    //       style={{ cursor: "pointer" }}
    //       id={value}
    //     />
    //   ),
    // },
    {
      Header: "full name",
      accessor: "first_name",
      Cell: ({ row }) => (
        <DefaultCell
          value={
            `${row.original.first_name} ${row.original.last_name}` ?? "N/A"
          }
        />
      ),
    },
    {
      Header: "role",
      accessor: "role",
      Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
    },
    {
      Header: "email",
      accessor: "email",
      Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
    },
    {
      Header: "country",
      accessor: "country",
      Cell: ({ value }) => <DefaultCell value={value?.name ?? "N/A"} />,
    },
  ],
};

export default dataTableData;
