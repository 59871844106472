/** @format */

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import React, { useState } from "react";
/** @format */

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import MuiAlert from "@mui/material/Alert";

import validations from "./schema/validation";
import form from "./schema/form";
import initialValues from "./schema/initialValue";
import { CircularProgress, Snackbar } from "@mui/material";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";

import OrganizationInfo from "./OrganizationInfo";
import { useCreateOrganizationMutation } from "features/slices/organizationSlice";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const CreateNew = () => {
    const [createOrganization, { isLoading, isError, error }] =
        useCreateOrganizationMutation();
    const { formId, formField } = form;

    const currentValidation = validations[0];
    const [open, setOpen] = React.useState(false);
    const [location, setLocation] = useState([]);
    const [logo, setLogo] = useState();
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(!showError);
    const navigate = useNavigate();
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const submitForm = async (values, actions) => {
        const formData = new FormData();
        formData.append("website", values.website);
        formData.append("name", values.name);
        formData.append("logo", logo);
        formData.append("country_id", values.country_id);
        formData.append("person_contact", values.person_contact);
        formData.append("contact_email", values.contact_email);
        formData.append("contact_phone", values.contact_phone);
        formData.append("sub_text", values.sub_text);
        formData.append("headline", values.headline);
        formData.append("cta", values.cta);
        formData.append("currenncy", values.currency);
        formData.append("rate", values.rate);
        formData.append("currency_symbol", values.currency_symbol);
        formData.append("price_per_tree", values.price_per_tree);
        formData.append("district", values.district);
        formData.append("sector", values.sector);
        formData.append("village", values.village);
        formData.append("cell", values.cell);

        try {
            const result = await createOrganization(formData).unwrap();
            if (result?.status) {
                actions.setSubmitting(false);
                handleClick();
                actions.resetForm();
            }
            setTimeout(() => {
                navigate(`/organization/list`);
            }, 2000);
        } catch (err) {
            actions.setSubmitting(false);
            toggleShowError(true);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    New organisation Added Successfully!
                </Alert>
            </Snackbar>
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={submitForm}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form
                                    encType='multipart/form-data'
                                    id={formId}
                                    autoComplete='off'
                                >
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <OrganizationInfo
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                    setLogo={setLogo}
                                                    location={location}
                                                    setLocation={setLocation}
                                                />

                                                <SoftBox
                                                    mt={2}
                                                    ml={3}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            ` Send`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {showError &&
                                                    !isLoading &&
                                                    isError && (
                                                        <ErrorMessageResponse>
                                                            {
                                                                error?.data
                                                                    ?.message
                                                            }
                                                        </ErrorMessageResponse>
                                                    )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default CreateNew;
