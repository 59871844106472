/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

export const supportApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllSupports: builder.query({
            query: (status) => {
                let url = "supports";
                if (status) {
                    url = `supports/status/${status}`;
                }
                return url;
            },
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["supports"],
        }),
        getSupportById: builder.query({
            query: (id) => `supports/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["supports"],
        }),
        getSupportsByStatus: builder.query({
            query: (status) => `supports/status/${status}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getSupportStatistics: builder.query({
            query: () => "supports/statistics",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getSupportTasks: builder.query({
            query: (id) => `supports/${id}/tasks`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getSupportWithoutResponse: builder.query({
            query: () => "supports/delayed",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
    }),
});

export const {
    useGetAllSupportsQuery,
    useGetSupportByIdQuery,
    useGetSupportStatisticsQuery,
    useGetSupportTasksQuery,
    useGetSupportWithoutResponseQuery,
    useGetSupportsByStatusQuery,
} = supportApi;

const supportSlice = createSlice({
    name: "support",
    initialState: {
        support: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            supportApi.endpoints.getAllSupports.matchFulfilled,
            (state, action) => {
                state.support = action.payload;
            }
        );
        builder.addMatcher(
            supportApi.endpoints.getSupportById.matchFulfilled,
            (state, action) => {
                state.support = action.payload;
            }
        );
        builder.addMatcher(
            supportApi.endpoints.getSupportStatistics.matchFulfilled,
            (state, action) => {
                state.support = action.payload;
            }
        );
        builder.addMatcher(
            supportApi.endpoints.getSupportTasks.matchFulfilled,
            (state, action) => {
                state.support = action.payload;
            }
        );
    },
});

export const supportReducer = supportSlice.reducer;

export default supportSlice;
