const form = {
  formId: 'new-country-form',
  formField: {
    name: {
      name: 'name',
      label: 'Country name',
      type: 'text',
      placeholder: 'e.g. Rwanda',
      errorMsg: 'Country name is required.',
    },
    political_name: {
      name: 'political_name',
      label: 'Political name',
      type: 'text',
      placeholder: 'e.g. Republic Of Rwanda',
      errorMsg: 'Political name is required.',
    },
    code: {
      name: 'code',
      label: 'Country code',
      type: 'string',
      placeholder: 'eg. RW',
      errorMsg: 'Country code is required.',
    },
    phone_code: {
      name: 'phone_code',
      label: 'Phone code',
      type: 'number',
      placeholder: 'eg. 250',
      errorMsg: 'Phone code is required.',
    },

    h_to_cover: {
      name: 'h_to_cover',
      label: 'Hectars to cover',
      type: 'number',
      placeholder: 'eg: 10,000',
      errorMsg: 'Hectars to cover is required.',
    },

    money_code: {
      name: 'money_code',
      label: 'Currency',
      type: 'text',
      placeholder: 'e.g. FRW',
      errorMsg: 'Currency is required.',
    },
  },
};

export default form;
