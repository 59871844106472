/** @format */

import React from "react";
import LoanStatusTable from "../LoanStatusTable";

const UnderReviewLoans = () => {
    return (
        <LoanStatusTable status={"Under_Review"} title={"Under review loans"} />
    );
};

export default UnderReviewLoans;
