/** @format */

import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import OrgCard from "eco/Cards/OrgCard";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useGetAllForestsQuery } from "features/slices/forest";
import React from "react";
import ForestMap from "./forestMap";
import breakpoints from "assets/theme/base/breakpoints";
import SoftTypography from "components/SoftTypography";
import columnDataForest from "./data/columnDataForest";
import DataTable from "eco/Tables/DataTable";

const Forest = () => {
    const { data: forests, isLoading, isFetching } = useGetAllForestsQuery();
    const { values } = breakpoints;

    const center = {
        lat: -1.942155,
        long: 29.2209719,
    };
    const totalPlantingTrees = forests?.reduce(
        (accumulator, currentForest) =>
            accumulator + parseInt(currentForest?.planting_tree || 0),
        0
    );

    const totalDeadTrees = forests?.reduce(
        (accumulator, currentForest) =>
            accumulator + parseInt(currentForest?.dead_tree || 0),
        0
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <div style={{ marginTop: "1.3rem" }}>
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Forest Statistics
                    </SoftTypography>
                </div>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Planted trees'
                                        count={totalPlantingTrees}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Dead trees'
                                        count={totalDeadTrees}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Replaced trees'
                                        count={totalDeadTrees}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OrgCard
                                        title='Survived trees'
                                        count={
                                            totalPlantingTrees - totalDeadTrees
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <div style={{ marginTop: "1.3rem" }}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Forest List
                </SoftTypography>
            </div>
            <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                <Card>
                    <DataTable
                        table={columnDataForest}
                        entriesPerPage={5}
                        canSearch
                        theData={forests}
                    />
                </Card>
            </LoadingCardSkeleton>
            <SoftBox my={3}>
                <LoadingCardSkeleton
                    isLoading={isLoading || isFetching}
                    height='333px'
                >
                    <Card style={{ padding: 10 }}>
                        <ForestMap center={center} data={forests} />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default Forest;
