/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import styled from "styled-components";
import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { ADMIN_BASE_URL } from "constants";

const organizationColumns = {
    columns: [
        {
            Header: "Logo",
            accessor: "logo",
            Cell: ({ value }) => {
                return (
                    <Logo
                        src={`
                        ${ADMIN_BASE_URL}${value}`}
                    />
                );
            },
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Contact person",
            accessor: "person_contact",
            Cell: ({ value }) => (
                <DefaultCell value={value ?? "Contact person"} />
            ),
        },
        {
            Header: "Contact phone",
            accessor: "contact_phone",
            Cell: ({ value }) => <DefaultCell value={value ?? "Phone"} />,
        },
        {
            Header: "Physical Address",
            accessor: "location_name",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value, row }) => (
                <div style={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow={false} title='view details'>
                        <NavLink
                            style={{ fontSize: "25px" }}
                            to={`/organization/details/${value}`}
                        >
                            <RemoveRedEyeIcon fontSize='inherit' />
                        </NavLink>
                    </Tooltip>
                    <Tooltip arrow={false} title='edit organization'>
                        <NavLink
                            style={{ fontSize: "25px" }}
                            to={`/organization/edit/${value}`}
                        >
                            <CreateIcon fontSize='inherit' />
                        </NavLink>
                    </Tooltip>
                </div>
            ),
        },
    ],
};

export const NavLink = styled(Link)`
    text-decoration: none;
    color: black;
    &:hover {
        text-decoration: underline !important;
    }
`;

export const Logo = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50px;
`;

export default organizationColumns;
