/** @format */

import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

import { useGetCellsBySectorIdQuery } from "features/slices/cellSlice";
import { useGetDistrictsByProvinceIdQuery } from "features/slices/districtSlice";
import { useGetAllProvinceByCountryIdQuery } from "features/slices/provinceSlice";
import { useGetSectorsByDistrictIdQuery } from "features/slices/sectorSlice";
import { useGetVillagesByCellIdQuery } from "features/slices/villageSlice";
import React, { useState } from "react";
import FormField from "components/FormField";
import { useGetProjectByBeneficiaryQuery } from "features/slices/projectSlice";

const ProjectForestForm = ({
    formData,
    setLocation,
    location,
    country_id,
    id,
}) => {
    const { formField, values, errors, touched, setFieldValue } = formData;
    const { data: projectsBen } = useGetProjectByBeneficiaryQuery(id);
    const farmers = projectsBen && projectsBen?.data;
    const [provinceId, setProvinceId] = useState({
        id: null,
        name: null,
    });
    const [districtId, setDistrictId] = useState({
        id: null,
        name: null,
    });
    const [sectocId, setSectorId] = useState({
        id: null,
        name: null,
    });
    const [cellId, setCellId] = useState({
        id: null,
        name: null,
    });

    // Queries for fetching location data

    const { data: provinces } = useGetAllProvinceByCountryIdQuery();
    const { data: districts, isLoading: loadingDistricts } =
        useGetDistrictsByProvinceIdQuery(provinceId.id);
    const { data: sectors } = useGetSectorsByDistrictIdQuery(districtId.id);
    const { data: cells } = useGetCellsBySectorIdQuery(sectocId.id);
    const { data: villages } = useGetVillagesByCellIdQuery(cellId.id);
    return (
        <>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>
                    Create Forest to Project
                </SoftTypography>
            </SoftBox>
            <SoftBox component='form' pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.name.type}
                            label={formField.name.label}
                            name={formField.name.name}
                            value={values.name}
                            placeholder={formField.name.placeholder}
                            error={errors.name && touched.name}
                            success={values?.name?.length > 0 && !errors?.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.forest_size.type}
                            label={formField.forest_size.label}
                            name={formField.forest_size.name}
                            value={values.forest_size}
                            placeholder={formField.forest_size.placeholder}
                            error={errors.forest_size && touched.forest_size}
                            success={
                                values?.forest_size?.length > 0 &&
                                !errors?.forest_size
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                beneficiary
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.beneficiary} // Set default to the first option's ID
                            error={errors.beneficiary && touched.beneficiary}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.beneficiary.name,
                                    event.target.value
                                );
                            }}
                        >
                            {farmers?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption?.farmer?.id}
                                    value={roleOption?.farmer?.id}
                                >
                                    {roleOption?.farmer?.full_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Province
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.province?.province} // Set default to the first option's ID
                            error={errors.province && touched.province}
                            disabled={
                                !country_id ||
                                (provinces && provinces.data?.length <= 0)
                            }
                            onChange={(event) => {
                                setProvinceId({
                                    id: event.target.value,
                                    name: provinces?.data?.find(
                                        (item) => item.id === event.target.value
                                    ).province,
                                });
                                setFieldValue(
                                    formField.province.name,
                                    event.target.value
                                );
                                setLocation([
                                    ...location,
                                    provinces?.data?.find(
                                        (item) => item.id === event.target.value
                                    ).province,
                                ]);
                            }}
                        >
                            {provinces?.data?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption?.id}
                                    value={roleOption?.id}
                                >
                                    {roleOption?.province}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                District
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.district.name} // Set default to the first option's ID
                            error={errors.district && touched.district}
                            disabled={
                                !districts ||
                                (districts && districts?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.district?.name,
                                    event.target.value
                                );
                                setDistrictId({
                                    id: event.target.value,
                                    name: districts?.find(
                                        (item) => item.id === event.target.value
                                    ).district,
                                });
                                setLocation([
                                    ...location,
                                    districts?.find(
                                        (item) => item.id === event.target.value
                                    ).district,
                                ]);
                            }}
                        >
                            {!loadingDistricts &&
                                districts?.map((district) => (
                                    <MenuItem
                                        key={district.id}
                                        value={district.id}
                                    >
                                        {district.district}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Sector
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.sector} // Set default to the first option's ID
                            error={errors.sector && touched.sector}
                            disabled={
                                !sectors || (sectors && sectors?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.sector.name,
                                    event.target.value
                                );
                                setSectorId({
                                    id: event.target.value,
                                    name: sectors?.find(
                                        (item) => item.id === event.target.value
                                    ).sector,
                                });
                                setLocation([
                                    ...location,
                                    sectors?.find(
                                        (item) => item.id === event.target.value
                                    ).sector,
                                ]);
                            }}
                        >
                            {sectors?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.sector}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Cell
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.cell} // Set default to the first option's ID
                            error={errors.cell && touched.cell}
                            disabled={!cells || (cells && cells?.length <= 0)}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.cell?.name,
                                    event.target.value
                                );
                                setCellId({
                                    id: event.target.value,
                                    name: cells?.find(
                                        (item) => item.id === event.target.value
                                    ).cell,
                                });
                                setLocation([
                                    ...location,
                                    cells?.find(
                                        (item) => item.id === event.target.value
                                    ).cell,
                                ]);
                            }}
                        >
                            {cells?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.cell}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Village
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.village} // Set default to the first option's ID
                            error={errors.village && touched.village}
                            disabled={
                                !villages || (villages && villages?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.village?.name,
                                    event.target.value
                                );
                                setLocation([
                                    ...location,
                                    villages?.find(
                                        (item) => item.id === event.target.value
                                    ).village,
                                ]);
                            }}
                        >
                            {villages?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.village}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
};

export default ProjectForestForm;
