/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

// Farmer API endpoints
export const loanApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // getAllLoans: builder.query({
        //   query: () => '/loan/all',
        //   refetchOnMount: 'always',
        //   refetchOnReconnect: true,
        //   // refetchOnFocus: 'always',
        //   // pollingInterval: 15 * 60 * 1000, // Fetch data every 15 minutes
        //   // retry: 3,
        //   // retryOnNetworkFailure: true,
        //   // maxRetries: 3,
        //   transformResponse: (response, meta, arg) => response.data,
        // }),

        getLoans: builder.query({
            query: (status) => {
                let url = "loans";
                if (status) {
                    url = `loans/status/${status}`;
                }
                return url;
            },
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["loans"],
        }),
        getLoanStatistics: builder.query({
            query: () => "loans/statistics",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getLoanById: builder.query({
            query: (id) => `loans/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providesTags: ["loans"],
        }),
        getAllLoans: builder.mutation({
            query: (data) => {
                const formData = new FormData();

                formData.append("status", data?.name || "");
                formData.append("from", data?.from || "");
                formData.append("to", data?.to || "");
                formData.append("payment_status", data?.payment_status || "");
                formData.append("farmer_id", data?.farmer_id || "");
                formData.append("loan_type_id", data?.loan_type_id || "");
                formData.append("country_id", data?.country_id || "");

                return {
                    url: "loan/all",
                    method: "POST",
                    body: formData,
                };
            },
        }),
        getAllRequestedLoans: builder.mutation({
            query: (data) => {
                const formData = new FormData();

                formData.append("status", "Application_Submitted");
                formData.append("from", data?.from || "");
                formData.append("to", data?.to || "");
                formData.append("payment_status", data?.payment_status || "");
                formData.append("farmer_id", data?.farmer_id || "");
                formData.append("loan_type_id", data?.loan_type_id || "");
                formData.append("country_id", data?.country_id || "");

                return {
                    url: "loan/all",
                    method: "POST",
                    body: formData,
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
            },
        }),
        getAllOngoingLoans: builder.mutation({
            query: (data) => {
                const formData = new FormData();

                formData.append("status", "Approved");
                formData.append("from", data?.from || "");
                formData.append("to", data?.to || "");
                formData.append("payment_status", data?.payment_status || "");
                formData.append("farmer_id", data?.farmer_id || "");
                formData.append("loan_type_id", data?.loan_type_id || "");
                formData.append("country_id", data?.country_id || "");

                return {
                    url: "loan/all",
                    method: "POST",
                    body: formData,
                };
            },
        }),
        getAllCompletedLoans: builder.mutation({
            query: (data) => {
                const formData = new FormData();

                formData.append("status", "Completed");
                formData.append("from", data?.from || "");
                formData.append("to", data?.to || "");
                formData.append("payment_status", data?.payment_status || "");
                formData.append("farmer_id", data?.farmer_id || "");
                formData.append("loan_type_id", data?.loan_type_id || "");
                formData.append("country_id", data?.country_id || "");

                return {
                    url: "loan/all",
                    method: "POST",
                    body: formData,
                };
            },
        }),
        getLoanTypes: builder.query({
            query: () => "loans/main-dashboard/status-count",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getLoanCountStatus: builder.query({
            query: () => "loans/main-dashboard/status-count",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getLoanMainDash: builder.query({
            query: () => "loans/main-dashboard",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
    }),
});

export const {
    useGetAllLoansMutation,
    useGetLoanTypesQuery,
    useGetLoanMainDashQuery,
    useGetLoanCountStatusQuery,
    useGetAllRequestedLoansMutation,
    useGetAllOngoingLoansMutation,
    useGetAllCompletedLoansMutation,
    useGetLoansQuery,
    useGetLoanByIdQuery,
    useGetLoanStatisticsQuery,
} = loanApi;

// Loan slice
const loanSlice = createSlice({
    name: "loan",
    initialState: {
        loans: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            loanApi.endpoints.getAllLoans.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
    },
});

export const farmerReducer = loanSlice.reducer;

export default loanSlice;
