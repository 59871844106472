/** @format */
import * as Yup from "yup";
import form from "./form";

const {
    formField: { beneficiary },
} = form;

const validations = [
    Yup.object().shape({
        [beneficiary.name]: Yup.string().required("This field is required"),
    }),
];

export default validations;
