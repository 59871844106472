/** @format */
import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

export const cellApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCellsBySectorId: builder.query({
            query: (sectorId) => `cells/by-sector/${sectorId}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providersTags: ["cell"],
        }),
    }),
});

export const { useGetCellsBySectorIdQuery } = cellApi;

const cellTypeSlice = createSlice({
    name: "cell",
    initialState: {
        cells: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            cellApi.endpoints.getCellsBySectorId.matchFulfilled,
            (state, action) => {
                state.cells = action.payload;
            }
        );
    },
});

export const cellTypeReducer = cellTypeSlice.reducer;

export default cellTypeSlice;
