/** @format */

// @mui core components
import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

// Settings page components
import FormField from "components/FormField";
import { useGetLocationListQuery } from "features/slices/countrySlice";

function AddAgentInfo({ formData, CountriesList }) {
    const { formField, values, errors, touched, setFieldValue } = formData;

    const [filtersProvince, setFilterProvincee] = useState({
        level: "provinces",
        parentId: null,
    });
    const [filtersDistrict, setFilterDistrict] = useState({
        level: "districts",
        parentId: null,
    });
    const [filtersSectors, setFilterSecctorse] = useState({
        level: "sectors",
        parentId: null,
    });
    const [filtersCells, setFilterCells] = useState({
        level: "cells",
        parentId: null,
    });
    const [filtersVillage, setFilterVillage] = useState({
        level: "vilages",
        parentId: null,
    });

    // Queries for fetching location data

    const { data: provinces } = useGetLocationListQuery(filtersProvince, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });

    const { data: districts } = useGetLocationListQuery(filtersDistrict, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });

    const { data: sectors } = useGetLocationListQuery(filtersSectors, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });
    const { data: cells } = useGetLocationListQuery(filtersCells, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });
    const { data: villages } = useGetLocationListQuery(filtersVillage, {
        refetchOnMountOrArgChange: true,
        skip: false,
    });
    return (
        <>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>Agent Info</SoftTypography>
            </SoftBox>
            <SoftBox component='form' pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.first_name.type}
                            label={formField.first_name.label}
                            name={formField.first_name.name}
                            value={values.first_name}
                            placeholder={formField.first_name.placeholder}
                            error={errors.first_name && touched.first_name}
                            success={
                                values?.first_name?.length > 0 &&
                                !errors?.first_name
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.last_name.type}
                            label={formField.last_name.label}
                            name={formField.last_name.name}
                            value={values.last_name}
                            placeholder={formField.last_name.placeholder}
                            error={errors.last_name && touched.last_name}
                            success={
                                values?.last_name?.length > 0 &&
                                !errors?.last_name
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.dob.type}
                            label={formField.dob.label}
                            name={formField.dob.name}
                            value={values.dob}
                            placeholder={formField.dob.placeholder}
                            error={errors.dob && touched.dob}
                            success={values?.dob?.length > 0 && !errors?.dob}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.whatsapp_phone.type}
                            label={formField.whatsapp_phone.label}
                            name={formField.whatsapp_phone.name}
                            value={values.whatsapp_phone}
                            placeholder={formField.whatsapp_phone.placeholder}
                            error={
                                errors.whatsapp_phone && touched.whatsapp_phone
                            }
                            success={
                                values?.whatsapp_phone?.length > 0 &&
                                !errors?.whatsapp_phone
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.momo_phone.type}
                            label={formField.momo_phone.label}
                            name={formField.momo_phone.name}
                            value={values.momo_phone}
                            placeholder={formField.momo_phone.placeholder}
                            error={errors.momo_phone && touched.momo_phone}
                            success={
                                values?.momo_phone?.length > 0 &&
                                !errors?.momo_phone
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Education
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.education} // Set default to the first option's ID
                            error={errors.education && touched.education}
                            onChange={(event) =>
                                setFieldValue(
                                    formField.education.name,
                                    event.target.value
                                )
                            }
                        >
                            {formField.education.options.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.location_name.type}
                            label={formField.location_name.label}
                            name={formField.location_name.name}
                            value={values.location_name}
                            placeholder={formField.location_name.placeholder}
                            error={
                                errors.location_name && touched.location_name
                            }
                            success={
                                values?.location_name?.length > 0 &&
                                !errors?.location_name
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Agent level
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.level} // Set default to the first option's ID
                            error={errors.level && touched.level}
                            onChange={(event) =>
                                setFieldValue(
                                    formField.level.name,
                                    event.target.value
                                )
                            }
                        >
                            {formField.level.options.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Country
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.country_id}
                            error={errors.country_id && touched.country_id}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.country_id.name,
                                    event.target.value
                                );
                                setFilterProvincee((prevState) => ({
                                    ...prevState,
                                    parentId: event.target.value,
                                }));
                                setFieldValue(formField.province.name, "");
                            }}
                        >
                            {CountriesList &&
                                CountriesList?.length > 1 &&
                                CountriesList?.map((roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.id}
                                    >
                                        {roleOption.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Province
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.province} // Set default to the first option's ID
                            error={errors.province && touched.province}
                            disabled={
                                !provinces ||
                                (provinces && provinces?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.province.name,
                                    event.target.value
                                );
                                setFilterDistrict((prevState) => ({
                                    ...prevState,
                                    parentId: event.target.value,
                                }));
                                setFieldValue(formField.district.name, "");
                                setFieldValue(formField.sector.name, "");
                                setFieldValue(formField.cell.name, "");
                                setFieldValue(formField.village.name, "");
                            }}
                        >
                            {provinces &&
                                provinces?.length > 1 &&
                                provinces?.map((roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.id}
                                    >
                                        {roleOption.province}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                District
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.district} // Set default to the first option's ID
                            error={errors.district && touched.district}
                            disabled={
                                !districts ||
                                (districts && districts?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.district.name,
                                    event.target.value
                                );
                                setFilterSecctorse((prevState) => ({
                                    ...prevState,
                                    parentId: event.target.value,
                                }));
                                setFieldValue(formField.sector.name, "");
                                setFieldValue(formField.cell.name, "");
                                setFieldValue(formField.village.name, "");
                            }}
                        >
                            {districts &&
                                districts?.length > 1 &&
                                districts?.map((roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.id}
                                    >
                                        {roleOption?.district}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Sector
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.sector} // Set default to the first option's ID
                            error={errors.sector && touched.sector}
                            disabled={
                                !sectors || (sectors && sectors?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.sector.name,
                                    event.target.value
                                );
                                setFilterCells((prevState) => ({
                                    ...prevState,
                                    parentId: event.target.value,
                                }));
                                setFieldValue(formField.cell.name, "");
                                setFieldValue(formField.village.name, "");
                            }}
                        >
                            {sectors &&
                                sectors?.length > 1 &&
                                sectors?.map((roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.id}
                                    >
                                        {roleOption?.sector}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Cell
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.cell} // Set default to the first option's ID
                            error={errors.cell && touched.cell}
                            disabled={!cells || (cells && cells?.length <= 0)}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.cell.name,
                                    event.target.value
                                );
                                setFilterVillage((prevState) => ({
                                    ...prevState,
                                    parentId: event.target.value,
                                }));
                                setFieldValue(formField.village.name, "");
                            }}
                        >
                            {cells &&
                                cells?.length > 1 &&
                                cells?.map((roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.id}
                                    >
                                        {roleOption?.cell}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Village
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.village} // Set default to the first option's ID
                            error={errors.village && touched.village}
                            disabled={
                                !villages || (villages && villages?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.village.name,
                                    event.target.value
                                );
                            }}
                        >
                            {villages &&
                                villages?.length > 1 &&
                                villages?.map((roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.id}
                                    >
                                        {roleOption?.village}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
}

export default AddAgentInfo;
