/**
 * eslint-disable react/prop-types
 *
 * @format
 */

import IdCell from "components/dataTableComponents/IdCell";
import DefaultCell from "components/dataTableComponents/DefaultCell";
import { NavLink } from "layouts/pages/organization/data/organizationColumns";
import { Logo } from "layouts/pages/organization/data/organizationColumns";
import { ADMIN_BASE_URL } from "constants";
// import CustomerCell from 'components/dataTableComponents/CustomerCell';

const dataTableData = {
    columns: [
        {
            Header: "id",
            accessor: "agent_code",
            Cell: ({ value, row }) => (
                <IdCell
                    handleClick={true}
                    url='/agent/agent-details'
                    style={{ cursor: "pointer" }}
                    routeId={`${row?.id}`}
                    id={value}
                />
            ),
        },
        {
            Header: "Avatar",
            accessor: "photo",
            Cell: ({ value, row }) => (
                <Logo
                    src={`
                        ${ADMIN_BASE_URL}${value}`}
                />
            ),
        },
        {
            Header: "First Name",
            accessor: "first_name",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Last Name",
            accessor: "last_name",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Gender",
            accessor: "gender",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Education",
            accessor: "education",
            Cell: ({ value }) => <DefaultCell value={value ?? "Address"} />,
        },
        {
            Header: "Phone",
            accessor: "momo_phone",
            Cell: ({ value }) => (
                <DefaultCell value={"+" + value ?? "Phone number"} />
            ),
        },
        {
            Header: "Joined Date",
            accessor: "joined_date",
            Cell: ({ value }) => <DefaultCell value={value ?? "Joined Data"} />,
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }) => <DefaultCell value={value ?? "Status"} />,
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value, row }) => (
                <NavLink to={`/agents/details/${value}`}>More detail</NavLink>
            ),
        },
    ],
};

export default dataTableData;
