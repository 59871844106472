/**
 * eslint-disable react/prop-types
 *
 * @format
 */
import DefaultCell from "components/dataTableComponents/DefaultCell";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const columnsStatus = {
    columns: [
        {
            Header: "Farmer",
            accessor: "Farmer",
            Cell: ({ value }) => <DefaultCell value={value?.full_name} />,
        },
        {
            Header: "Loan amount",
            accessor: "amount_to_pay",
            Cell: ({ value }) => <DefaultCell value={value || "0"} />,
        },
        {
            Header: "Paid amount",
            accessor: "paid_amount",
            Cell: ({ value }) => <DefaultCell value={value || "0"} />,
        },
        {
            Header: "Loan rate",
            accessor: "loan_rate",
            Cell: ({ value }) => <DefaultCell value={value || "0"} />,
        },
        {
            Header: "Month to paid",
            accessor: "months_to_pay",
            Cell: ({ value }) => <DefaultCell value={value || "0"} />,
        },

        {
            Header: "Payment start date",
            accessor: "payment_start_date",
            Cell: ({ value }) => {
                const date = new Date(value);
                const formatDate = format(date, "dd-MM-yyyy");
                return <DefaultCell value={formatDate} />;
            },
        },
        {
            Header: "Payment end date",
            accessor: "payment_end_date",
            Cell: ({ value }) => {
                const date = new Date(value);
                const formatDate = format(date, "dd-MM-yyyy");
                return <DefaultCell value={formatDate} />;
            },
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value }) => (
                <Link to={`/loans/details/${value}`}>more details</Link>
            ),
        },
    ],
};

export default columnsStatus;
