/** @format */

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React from "react";
import columnStatus from "../data/columnStatus";
import { useGetSupportsByStatusQuery } from "features/slices/supportSlice";
import SoftBox from "components/SoftBox";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import { Card } from "@mui/material";
import DataTable from "eco/Tables/DataTable";
import SoftTypography from "components/SoftTypography";

const SupportStatus = ({ status, title }) => {
    const {
        data: supports,
        isLoading,
        isFetching,
    } = useGetSupportsByStatusQuery(status);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox my={3}>
                <SoftTypography
                    variant={"h6"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    {title}
                </SoftTypography>
                <LoadingCardSkeleton
                    isLoading={isLoading || isFetching}
                    height='333px'
                >
                    <Card>
                        <DataTable
                            table={columnStatus}
                            entriesPerPage={false}
                            canSearch
                            theData={supports?.data}
                        />
                    </Card>
                </LoadingCardSkeleton>
            </SoftBox>
        </DashboardLayout>
    );
};

export default SupportStatus;
