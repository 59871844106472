import form from "./form";

const {
  formField: {
    first_name,
    last_name,
    whatsapp_phone,
    momo_phone,
    education,
    // location_name,
    level,
    dob,
    province,
    district,
    cell,
    sector,
    village,
    country_id,
  },
} = form;

const initialValues = {
  [first_name.name]: "",
  [last_name.name]: "",
  [whatsapp_phone.name]: "",
  [momo_phone.name]: "",
  [education.name]: "",
  [country_id.name]: "",
  [level.name]: "",
  [dob.name]: "",
  [province.name]: "",
  [district.name]: "",
  [sector.name]: "",
  [cell.name]: "",
  [village.name]: "",
};

export default initialValues;
