/** @format */
import DefaultCell from "components/dataTableComponents/DefaultCell";
import { countries } from "constants/data";
import { Link } from "react-router-dom";

const columnStatus = {
    columns: [
        {
            Header: "Support",
            accessor: "support",
            Cell: ({ value }) => <DefaultCell value={value?.name ?? "N/A"} />,
        },
        {
            Header: "Farmer",
            accessor: "farmer",
            Cell: ({ value }) => (
                <DefaultCell value={value?.full_name ?? "N/A"} />
            ),
        },
        {
            Header: "Comment",
            accessor: "comment",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Country",
            accessor: "country_id",
            Cell: ({ value }) => {
                const country = countries.find((c) => c.id === value);
                return <DefaultCell value={country.name ?? "N/A"} />;
            },
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value }) => (
                <Link to={`/support/${value}/details`}>More details</Link>
            ),
        },
    ],
};

export default columnStatus;
