/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

// Farmer API endpoints
export const forestApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllForests: builder.query({
            query: () => "forests",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providersTags: ["forest"],
        }),
        getforestById: builder.query({
            query: (id) => `forests/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getforestByFarmerId: builder.query({
            query: (id) => `forest/farmer/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getTreesByForestId: builder.query({
            query: (id) => `trees/forest/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getForestStatById: builder.query({
            query: (id) => `forests/${id}/statistics`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
    }),
});

export const {
    useGetAllForestsQuery,
    useGetforestByFarmerIdQuery,
    useGetTreesByForestIdQuery,
    useGetforestByIdQuery,
    useGetForestStatByIdQuery,
} = forestApi;

// Agent slice
const forestSlice = createSlice({
    name: "forest",
    initialState: {
        forest: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            forestApi.endpoints.getAllForests.matchFulfilled,
            (state, action) => {
                state.forest = action.payload;
            }
        );
        builder.addMatcher(
            forestApi.endpoints.getTreesByForestId.matchFulfilled,
            (state, action) => {
                state.forest = action.payload;
            }
        );
        builder.addMatcher(
            forestApi.endpoints.getForestStatById.matchFulfilled,
            (state, action) => {
                state.forest = action.payload;
            }
        );
    },
});

export const forestReducer = forestSlice.reducer;

export default forestSlice;
