/** @format */

import { Card, Divider, Grid } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";
import { fakeTaskData } from "constants/data";
import DataTable from "eco/Tables/DataTable";
import taskDetailColumns from "./data/taskDetailColumn";
import { taskHistories } from "constants/data";

const TaskDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [task, setTask] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        const findOne = fakeTaskData.find((value) => value.id === id);
        setTask(findOne);
        setIsLoading(false);
    }, [isLoading]);
    const toDate = new Date("2023-04-30T20:30:29.710Z");
    const formatDate = format(toDate, "dd-MM-yyyy");
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox>
                <Card
                    style={{
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                    }}
                >
                    <SoftTypography variant='h4' fontWeight='bold'>
                        Tasks #{id} details
                    </SoftTypography>
                    <Container>
                        <InfoContainer>
                            <SoftBox pt={1} pb={3} px={2}>
                                <SoftBox mb={3}>
                                    <Grid
                                        container
                                        spacing={3}
                                        alignItems='center'
                                    >
                                        <Grid item xs={12} md={6}>
                                            <SoftBox
                                                display='flex'
                                                alignItems='center'
                                            >
                                                <SoftBox lineHeight={1}>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Payment status:
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            {task.payment_status ? (
                                                                <SoftBadge
                                                                    variant='gradient'
                                                                    color='success'
                                                                    size='xs'
                                                                    badgeContent='completed'
                                                                    container
                                                                />
                                                            ) : (
                                                                <SoftBadge
                                                                    variant='gradient'
                                                                    color='error'
                                                                    size='xs'
                                                                    badgeContent='pending'
                                                                    container
                                                                />
                                                            )}
                                                        </SoftBox>
                                                    </SoftBox>

                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftTypography
                                                            variant='body2'
                                                            fontWeight='medium'
                                                        >
                                                            Farmer:
                                                        </SoftTypography>
                                                        <SoftBox ml={2}>
                                                            <NavLink to={"#"}>
                                                                {task.farmer}
                                                            </NavLink>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftBox>
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Forest Name:
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {
                                                                    task.forest_name
                                                                }
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                    <SoftBox
                                                        display='flex'
                                                        alignItems='center'
                                                        mb={0.5}
                                                    >
                                                        <SoftBox>
                                                            <SoftTypography
                                                                variant='body2'
                                                                fontWeight='medium'
                                                            >
                                                                Forest Location:
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox ml={2}>
                                                            <SoftTypography
                                                                variant='button'
                                                                fontWeight='regular'
                                                                color='text'
                                                            >
                                                                {
                                                                    task.forest_location
                                                                }
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{ textAlign: "right" }}
                                        >
                                            <SoftButton
                                                variant='gradient'
                                                color='info'
                                                handleClick={() =>
                                                    navigate("/tasks/add-new")
                                                }
                                            >
                                                New task
                                            </SoftButton>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                                <Divider />
                                <SoftBox mt={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <SoftTypography
                                                variant='h6'
                                                fontWeight='medium'
                                                mb={2}
                                            >
                                                Task Todo
                                            </SoftTypography>
                                            <SoftBox lineHeight={1}>
                                                <SoftBox
                                                    display='flex'
                                                    alignItems='center'
                                                    mb={0.5}
                                                >
                                                    <SoftTypography
                                                        variant='body2'
                                                        fontWeight='medium'
                                                    >
                                                        Task status:
                                                    </SoftTypography>
                                                    <SoftBox ml={2}>
                                                        {task.task_status ? (
                                                            <SoftBadge
                                                                variant='gradient'
                                                                color='success'
                                                                size='xs'
                                                                badgeContent='completed'
                                                                container
                                                            />
                                                        ) : (
                                                            <SoftBadge
                                                                variant='gradient'
                                                                color='error'
                                                                size='xs'
                                                                badgeContent='pending'
                                                                container
                                                            />
                                                        )}
                                                    </SoftBox>
                                                </SoftBox>
                                                <SoftBox
                                                    display='flex'
                                                    alignItems='center'
                                                    mb={0.5}
                                                >
                                                    <SoftTypography
                                                        variant='body2'
                                                        fontWeight='medium'
                                                    >
                                                        Task date:
                                                    </SoftTypography>
                                                    <SoftBox ml={2}>
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='regular'
                                                            color='text'
                                                        >
                                                            {formatDate}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </SoftBox>
                                                <SoftBox
                                                    display='flex'
                                                    alignItems='start'
                                                    flexDirection='column'
                                                    mb={0.5}
                                                >
                                                    <SoftTypography
                                                        variant='body2'
                                                        fontWeight='medium'
                                                    >
                                                        Message:
                                                    </SoftTypography>
                                                    <SoftBox mt={1}>
                                                        <SoftTypography
                                                            variant='button'
                                                            fontWeight='regular'
                                                            color='text'
                                                        >
                                                            {task.message}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                </SoftBox>
                                            </SoftBox>
                                        </Grid>
                                        {/* Agent Dashboard */}
                                        <Grid item xs={12} md={6} lg={6}></Grid>
                                    </Grid>
                                </SoftBox>
                            </SoftBox>
                        </InfoContainer>
                    </Container>

                    <SoftBox>
                        <SoftTypography
                            component='label'
                            variant='text'
                            fontWeight='bold'
                        >
                            Task History
                        </SoftTypography>
                        <DataTable
                            table={taskDetailColumns}
                            entriesPerPage={true}
                            canSearch={true}
                            theData={taskHistories}
                        />
                    </SoftBox>
                </Card>
            </SoftBox>
        </DashboardLayout>
    );
};

const Container = styled.div`
    padding: 20px 40px;
    display: flex;
    gap: 1rem;
`;

const InfoContainer = styled.div`
    flex: 1;
`;

const NavLink = styled(Link)`
    font-size: 14px;
    text-decoration: underline;
    &:focus,
    &:visited,
    &:link,
    &:active {
        text-decoration: underline !important;
    }
`;

export default TaskDetail;
