/** @format */

const form = {
    formId: "new-forest-project-form",
    formField: {
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "e.g. forest name",
            errorMsg: "forest name is required.",
        },
        forest_size: {
            name: "forest_size",
            label: "Forest size",
            type: "number",
            placeholder: "e.g. 24",
            errorMsg: "Forest size is required.",
        },
        province: {
            name: "province",
            label: "Province",
            type: "select",
            options: [
                { id: "1", name: "Kigali" },
                { id: "2", name: "East" },
                { id: "3", name: "West" },
            ],
            errorMsg: "This field is required.",
            invalidMsg: "Level can't be more than 5 and less than 1",
        },
        district: {
            name: "district",
            label: "District",
            type: "select",
            options: [
                { id: "1", name: "District 1" },
                { id: "2", name: "District 2" },
                { id: "3", name: "District 3" },
            ],
            errorMsg: "This field is required.",
        },
        sector: {
            name: "sector",
            label: "Sector",
            type: "select",
            options: [
                { id: "1", name: "Sector 1" },
                { id: "2", name: "Sector 2" },
                { id: "1", name: "Sector 3" },
            ],
            errorMsg: "This field is required.",
        },
        cell: {
            name: "cell",
            label: "Cell",
            type: "select",
            options: [
                { id: "1", name: "Cell 1" },
                { id: "2", name: "Cell 2" },
                { id: "3", name: "Cell 3" },
            ],
            errorMsg: "This field is required.",
        },
        village: {
            name: "village",
            label: "Village",
            type: "select",
            options: [],
            errorMsg: "This field is required.",
        },

        beneficiary: {
            name: "farmer_id",
            label: "Farmer",
            type: "select",
            options: [],
            errorMsg: "farmer is required.",
        },
    },
};

export default form;
