/** @format */

import { Grid } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import SoftBox from "components/SoftBox";
import team from "assets/images/placeholder.png";
import SoftTypography from "components/SoftTypography";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import ProfileInfoCard from "eco/Cards/InfoCards/ProfileInfoCard";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useGetFarmerByIdQuery } from "features/slices/farmerSlice";
import { useGetforestByIdQuery } from "features/slices/forest";
import { useGetForestReleaseByIdQuery } from "features/slices/forestReleaseSlice";
import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const ForestReleaseDetail = () => {
    const { id } = useParams();
    const { data, isLoading } = useGetForestReleaseByIdQuery(id);
    const forestRelease = data && data?.data;

    const { data: detail, isLoading: loadingFarmer } = useGetFarmerByIdQuery(
        forestRelease?.farmer_id
    );

    const farmer = detail && detail?.data;

    const { values } = breakpoints;
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Forest Release Details
                </SoftTypography>
                <Grid item xs={12} lg={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <LoadingCardSkeleton
                                isLoading={isLoading}
                                height='330px'
                            >
                                <ProfileInfoCard
                                    title='forest details'
                                    description='Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).'
                                    info={{
                                        treeType: `${
                                            (forestRelease &&
                                                forestRelease?.tree_type) ||
                                            "N/A"
                                        }`,
                                        forestSize: `${
                                            (forestRelease &&
                                                forestRelease?.forest_size) ||
                                            "0"
                                        } Ha`,

                                        forestAge: `${
                                            (forestRelease &&
                                                forestRelease?.forest_age) ||
                                            "0"
                                        } years`,

                                        yearMonths: `${
                                            (forestRelease &&
                                                forestRelease?.ym) ||
                                            "none"
                                        }`,

                                        agent: `${
                                            (forestRelease &&
                                                forestRelease?.farmer?.agent) ||
                                            "none"
                                        }`,

                                        farmerLocation: `${
                                            (forestRelease &&
                                                forestRelease?.farmer
                                                    ?.location_name) ||
                                            "None"
                                        }`,
                                        requestStatus: `${
                                            (forestRelease &&
                                                forestRelease?.request_status) ||
                                            "None"
                                        }`,
                                        paymentStatus: `${
                                            (forestRelease &&
                                                forestRelease?.payment_status) ||
                                            "None"
                                        }`,
                                        farmerName: `${
                                            (forestRelease &&
                                                forestRelease?.farmer
                                                    ?.full_name) ||
                                            "N/A"
                                        }`,
                                        farmerPhone: `${
                                            (forestRelease &&
                                                forestRelease?.farmer?.phone) ||
                                            "N/A"
                                        }`,
                                    }}
                                    action={{
                                        route: "",
                                        tooltip: "Edit Forest",
                                    }}
                                />
                            </LoadingCardSkeleton>
                        </Grid>
                        {/* <Grid item xs={12} lg={4}>
                            <Card sx={{ height: "100%" }}>
                                <SoftBox
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    pt={2}
                                    px={2}
                                >
                                    <SoftTypography
                                        variant='h6'
                                        fontWeight='medium'
                                        textTransform='capitalize'
                                    >
                                        Farmer profile
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox p={2}>
                                    <SoftBox
                                        display='flex'
                                        justifyContent='start'
                                        py={1}
                                        pr={2}
                                    >
                                        <SoftBox mr={2}>
                                            <ImageContainer>
                                                <ImageProfile
                                                    src={farmer?.photo || team}
                                                />
                                            </ImageContainer>
                                        </SoftBox>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Full Name: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;{farmer?.full_name}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Gender: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;
                                            {farmer?.gender || `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Education: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;
                                            {farmer?.education || `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            phone: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;
                                            {farmer?.phone || `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Location: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;
                                            {farmer?.location_name || `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                            </Card>
                        </Grid> */}
                        {/* <Grid item xs={12} lg={4}>
                            <Card sx={{ height: "100%" }}>
                                <SoftBox
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    pt={2}
                                    px={2}
                                >
                                    <SoftTypography
                                        variant='h6'
                                        fontWeight='medium'
                                        textTransform='capitalize'
                                    >
                                        Agent Profile
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox p={2}>
                                    <SoftBox
                                        display='flex'
                                        justifyContent='start'
                                        py={1}
                                        pr={2}
                                    >
                                        <SoftBox mr={2}>
                                            <ImageContainer>
                                                <ImageProfile
                                                    src={
                                                        agent?.photo ||
                                                        marie
                                                    }
                                                />
                                            </ImageContainer>
                                        </SoftBox>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Full Name: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;
                                            {agent?.full_name || `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Gender: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;
                                            {forest?.agent?.gender || `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Education: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;
                                            {forest?.agent?.education || `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            phone: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp; +
                                            {forest?.agent?.phone || `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Whatsapp Phone: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp; +
                                            {forest?.agent?.whatsapp_phone ||
                                                `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Momo Phone: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp; +
                                            {forest?.agent?.momo_phone ||
                                                `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox display='flex' py={1} pr={2}>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='bold'
                                            textTransform='capitalize'
                                        >
                                            Location: &nbsp;
                                        </SoftTypography>
                                        <SoftTypography
                                            variant='button'
                                            fontWeight='regular'
                                            color='text'
                                        >
                                            &nbsp;
                                            {forest?.agent?.location_name ||
                                                `none`}
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                            </Card>
                        </Grid> */}
                    </Grid>
                </Grid>
            </SoftBox>
        </DashboardLayout>
    );
};

const ImageContainer = styled.div`
    width: 200px;
    height: 200px;
`;
const ImageProfile = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export default ForestReleaseDetail;
