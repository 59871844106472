/** @format */

import React from "react";
import LoanStatusTable from "../LoanStatusTable";

const OnPauseLoan = () => {
    return <LoanStatusTable status={"OnPause"} title={"On Pause Loans"} />;
};

export default OnPauseLoan;
