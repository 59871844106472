/** @format */

import React from "react";
import LoanStatusTable from "../LoanStatusTable";

const ApprovedLoans = () => {
    return <LoanStatusTable status={"Approved"} title={"Approved loans"} />;
};

export default ApprovedLoans;
