/** @format */

const form = {
    formId: "new-project-form",
    formField: {
        project_name: {
            name: "project_name",
            label: "Project Name",
            type: "text",
            placeholder: "e.g. Production of plant",
            errorMsg: "Project name is required.",
        },
        organization_id: {
            name: "organization_id",
            label: "Organization",
            type: "select",
            options: [{ id: "1", name: "Root name" }],
            errorMsg: "Organization is required.",
        },
        sponsor: {
            name: "sponsor",
            label: "Sponsor",
            type: "select",
            options: [{ id: "1", name: "Root name" }],
            errorMsg: "Sponsor is required.",
        },
        country_id: {
            name: "country_id",
            label: "Country",
            type: "select",
            options: [
                { id: "1", name: "Rwanda" },
                { id: "2", name: "Burundi" },
                { id: "3", name: "Kenya" },
                { id: "4", name: "Tanzania" },
            ],
            errorMsg: "Country is required.",
        },
    },
};

export default form;
