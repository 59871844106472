// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';

function FormField({ label, ...rest }) {
  return (
    <SoftBox
      display='flex'
      flexDirection='column'
      justifyContent='flex-end'
      height='100%'>
      <SoftBox mb={1} ml={0.5} lineHeight={0} display='inline-block'>
        <SoftTypography
          component='label'
          variant='caption'
          fontWeight='bold'
          textTransform='capitalize'>
          {label}
        </SoftTypography>
      </SoftBox>
      <SoftInput {...rest} />
    </SoftBox>
  );
}

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: ' ',
};

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
};

export default FormField;
