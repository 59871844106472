/* eslint-disable react/prop-types */
import DefaultCell from 'components/dataTableComponents/DefaultCell';

const dataTableData = {
  columns: [
    {
      Header: 'No.',
      accessor: 'code',
      Cell: ({ value }) => (
        <img
          src={`https://flagsapi.com/${value}/shiny/32.png`}
          style={{ height: 25 }}
          alt=''
        />
      ),
    },
    {
      Header: 'Country',
      accessor: 'name',
      Cell: ({ value }) => <DefaultCell value={value || '-'} />,
    },
    {
      Header: 'Political name',
      accessor: 'political_name',
      Cell: ({ value }) => <DefaultCell value={value || '-'} />,
    },
    {
      Header: 'Phone code',
      accessor: 'phone_code',
      Cell: ({ value }) => <DefaultCell value={value || '-'} />,
    },
    {
      Header: 'Currency',
      accessor: 'money_code',
      Cell: ({ value }) => <DefaultCell value={value || '-'} />,
    },

    {
      Header: 'Hectares to cover',
      accessor: 'h_to_cover',
      Cell: ({ value }) => <DefaultCell value={value || '-'} />,
    },
  ],
};

export default dataTableData;
