// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftSelect from 'components/SoftSelect';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'layouts/pages/account/settings/components/BasicInfo/data/selectData';

function BasicInfo() {
  return (
    <Card id='basic-info' sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant='h5'>Basic Info</SoftTypography>
      </SoftBox>
      <SoftBox component='form' pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField label='Full name' placeholder='Muhoza Jeanne' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label='last name' placeholder='Thompson' />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SoftBox
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-end'
                  height='100%'>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display='inline-block'>
                    <SoftTypography
                      component='label'
                      variant='caption'
                      fontWeight='bold'
                      textTransform='capitalize'>
                      I&apos;m
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect placeholder='Male' options={selectData.gender} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <SoftBox
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      height='100%'>
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display='inline-block'>
                        <SoftTypography
                          component='label'
                          variant='caption'
                          fontWeight='bold'
                          textTransform='capitalize'>
                          birth date
                        </SoftTypography>
                      </SoftBox>
                      <SoftSelect
                        placeholder='February'
                        options={selectData.birthDate}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftBox
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      height='100%'>
                      <SoftSelect placeholder={1} options={selectData.days} />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SoftBox
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      height='100%'>
                      <SoftSelect
                        placeholder={2021}
                        options={selectData.years}
                      />
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label='email'
              placeholder='example@email.com'
              inputProps={{ type: 'email' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label='confirmation email'
              placeholder='example@email.com'
              inputProps={{ type: 'email' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label='your location' placeholder='Sydney, A' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label='phone number'
              placeholder='+40 735 631 620'
              inputProps={{ type: 'number' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              height='100%'>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display='inline-block'>
                <SoftTypography
                  component='label'
                  variant='caption'
                  fontWeight='bold'
                  textTransform='capitalize'>
                  Role
                </SoftTypography>
              </SoftBox>
              <SoftSelect placeholder='Super admin' options={selectData.role} />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              height='100%'>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display='inline-block'>
                <SoftTypography
                  component='label'
                  variant='caption'
                  fontWeight='bold'
                  textTransform='capitalize'>
                  Country
                </SoftTypography>
              </SoftBox>
              <SoftSelect placeholder='Rwanda' options={selectData.country} />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
