/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";
import headers from "features/headers";

// Farmer API endpoints
export const taskApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createTask: builder.mutation({
            query: (data) => {
                const formData = new FormData();

                formData.append("name", data?.name);
                formData.append("level", data?.task_type);

                return {
                    url: "support/create",
                    method: "POST",
                    body: formData,
                    headers,
                };
            },
            invalidatesTags: ["task"],
        }),
        getAllTasks: builder.query({
            query: () => "support/all",
            refetchOnMount: "always",
            refetchOnReconnect: true,
            refetchOnFocus: "always",
            // pollingInterval: 15 * 60 * 1000,
            retry: 3,
            retryOnNetworkFailure: true,
            maxRetries: 3,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ["task"],
        }),
    }),
});

export const { useGetAllTasksQuery, useCreateTaskMutation } = taskApi;

// Loan slice
const taskSlice = createSlice({
    name: "task",
    initialState: {
        tasks: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            taskApi.endpoints.getAllLoans.matchFulfilled,
            (state, action) => {
                return action.payload;
            }
        );
    },
});

export const taskReducer = taskSlice.reducer;

export default taskSlice;
