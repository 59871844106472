/** @format */

// @mui core components
import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

// Settings page components
import FormField from "components/FormField";
import { useGetAllProvinceByCountryIdQuery } from "features/slices/provinceSlice";
import { useGetDistrictsByProvinceIdQuery } from "features/slices/districtSlice";
import { useGetSectorsByDistrictIdQuery } from "features/slices/sectorSlice";
import { useGetCellsBySectorIdQuery } from "features/slices/cellSlice";
import { useGetVillagesByCellIdQuery } from "features/slices/villageSlice";
import styled from "styled-components";

function OrganizationInfo({ formData, setLogo, location, setLocation }) {
    const { formField, values, errors, touched, setFieldValue } = formData;

    const [provinceId, setProvinceId] = useState({
        id: null,
        name: null,
    });
    const [districtId, setDistrictId] = useState({
        id: null,
        name: null,
    });
    const [sectocId, setSectorId] = useState({
        id: null,
        name: null,
    });
    const [cellId, setCellId] = useState({
        id: null,
        name: null,
    });

    // Queries for fetching location data

    const { data: provinces } = useGetAllProvinceByCountryIdQuery();
    const { data: districts, isLoading: loadingDistricts } =
        useGetDistrictsByProvinceIdQuery(provinceId.id);
    const { data: sectors } = useGetSectorsByDistrictIdQuery(districtId.id);
    const { data: cells } = useGetCellsBySectorIdQuery(sectocId.id);
    const { data: villages } = useGetVillagesByCellIdQuery(cellId.id);
    return (
        <>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>Organization</SoftTypography>
            </SoftBox>
            <SoftBox component='form' pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.name.type}
                            label={formField.name.label}
                            name={formField.name.name}
                            value={values.name}
                            placeholder={formField.name.placeholder}
                            error={errors.name && touched.name}
                            success={values?.name?.length > 0 && !errors?.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftTypography
                            component='label'
                            variant='caption'
                            fontWeight='bold'
                        >
                            Logo
                        </SoftTypography>
                        <FileInput
                            type='file'
                            accept='image/*'
                            name={"logo"}
                            onChange={(e) => {
                                setLogo(e.target.files[0]);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.person_contact.type}
                            label={formField.person_contact.label}
                            name={formField.person_contact.name}
                            value={values.person_contact}
                            placeholder={formField.person_contact.placeholder}
                            error={
                                errors.person_contact && touched.person_contact
                            }
                            success={
                                values?.person_contact?.length > 0 &&
                                !errors?.person_contact
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.contact_phone.type}
                            label={formField.contact_phone.label}
                            name={formField.contact_phone.name}
                            value={values.contact_phone}
                            placeholder={formField.contact_phone.placeholder}
                            error={
                                errors.contact_phone && touched.contact_phone
                            }
                            success={
                                values?.contact_phone?.length > 0 &&
                                !errors?.contact_phone
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.contact_email.type}
                            label={formField.contact_email.label}
                            name={formField.contact_email.name}
                            value={values.contact_email}
                            placeholder={formField.contact_email.placeholder}
                            error={
                                errors.contact_email && touched.contact_email
                            }
                            success={
                                values?.contact_email?.length > 0 &&
                                !errors?.contact_email
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.website.type}
                            label={formField.website.label}
                            name={formField.website.name}
                            value={values.website}
                            placeholder={formField.website.placeholder}
                            error={errors.website && touched.website}
                            success={
                                values?.website?.length > 0 && !errors?.website
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.headline.type}
                            label={formField.headline.label}
                            name={formField.headline.name}
                            value={values.headline}
                            placeholder={formField.headline.placeholder}
                            error={errors.headline && touched.headline}
                            success={
                                values?.headline?.length > 0 &&
                                !errors?.headline
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.sub_text.type}
                            label={formField.sub_text.label}
                            name={formField.sub_text.name}
                            value={values.sub_text}
                            placeholder={formField.sub_text.placeholder}
                            error={errors.sub_text && touched.sub_text}
                            success={
                                values?.sub_text?.length > 0 &&
                                !errors?.sub_text
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.cta.type}
                            label={formField.cta.label}
                            name={formField.cta.name}
                            value={values.cta}
                            placeholder={formField.cta.placeholder}
                            error={errors.cta && touched.cta}
                            success={values?.cta?.length > 0 && !errors?.cta}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.rate.type}
                            label={formField.rate.label}
                            name={formField.rate.name}
                            value={values.rate}
                            placeholder={formField.rate.placeholder}
                            error={errors.rate && touched.rate}
                            success={values?.rate?.length > 0 && !errors?.rate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.price_per_tree.type}
                            label={formField.price_per_tree.label}
                            name={formField.price_per_tree.name}
                            value={values.price_per_tree}
                            placeholder={formField.price_per_tree.placeholder}
                            error={errors.sub_text && touched.price_per_tree}
                            success={
                                values?.price_per_tree > 0 &&
                                !errors?.price_per_tree
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Currency
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.currency}
                            error={errors.currency && touched.currency}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.currency.name,
                                    event.target.value
                                );
                                console.log(
                                    event.target.value,
                                    "option values"
                                );
                                setFieldValue(formField.currency.id, "");
                            }}
                        >
                            {formField.currency.options?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.name}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Currency Symbol
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.currency_symbol}
                            error={
                                errors.currency_symbol &&
                                touched.currency_symbol
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.currency_symbol.name,
                                    event.target.value
                                );
                                console.log(
                                    event.target.value,
                                    "option values"
                                );
                                setFieldValue(formField.currency_symbol.id, "");
                            }}
                        >
                            {formField.currency_symbol.options?.map(
                                (roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.name}
                                    >
                                        {roleOption.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Country
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.country_id}
                            error={errors.country_id && touched.country_id}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.country_id.name,
                                    event.target.value
                                );
                                setFieldValue(formField.country_id.id, "");
                                setLocation([
                                    ...location,
                                    formField.country_id.options.find(
                                        (item) => item.id === event.target.value
                                    ).name,
                                ]);
                            }}
                        >
                            {formField.country_id.options?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Province
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.province} // Set default to the first option's ID
                            error={errors.province && touched.province}
                            disabled={
                                !values.country_id ||
                                (provinces && provinces.data?.length <= 0)
                            }
                            onChange={(event) => {
                                setProvinceId({
                                    id: event.target.value,
                                    name: provinces?.data?.find(
                                        (item) => item.id === event.target.value
                                    ).province,
                                });
                                setFieldValue(
                                    formField.province.name,
                                    event.target.value
                                );
                                setFieldValue(formField.province.id, "");
                                setLocation([
                                    ...location,
                                    provinces?.data?.find(
                                        (item) => item.id === event.target.value
                                    ).province,
                                ]);
                            }}
                        >
                            {provinces?.data?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.province}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                District
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.district} // Set default to the first option's ID
                            error={errors.district && touched.district}
                            disabled={
                                !districts ||
                                (districts && districts?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.district.name,
                                    event.target.value
                                );
                                setDistrictId({
                                    id: event.target.value,
                                    name: districts?.find(
                                        (item) => item.id === event.target.value
                                    ).district,
                                });
                                setLocation([
                                    ...location,
                                    districts?.find(
                                        (item) => item.id === event.target.value
                                    ).district,
                                ]);
                            }}
                        >
                            {!loadingDistricts &&
                                districts?.map((district) => (
                                    <MenuItem
                                        key={district.id}
                                        value={district.id}
                                    >
                                        {district.district}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Sector
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.sector} // Set default to the first option's ID
                            error={errors.sector && touched.sector}
                            disabled={
                                !sectors || (sectors && sectors?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.sector.name,
                                    event.target.value
                                );
                                setSectorId({
                                    id: event.target.value,
                                    name: sectors?.find(
                                        (item) => item.id === event.target.value
                                    ).sector,
                                });
                                setLocation([
                                    ...location,
                                    sectors?.find(
                                        (item) => item.id === event.target.value
                                    ).sector,
                                ]);
                            }}
                        >
                            {sectors?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.sector}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Cell
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.cell} // Set default to the first option's ID
                            error={errors.cell && touched.cell}
                            disabled={!cells || (cells && cells?.length <= 0)}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.cell.name,
                                    event.target.value
                                );
                                setCellId({
                                    id: event.target.value,
                                    name: cells?.find(
                                        (item) => item.id === event.target.value
                                    ).cell,
                                });
                                setLocation([
                                    ...location,
                                    cells?.find(
                                        (item) => item.id === event.target.value
                                    ).cell,
                                ]);
                            }}
                        >
                            {cells?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.cell}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Village
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.village} // Set default to the first option's ID
                            error={errors.village && touched.village}
                            disabled={
                                !villages || (villages && villages?.length <= 0)
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.village.name,
                                    event.target.value
                                );
                                setLocation([
                                    ...location,
                                    villages?.find(
                                        (item) => item.id === event.target.value
                                    ).village,
                                ]);
                            }}
                        >
                            {villages?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption?.village}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
}

const FileInput = styled.input`
    outline: none;
    border-radius: 10px;
    width: 100%;
    padding: 0.635rem;
    border: 1px solid #ccc;
`;

export default OrganizationInfo;
