/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

export const districtApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDistrictsByProvinceId: builder.query({
            query: (provinceId) => `districts/by-province/${provinceId}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providersTags: ["district"],
        }),
    }),
});

export const { useGetDistrictsByProvinceIdQuery } = districtApi;

const districtTypeSlice = createSlice({
    name: "district",
    initialState: {
        districts: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            districtApi.endpoints.getDistrictsByProvinceId.matchFulfilled,
            (state, action) => {
                state.districts = action.payload;
            }
        );
    },
});

export const districtTypeReducer = districtTypeSlice.reducer;

export default districtTypeSlice;
