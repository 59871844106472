/** @format */

import * as Yup from "yup";
import form from "./form";

const {
    formField: { project_name, sponsor, organization_id, country_id },
} = form;

const validations = [
    Yup.object().shape({
        [project_name.name]: Yup.string().required(project_name.errorMsg),
        [sponsor.name]: Yup.string().required("This field is required"),
        [organization_id.name]: Yup.string().required("This field is required"),
        [country_id.name]: Yup.string().required(country_id.errorMsg),
    }),
];

export default validations;
