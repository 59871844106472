/** @format */

import form from "./form";

const {
    formField: {
        forest_size,
        name,
        district,
        sector,
        cell,
        village,
        beneficiary,
    },
} = form;

const initialValues = {
    [name.name]: "",
    [forest_size.name]: 1,
    [beneficiary.name]: "",
    [beneficiary.name]: "",
    [district.name]: "",
    [sector.name]: "",
    [cell.name]: "",
    [village.name]: "",
};

export default initialValues;
