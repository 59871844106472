/** @format */

import React from "react";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function UserInfo({ formData, CountriesList }) {
    const { formField, values, errors, touched, setFieldValue } = formData;
    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant='h5' fontWeight='bold'>
                    Create user
                </SoftTypography>
                <SoftTypography
                    variant='button'
                    fontWeight='regular'
                    color='text'
                >
                    Mandatory informations
                </SoftTypography>
            </SoftBox>
            <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.first_name.type}
                            label={formField.first_name.label}
                            name={formField.first_name.name}
                            value={values.first_name}
                            placeholder={formField.first_name.placeholder}
                            error={errors.first_name && touched.first_name}
                            success={
                                values?.first_name?.length > 0 &&
                                !errors?.first_name
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.last_name.type}
                            label={formField.last_name.label}
                            name={formField.last_name.name}
                            value={values.last_name}
                            placeholder={formField.last_name.placeholder}
                            error={errors.last_name && touched.last_name}
                            success={
                                values?.last_name?.length > 0 &&
                                !errors?.last_name
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.phone.type}
                            label={formField.phone.label}
                            name={formField.phone.name}
                            value={values.phone}
                            placeholder={formField.phone.placeholder}
                            error={errors.phone && touched.phone}
                            success={
                                values?.phone?.length > 0 && !errors?.phone
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.email.type}
                            label={formField.email.label}
                            name={formField.email.name}
                            value={values.email}
                            placeholder={formField.email.placeholder}
                            error={errors.email && touched.email}
                            success={
                                values?.email?.length > 0 && !errors?.email
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Country
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.country_id}
                            error={errors.country_id && touched.country_id}
                            onChange={(event) =>
                                setFieldValue(
                                    formField.country_id.name,
                                    event.target.value
                                )
                            }
                        >
                            {formField.country_id &&
                                formField.country_id.options?.length > 1 &&
                                formField.country_id.options?.map((country) => (
                                    <MenuItem
                                        key={country.id}
                                        value={country.id}
                                    >
                                        {country.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.location.type}
                            label={formField.location.label}
                            name={formField.location.name}
                            value={values.location}
                            placeholder={formField.location.placeholder}
                            error={errors.location && touched.location}
                            success={
                                values?.location?.length > 0 &&
                                !errors?.location
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Role
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.role} // Set default to the first option's ID
                            error={errors.role && touched.role}
                            onChange={(event) =>
                                setFieldValue(
                                    formField.role.name,
                                    event.target.value
                                )
                            }
                        >
                            {formField.role.options.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.dob.type}
                            label={formField.dob.label}
                            name={formField.dob.name}
                            value={values.dob}
                            placeholder={formField.dob.placeholder}
                            error={errors.dob && touched.dob}
                            success={values?.dob?.length > 0 && !errors?.dob}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.password.type}
                            label={formField.password.label}
                            name={formField.password.name}
                            value={values.password}
                            placeholder={formField.password.placeholder}
                            error={errors.password && touched.password}
                            success={
                                values?.password?.length > 0 &&
                                !errors?.password
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.repeatPassword.type}
                            label={formField.repeatPassword.label}
                            name={formField.repeatPassword.name}
                            value={values.repeatPassword}
                            placeholder={formField.repeatPassword.placeholder}
                            error={
                                errors.repeatPassword && touched.repeatPassword
                            }
                            success={
                                values?.repeatPassword?.length > 0 &&
                                !errors?.repeatPassword
                            }
                        />
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
};

export default UserInfo;
