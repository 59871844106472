/**
 * eslint-disable react/prop-types
 *
 * @format
 */

import SoftBadgeDot from "components/SoftBadgeDot";
import SoftBox from "components/SoftBox";
import DefaultCell from "components/dataTableComponents/DefaultCell";
import { Link } from "react-router-dom";

const dataTableData = {
    columns: [
        {
            Header: "Payment Status",
            accessor: "payment_status",
            Cell: ({ value }) => (
                <SoftBox ml={-1.325}>
                    {value ? (
                        <SoftBadgeDot size='xs' badgeContent='completed' />
                    ) : (
                        <SoftBadgeDot
                            color='error'
                            size='xs'
                            badgeContent='pending'
                        />
                    )}
                </SoftBox>
            ),
        },
        {
            Header: "Farmer",
            accessor: "farmer",
            Cell: ({ value }) => <DefaultCell value={value ?? "Farmer"} />,
        },
        {
            Header: "Forest Name",
            accessor: "forest_name",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "Forest Location",
            accessor: "forest_location",
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value }) => (
                <Link to={`/tasks/${value}/details`}>more</Link>
            ),
        },
    ],
};

export default dataTableData;
