/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    GoogleMap,
    LoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import styled from "styled-components";
import treeIcon from "assets/images/tree-icon.png";
import { apiKey } from "constants/data";

const style = { paddingBottom: 10, fontSize: 14, fontWeight: 400 };

const ForestMap = ({ height = "60vh", center, data }) => {
    const [selected, setSelected] = useState(null);
    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                id='example-map_trees'
                mapContainerStyle={{ height: `${height}`, width: "100%" }}
                zoom={5}
                center={{
                    lat: center?.lat,
                    lng: center?.long,
                }}
            >
                {data?.map((location) => (
                    <Marker
                        key={location.id}
                        options={{
                            icon: treeIcon,
                        }}
                        position={{
                            lat: location?.latitude * 1,
                            lng: location?.longitude * 1,
                        }}
                        onClick={() => {
                            setSelected({
                                ...location,

                                position: {
                                    lat: location?.latitude * 1,
                                    lng: location?.longitude * 1,
                                },
                            });
                        }}
                    />
                ))}

                {selected && (
                    <InfoWindow
                        paddingTop='5px'
                        position={selected.position}
                        onCloseClick={() => {
                            setSelected(null);
                        }}
                    >
                        <PopUpStyle>
                            <h4>Forest name</h4>
                            <p style={style}>{selected?.name}</p>
                            <h4>Hectars covered</h4>
                            <p style={style}>{selected?.forest_size} HA</p>
                            <h4>Number of trees</h4>
                            <p style={style}>{selected?.planting_tree}</p>
                            <h4>Dead trees</h4>
                            <p style={style}>{selected?.dead_tree}</p>
                            <h4>Replaced trees</h4>
                            <p style={style}>{selected?.replaced_tree}</p>
                            <h4>Planted date</h4>
                            <p style={style}>{selected?.action_date}</p>
                            <h4>Project name</h4>
                            <p style={style}>
                                {selected?.project?.project_name}
                            </p>
                            <h4>Revenue</h4>
                            <p style={style}>{selected?.planting_tree} FRW</p>

                            <h4>View more</h4>
                            <p style={style}>
                                <Link
                                    to={`/forest/${selected?.id}/details`}
                                    style={{
                                        color: "blue",
                                        display: "block",
                                        paddingTop: 5,
                                    }}
                                >
                                    More details{" "}
                                </Link>
                            </p>
                        </PopUpStyle>
                    </InfoWindow>
                )}
            </GoogleMap>
        </LoadScript>
    );
};

const PopUpStyle = styled.div`
    width: 400px;
`;

export default ForestMap;
