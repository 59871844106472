/** @format */

import { Card } from "@mui/material";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import DataTable from "eco/Tables/DataTable";
import { useGetLoansQuery } from "features/slices/loanSlice";
import React from "react";
import columnsStatus from "./data/columnStatus";
import SoftTypography from "components/SoftTypography";

const LoanStatusTable = ({ status, title }) => {
    const { data: loans, isLoading } = useGetLoansQuery(status);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftTypography
                variant={"h6"}
                textTransform='capitalize'
                fontWeight='bold'
                gutterBottom
            >
                {title}
            </SoftTypography>
            <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                <Card>
                    <DataTable
                        table={columnsStatus}
                        entriesPerPage={false}
                        canSearch
                        theData={loans?.data}
                    />
                </Card>
            </LoadingCardSkeleton>
        </DashboardLayout>
    );
};

export default LoanStatusTable;
