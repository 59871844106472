/** @format */

const form = {
    formId: "edit-organization-form",
    formField: {
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "e.g. ISARO ECONext",
            errorMsg: "Company name is required.",
        },
        contact_email: {
            name: "contact_email",
            label: "Email Address",
            type: "email",
            placeholder: "e.g. isaro.eco@gmail.com",
            errorMsg: "Email address is required.",
        },
        person_contact: {
            name: "person_contact",
            label: "Person contact",
            type: "text",
            placeholder: "e.g. John Doe",
            errorMsg: "Person contact name is required.",
        },
        sub_text: {
            name: "sub_text",
            label: "Sub Text",
            type: "text",
            placeholder: "e.g. subtext",
            errorMsg: "sub text is required.",
        },
        headline: {
            name: "headline",
            label: "Head Line",
            type: "text",
            placeholder: "e.g. ",
            errorMsg: "head line is required.",
        },
        cta: {
            name: "cta",
            label: "CTA",
            type: "text",
            placeholder: "e.g. ",
            errorMsg: "CTA is required.",
        },
        currency: {
            name: "currency",
            label: "Currency",
            options: [
                { id: "1", name: "USD" },
                { id: "2", name: "RWF" },
                { id: "3", name: "GBP" },
            ],
            errorMsg: "Currency is required.",
        },
        rate: {
            name: "rate",
            label: "Rate",
            type: "number",
            placeholder: "e.g. 1 or 100",
            errorMsg: "rate is required.",
        },
        currency_symbol: {
            name: "currency_symbol",
            label: "Currency symbol",
            options: [
                { id: "1", name: "$" },
                { id: "2", name: "RWF" },
                { id: "3", name: "£" },
            ],
            errorMsg: "Currency symbol is required.",
        },
        price_per_tree: {
            name: "price_per_tree",
            label: "Price / tree",
            type: "number",
            placeholder: "e.g. 100$",
            errorMsg: "Price / tree is required.",
        },
        contact_phone: {
            name: "contact_phone",
            label: "Person phone",
            type: "string",
            placeholder: "eg. 0788xxxxxx",
            errorMsg: "person phone is required.",
        },
        website: {
            name: "website",
            label: "Website",
            type: "text",
            placeholder: "e.g. https://examplte.com",
            errorMsg: "Website link is required.",
        },
        country_id: {
            name: "country_id",
            label: "Country",
            type: "select",
            options: [
                { id: "1", name: "Rwanda" },
                { id: "2", name: "Burundi" },
                { id: "3", name: "Kenya" },
                { id: "4", name: "Tanzania" },
            ],
            errorMsg: "Country is required.",
        },
        province: {
            name: "province",
            label: "Province",
            type: "select",
            options: [
                { id: "1", name: "Kigali" },
                { id: "2", name: "East" },
                { id: "3", name: "West" },
            ],
            errorMsg: "This field is required.",
            invalidMsg: "Level can't be more than 5 and less than 1",
        },
        district: {
            name: "district",
            label: "District",
            type: "select",
            options: [
                { id: "1", name: "District 1" },
                { id: "2", name: "District 2" },
                { id: "3", name: "District 3" },
            ],
            errorMsg: "This field is required.",
        },
        sector: {
            name: "sector",
            label: "Sector",
            type: "select",
            options: [
                { id: "1", name: "Sector 1" },
                { id: "2", name: "Sector 2" },
                { id: "1", name: "Sector 3" },
            ],
            errorMsg: "This field is required.",
        },
        cell: {
            name: "cell",
            label: "Cell",
            type: "select",
            options: [
                { id: "1", name: "Cell 1" },
                { id: "2", name: "Cell 2" },
                { id: "3", name: "Cell 3" },
            ],
            errorMsg: "This field is required.",
        },
        village: {
            name: "village",
            label: "Vilage",
            type: "select",
            options: [
                { id: "1", name: "Village 1" },
                { id: "2", name: "Village 2" },
                { id: "3", name: "Village 3" },
            ],
            errorMsg: "This field is required.",
        },
    },
};

export default form;
