/** @format */

import React from "react";
import LoanStatusTable from "../LoanStatusTable";

const ApplicationSubmittedLoans = () => {
    return (
        <LoanStatusTable
            status={"Application_Submitted"}
            title={"Applications submit loans"}
        />
    );
};

export default ApplicationSubmittedLoans;
