/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import { NavLink } from "layouts/pages/organization/data/organizationColumns";

const columnDataReleaseForest = {
    columns: [
        {
            Header: "Tree Types",
            accessor: "tree_type",
            Cell: ({ value }) => <DefaultCell value={value ?? "Tree Type"} />,
        },
        {
            Header: "Farmer",
            accessor: "farmer",
            Cell: ({ value }) => (
                <DefaultCell value={value?.full_name ?? "Name"} />
            ),
        },
        {
            Header: "Forest size",
            accessor: "forest_size",
            Cell: ({ value }) => (
                <DefaultCell value={`${value} hectars` ?? "size"} />
            ),
        },

        {
            Header: "Unit",
            accessor: "unit",
            Cell: ({ value }) => (
                <DefaultCell value={value ?? "planting trees"} />
            ),
        },

        {
            Header: "More",
            accessor: "id",
            Cell: ({ value, row }) => (
                <NavLink to={`/forest-release/${value}/details`}>
                    More detail
                </NavLink>
            ),
        },
    ],
};

export default columnDataReleaseForest;
