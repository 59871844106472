/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import { useGetOrganizationByIdQuery } from "features/slices/organizationSlice";
import { useGetSponsorByIdQuery } from "features/slices/sponsorSlice";
import { Link } from "react-router-dom";
import styled from "styled-components";

const columnProjectBeneficiary = {
    columns: [
        {
            Header: "Farmer",
            accessor: "farmer",
            Cell: ({ value }) => {
                return (
                    <ALink to={`/farmers/farmer-details/${value?.id}`}>
                        <DefaultCell value={value?.full_name} />
                    </ALink>
                );
            },
        },
        {
            Header: "Agent",
            accessor: "agent",
            Cell: ({ value }) => {
                return (
                    <ALink to={`/agents/details/${value?.id}`}>
                        <DefaultCell value={value?.full_name ?? "N/A"} />
                    </ALink>
                );
            },
        },
        {
            Header: "Organization",
            accessor: "organization_id",
            Cell: ({ value }) => {
                const { data: organization } =
                    useGetOrganizationByIdQuery(value);
                return (
                    <DefaultCell
                        value={organization?.name ?? "Contact person"}
                    />
                );
            },
        },
        {
            Header: "Sponsor",
            accessor: "sponsor_id",
            Cell: ({ value }) => {
                const { data: sponsor } = useGetSponsorByIdQuery(value);
                return <DefaultCell value={sponsor?.name ?? "Phone"} />;
            },
        },
    ],
};

const ALink = styled(Link)`
    color: #747f98 !important;
    &:hover {
        text-decoration: underline !important;
    }
`;

export default columnProjectBeneficiary;
