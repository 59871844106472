/** @format */

import React, { useState } from "react";
// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import Footer from "eco/Footer";
import MuiAlert from "@mui/material/Alert";
import { CircularProgress, Snackbar } from "@mui/material";
import { useGetAllCountriesQuery } from "features/slices/countrySlice";
import UserInfo from "./UserInfo";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import ErrorMessageResponse from "components/ErrorMessage/ErrorMessageResponse";
import { useCreateUserMutation } from "features/slices/userSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant='filled'
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            {...props}
        />
    );
});

const UserEdit = () => {
    const [open, setOpen] = React.useState(false);
    const [showError, setShowError] = useState(false);
    const { data: CountriesList } = useGetAllCountriesQuery();
    const { formId, formField } = form;
    const currentValidation = validations[0];
    const [_, { isLoading, isError, error }] = useCreateUserMutation();

    const toggleShowError = () => setShowError(!showError);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    const submitForm = async (values, actions) => {};
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Snackbar
                open={open}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity='success'
                    sx={{
                        width: "100%",
                        backgroundColor: "#17c1e8",
                        color: "#FFFFFF",
                    }}
                >
                    User is updated!
                </Alert>
            </Snackbar>
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={submitForm}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form id={formId} autoComplete='off'>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <UserInfo
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                    CountriesList={
                                                        CountriesList
                                                    }
                                                />
                                                <SoftBox
                                                    mt={2}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            ` save`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {showError &&
                                                    !isLoading &&
                                                    isError && (
                                                        <ErrorMessageResponse>
                                                            {error.message}
                                                        </ErrorMessageResponse>
                                                    )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default UserEdit;
