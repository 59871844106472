/** @format */

const form = {
    formId: "new-sponsor-form",
    formField: {
        name: {
            name: "name",
            label: "Sponsor Name",
            type: "text",
            placeholder: "e.g. MTN",
            errorMsg: "Sponsor name is required.",
        },
        Country: {
            name: "country_id",
            label: "Country",
            type: "select",
            options: [
                { id: "1", name: "Rwanda" },
                { id: "2", name: "Tanzania" },
            ],
            errorMsg: "This field is required.",
            invalidMsg: "Level can't be more than 5 and less than 1",
        },
    },
};

export default form;
