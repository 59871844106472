/** @format */

import { Card, Grid, MenuItem, Select } from "@mui/material";
import FormField from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import React from "react";

const top0 = { paddingTop: 0 };

const SponsorFormInfo = ({ formData }) => {
    const { formField, values, errors, touched, setFieldValue } = formData;

    return (
        <Card>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>Add Sponsor</SoftTypography>
            </SoftBox>
            <SoftBox component='form' pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.name.type}
                            label={formField.name.label}
                            name={formField.name.name}
                            value={values.name}
                            placeholder={formField.name.placeholder}
                            error={errors.name && touched.name}
                            success={values?.name?.length > 0 && !errors?.name}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} style={top0}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Country
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.Country} // Set default to the first option's ID
                            error={errors.Country && touched.Country}
                            onChange={(event) =>
                                setFieldValue(
                                    formField.Country.name,
                                    event.target.value
                                )
                            }
                        >
                            {formField.Country.options.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.id}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </SoftBox>
        </Card>
    );
};

export default SponsorFormInfo;
