/** @format */

import { useEffect, useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";

// ProgressLineChart configurations
import configs from "eco/Charts/LineCharts/ProgressLineChart/config";

// const Dataz = [
//   { ym: "2023/1", total_amount: Math.floor(Math.random() * 100000) },
//   { ym: "2023/2", total_amount: Math.floor(Math.random() * 100000) },
//   { ym: "2023/3", total_amount: Math.floor(Math.random() * 100000) },
//   { ym: "2023/4", total_amount: Math.floor(Math.random() * 100000) },
//   { ym: "2023/5", total_amount: Math.floor(Math.random() * 100000) },
//   { ym: "2023/6", total_amount: Math.floor(Math.random() * 100000) },
//   { ym: "2023/7", total_amount: Math.floor(Math.random() * 100000) },
//   { ym: "2023/8", total_amount: Math.floor(Math.random() * 100000) },
//   { ym: "2023/9", total_amount: Math.floor(Math.random() * 100000) },
// ];
function ProgressLineChart({
    color,
    icon,
    title,
    count,
    progress,
    height = "15rem",
    chart,
    currency,
    FarmerLoan,
}) {
    const [statsData, setStatsData] = useState({});

    const updateChartData = (data) => {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        const monthlyTotalData = Array(12).fill(0);

        for (const dataPoint of data) {
            const { ym, total_amount } = dataPoint;
            const [, month] = ym.split("/").map(Number);
            const monthIndex = month - 1;
            monthlyTotalData[monthIndex] += parseInt(total_amount + 13);
        }

        const currentMonthIndex = new Date().getMonth();
        const chartData = {
            labels: months.slice(0, currentMonthIndex + 1),
            data: monthlyTotalData.slice(0, currentMonthIndex + 1),
        };

        setStatsData(chartData);
    };

    const { data, options } = configs(
        color,
        statsData?.labels || [],
        title,
        statsData?.data || []
    );

    useEffect(() => {
        if (FarmerLoan && FarmerLoan?.length > 0) {
            updateChartData(FarmerLoan);
        }
    }, [FarmerLoan]);

    return (
        <Card>
            <SoftBox display='flex' alignItems='center' pt={2} px={2}>
                <SoftBox
                    width='3rem'
                    height='3rem'
                    display='grid'
                    justifyContent='center'
                    alignItems='center'
                    borderRadius='md'
                    shadow='md'
                    color='white'
                    bgColor={color}
                    variant='gradient'
                >
                    <Icon fontSize='default'>{icon}</Icon>
                </SoftBox>
                <SoftBox ml={2} lineHeight={1}>
                    <SoftTypography
                        variant='button'
                        fontWeight='medium'
                        textTransform='capitalize'
                        color='text'
                    >
                        {title}
                    </SoftTypography>
                    {count ? (
                        <SoftTypography variant='h5' fontWeight='bold'>
                            {currency ? currency : null}
                            {count}
                        </SoftTypography>
                    ) : null}
                </SoftBox>
                <SoftBox width='25%' ml='auto'>
                    <SoftTypography
                        display='block'
                        variant='caption'
                        fontWeight='medium'
                        color='text'
                    >
                        {progress}%
                    </SoftTypography>
                    <SoftBox mt={0.25}>
                        <SoftProgress
                            variant='gradient'
                            color={color}
                            value={progress}
                        />
                    </SoftBox>
                </SoftBox>
            </SoftBox>
            {useMemo(
                () => (
                    <SoftBox mt={2}>
                        <Line
                            data={data}
                            options={options}
                            style={{ height }}
                        />
                    </SoftBox>
                ),
                [height, data, options]
            )}
        </Card>
    );
}

// Setting default values for the props of ProgressLineChart
ProgressLineChart.defaultProps = {
    color: "info",
    count: 0,
    height: "15.25rem",
};

// Typechecking props for the ProgressLineChart
ProgressLineChart.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
    ]),
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    progress: PropTypes.number.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.array).isRequired,
    currency: PropTypes.string,
};

export default ProgressLineChart;
