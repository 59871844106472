import formElements from './form';

const {
  formField: { name, political_name, phone_code, money_code, h_to_cover, code },
} = formElements;

const initialValues = {
  [code.name]: '',
  [name.name]: '',
  [political_name.name]: '',
  [phone_code.name]: '',
  [money_code.name]: '',
  [h_to_cover.name]: '',
};

export default initialValues;
