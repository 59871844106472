/** @format */

const form = {
    formId: "new-user-form",
    formField: {
        first_name: {
            name: "first_name",
            label: "First Name",
            type: "text",
            placeholder: "e.g. John",
            errorMsg: "First name is required.",
        },
        last_name: {
            name: "last_name",
            label: "Last Name",
            type: "text",
            placeholder: "e.g. Doe",
            errorMsg: "Last name is required.",
        },
        email: {
            name: "email",
            label: "email address",
            type: "email",
            placeholder: "eg. user@isaro.rw",
            errorMsg: "Email address is required.",
            invalidMsg: "Your email address is invalid",
        },
        phone: {
            name: "phone",
            label: "Phone",
            type: "string",
            placeholder: "eg. 0788xxxxxx",
            errorMsg: "Phone is required.",
            invalidMsg: "Your phone is invalid",
        },
        password: {
            name: "password",
            label: "password",
            type: "password",
            placeholder: "******",
            errorMsg: "Password is required.",
            invalidMsg: "Your password should be more than 6 characters.",
        },
        repeatPassword: {
            name: "repeatPassword",
            label: "repeat password",
            type: "password",
            placeholder: "******",
            errorMsg: "Password is required.",
            invalidMsg: "Your password doesn't match.",
        },
        role: {
            name: "role",
            label: "Role",
            type: "select",
            options: [
                { id: "1", name: "Admin" },
                { id: "2", name: "Organization" },
            ],
            errorMsg: "Role is required.",
        },
        country_id: {
            name: "country_id",
            label: "Country",
            type: "select",
            options: [
                { id: "1", name: "Rwanda" },
                { id: "2", name: "Burundi" },
                { id: "3", name: "Kenya" },
                { id: "4", name: "Tanzania" },
                // Add more countries as needed
            ],
            errorMsg: "Country is required.",
        },
        location: {
            name: "location",
            label: "Location",
            type: "text",
            placeholder: "e.g. City",
            errorMsg: "Location is required.",
        },
        dob: {
            name: "dob",
            label: "Date of Birth",
            type: "date",
            errorMsg: "Date of birth is required.",
        },
    },
};

export default form;
