/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import { countries } from "constants/data";
import { useGetFarmerByIdQuery } from "features/slices/farmerSlice";
import { useGetOrganizationByIdQuery } from "features/slices/organizationSlice";

const columnDataProjectForest = {
    columns: [
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Name",
            accessor: "farmer_id",
            Cell: ({ value }) => {
                const { data: farmer } = useGetFarmerByIdQuery(value);
                return (
                    <DefaultCell
                        value={farmer?.full_name ?? "Contact person"}
                    />
                );
            },
        },
        {
            Header: "Organization",
            accessor: "organization_id",
            Cell: ({ value }) => {
                const { data: organization } =
                    useGetOrganizationByIdQuery(value);
                return (
                    <DefaultCell
                        value={organization?.name ?? "Contact person"}
                    />
                );
            },
        },
        {
            Header: "country",
            accessor: "country_id",
            Cell: ({ value }) => {
                const country = countries.find((c) => c.id === value);
                return <DefaultCell value={country.name ?? "N/A"} />;
            },
        },
    ],
};

export default columnDataProjectForest;
