/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSliceV1";

// Farmer API endpoints
export const loanTypeApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllLoanTypes: builder.query({
            query: () => "loan/all-loan-type",
            refetchOnMount: "always",
            refetchOnReconnect: true,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ["loanType"],
        }),
        getAllFertilizer: builder.query({
            query: () => "fertilizer/all",
            refetchOnMount: "always",
            refetchOnReconnect: true,
            transformResponse: (response, meta, arg) => response.data,
            providesTags: ["fertilizerType"],
        }),
        createLoanTypes: builder.mutation({
            query: (data) => {
                // const formData = new FormData();
                // formData.append("name", data.name);

                return {
                    url: "loan/create-loan-type",
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
            },
            invalidatesTags: ["loanType"],
        }),
        createFertilizer: builder.mutation({
            query: (data) => {
                // const formData = new FormData();
                // formData.append('name', data.name);

                return {
                    url: "fertilizer/create",
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
            },
            invalidatesTags: ["fertilizerType"],
        }),
    }),
});

export const {
    useGetAllLoanTypesQuery,
    useCreateLoanTypesMutation,
    useGetAllFertilizerQuery,
    useCreateFertilizerMutation,
} = loanTypeApi;

const loanTypeSlice = createSlice({
    name: "loanType",
    initialState: {
        loanTypes: [],
        loanFertilizer: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            loanTypeApi.endpoints.getAllFertilizer.matchFulfilled,
            (state, action) => {
                state.loanFertilizer = action.payload;
            }
        );
        builder.addMatcher(
            loanTypeApi.endpoints.getAllLoanTypes.matchFulfilled,
            (state, action) => {
                state.loanTypes = action.payload;
            }
        );
        builder.addMatcher(
            loanTypeApi.endpoints.createLoanTypes.matchFulfilled,
            (state, action) => {
                state.loanTypes = [action.payload.data, ...state.loanTypes];
            }
        );
        builder.addMatcher(
            loanTypeApi.endpoints.createFertilizer.matchFulfilled,
            (state, action) => {
                state.loanFertilizer = [
                    action.payload.data,
                    ...state.loanFertilizer,
                ];
            }
        );
    },
});

export const loanTypeReducer = loanTypeSlice.reducer;

export default loanTypeSlice;
