/** @format */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.errorColor || "#f44336"};
    font-size: ${(props) => props.fontSize || "14px"};
    margin-top: ${(props) => props.spacing || "8px"};
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s;

    &.active {
        opacity: 1;
        transform: translateY(0);
    }
`;

// const ErrorIcon = styled.span`
//   margin-right: ${(props) => props.spacing || "8px"};
// `;

const ErrorMessageText = styled.span`
    flex: 1;
`;

const ErrorMessageDisplay = ({
    errors,
    field,
    errorColor,
    fontSize,
    spacing,
}) => {
    const errorMessage = errors[field];
    return (
        <ErrorMessageContainer
            className={`error-message ${errorMessage ? "active" : ""}`}
            errorColor={errorColor}
            fontSize={fontSize}
            spacing={spacing}
        >
            <ErrorMessageText className='error-text'>
                {errorMessage}
            </ErrorMessageText>
        </ErrorMessageContainer>
    );
};

ErrorMessageDisplay.propTypes = {
    errors: PropTypes.object,
    field: PropTypes.string,
    errorColor: PropTypes.string,
    fontSize: PropTypes.string,
    spacing: PropTypes.string,
};

export default ErrorMessageDisplay;
