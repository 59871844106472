/** @format */

import DefaultCell from "components/dataTableComponents/DefaultCell";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";

import { Link } from "react-router-dom";
import { useGetOrganizationByIdQuery } from "features/slices/organizationSlice";
import { useGetSponsorByIdQuery } from "features/slices/sponsorSlice";
import { countries } from "constants/data";
import SoftBox from "components/SoftBox";

const columnDataProject = {
    columns: [
        {
            Header: "Name",
            accessor: "project_name",
            Cell: ({ value }) => <DefaultCell value={value ?? "Name"} />,
        },
        {
            Header: "Organization",
            accessor: "organization_id",
            Cell: ({ value }) => {
                const { data: organization } =
                    useGetOrganizationByIdQuery(value);
                return (
                    <DefaultCell
                        value={organization?.name ?? "Contact person"}
                    />
                );
            },
        },
        {
            Header: "Sponsor",
            accessor: "sponsor",
            Cell: ({ value }) => {
                const { data: sponsor } = useGetSponsorByIdQuery(value);
                return <DefaultCell value={sponsor?.name ?? "Phone"} />;
            },
        },
        {
            Header: "Country",
            accessor: "country_id",
            Cell: ({ value }) => {
                const country = countries.find((c) => c.id === value);
                return <DefaultCell value={country.name ?? "N/A"} />;
            },
        },
        {
            Header: "More",
            accessor: "id",
            Cell: ({ value, row }) => (
                <SoftBox display='flex' alignItems='center' gap={"1rem"}>
                    <Link to={`/project/${value}/update`}>
                        <CreateIcon />
                    </Link>
                    <Link to={`/project/${value}/details`}>
                        <VisibilityIcon />
                    </Link>
                </SoftBox>
            ),
        },
    ],
};

export default columnDataProject;
