/** @format */

import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import ProfileInfoCard from "eco/Cards/InfoCards/ProfileInfoCard";
import OrgCard from "eco/Cards/OrgCard";
import marie from "assets/images/placeholder.png";
import team from "assets/images/placeholder.png";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import { useGetForestStatByIdQuery } from "features/slices/forest";
import { useGetforestByIdQuery } from "features/slices/forest";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetTreesByForestIdQuery } from "features/slices/forest";
import TreesMap from "../treesMap";
import SoftTypography from "components/SoftTypography";
import styled from "styled-components";
import breakpoints from "assets/theme/base/breakpoints";

const ForestDetails = () => {
    const { id } = useParams();
    const { values } = breakpoints;
    const { data: forest, isLoading: isLoadingForest } =
        useGetforestByIdQuery(id);
    const { data: forestStat, isLoading } = useGetForestStatByIdQuery(id);
    const { data: trees } = useGetTreesByForestIdQuery(id);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Forest Statistics
                </SoftTypography>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton isLoading={isLoading}>
                                        <OrgCard
                                            title='Completed Task'
                                            count={
                                                forestStat?.tasks
                                                    ?.completedCount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton isLoading={isLoading}>
                                        <OrgCard
                                            title='Pending Task'
                                            count={
                                                forestStat?.tasks?.pendingCount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton isLoading={isLoading}>
                                        <OrgCard
                                            title='On hold Task'
                                            count={
                                                forestStat?.tasks?.obholdcount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton isLoading={isLoading}>
                                        <OrgCard
                                            title='In Progress Task'
                                            count={
                                                forestStat?.tasks
                                                    ?.inprogresscount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton isLoading={isLoading}>
                                        <OrgCard
                                            title='Declined Task'
                                            count={
                                                forestStat?.tasks?.declinedcount
                                            }
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                {/* tree status */}
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton isLoading={isLoading}>
                                        <OrgCard
                                            title='Planting Trees'
                                            count={forest?.planting_tree}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton isLoading={isLoading}>
                                        <OrgCard
                                            title='Replaced trees'
                                            count={forest?.replaced_tree}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton isLoading={isLoading}>
                                        <OrgCard
                                            title='Dead trees'
                                            count={forest?.dead_tree}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
            <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <LoadingCardSkeleton
                            isLoading={isLoadingForest}
                            height='330px'
                        >
                            <ProfileInfoCard
                                title='forest details'
                                description='Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).'
                                info={{
                                    forestName: `${
                                        (forest && forest?.name) || "N/A"
                                    }`,
                                    forestSize: `${
                                        (forest && forest?.forest_size) || "0"
                                    } Ha`,
                                    revenue: `${
                                        (forest && forest?.revenue) || "0"
                                    } FRW`,
                                    plantingTrees: `${
                                        (forest && forest?.planting_tree) || "0"
                                    }`,
                                    replaceTrees: `${
                                        (forest && forest?.replaced_tree) || "0"
                                    }`,
                                    deadTrees: `${
                                        (forest && forest?.dead_tree) || "0"
                                    }`,
                                    havestTimeExpectation: `${
                                        (forest &&
                                            forest?.harvest_time_expectation) ||
                                        "0"
                                    }`,
                                    havestingNumber: `${
                                        (forest && forest?.harvesting_number) ||
                                        "0"
                                    }`,
                                    havestNumberExpectation: `${
                                        (forest &&
                                            forest?.harvest_number_expectation) ||
                                        "0"
                                    }`,
                                    location: `${
                                        (forest && forest?.location_name) ||
                                        "None"
                                    }`,
                                    farmer: `${
                                        (forest && forest?.farmer?.full_name) ||
                                        "N/A"
                                    }`,
                                    // agent: `${
                                    //     (forest && forest?.agent?.full_name) ||
                                    //     "N/A"
                                    // }`,
                                }}
                                action={{
                                    route: "",
                                    tooltip: "Edit Forest",
                                }}
                            />
                        </LoadingCardSkeleton>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card sx={{ height: "100%" }}>
                            <SoftBox
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                                pt={2}
                                px={2}
                            >
                                <SoftTypography
                                    variant='h6'
                                    fontWeight='medium'
                                    textTransform='capitalize'
                                >
                                    Farmer profile
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox p={2}>
                                <SoftBox
                                    display='flex'
                                    justifyContent='start'
                                    py={1}
                                    pr={2}
                                >
                                    <SoftBox mr={2}>
                                        <ImageContainer>
                                            <ImageProfile
                                                src={
                                                    forest?.farmer?.photo ||
                                                    team
                                                }
                                            />
                                        </ImageContainer>
                                    </SoftBox>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Full Name: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;{forest?.farmer?.full_name}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Gender: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;{forest?.farmer?.gender || `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Education: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;
                                        {forest?.farmer?.education || `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        phone: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;
                                        {forest?.farmer?.phone || `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Location: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;
                                        {forest?.farmer?.location_name ||
                                            `none`}
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Card sx={{ height: "100%" }}>
                            <SoftBox
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                                pt={2}
                                px={2}
                            >
                                <SoftTypography
                                    variant='h6'
                                    fontWeight='medium'
                                    textTransform='capitalize'
                                >
                                    Agent Profile
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox p={2}>
                                <SoftBox
                                    display='flex'
                                    justifyContent='start'
                                    py={1}
                                    pr={2}
                                >
                                    <SoftBox mr={2}>
                                        <ImageContainer>
                                            <ImageProfile
                                                src={
                                                    forest?.agent?.photo ||
                                                    marie
                                                }
                                            />
                                        </ImageContainer>
                                    </SoftBox>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Full Name: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;
                                        {forest?.agent?.full_name || `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Gender: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;{forest?.agent?.gender || `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Education: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;
                                        {forest?.agent?.education || `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        phone: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp; +{forest?.agent?.phone || `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Whatsapp Phone: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp; +
                                        {forest?.agent?.whatsapp_phone ||
                                            `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Momo Phone: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp; +
                                        {forest?.agent?.momo_phone || `none`}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display='flex' py={1} pr={2}>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='bold'
                                        textTransform='capitalize'
                                    >
                                        Location: &nbsp;
                                    </SoftTypography>
                                    <SoftTypography
                                        variant='button'
                                        fontWeight='regular'
                                        color='text'
                                    >
                                        &nbsp;
                                        {forest?.agent?.location_name || `none`}
                                    </SoftTypography>
                                </SoftBox>
                            </SoftBox>
                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        <SoftTypography
                            variant={
                                window.innerWidth < values.sm ? "h3" : "h2"
                            }
                            textTransform='capitalize'
                            fontWeight='bold'
                            gutterBottom
                        >
                            Trees map
                        </SoftTypography>

                        <Card style={{ padding: 10 }}>
                            <TreesMap
                                center={{
                                    latitude: forest?.latitude,
                                    longitude: forest?.longitude,
                                }}
                                data={trees?.data}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

const ImageContainer = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px
`;
const ImageProfile = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%
`;

export default ForestDetails;
