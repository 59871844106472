/** @format */

import { useEffect, useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import BarReportsChartItem from "eco/Charts/BarCharts/ReportsBarChart/ReportsBarChartItem";

// ReportsBarChart configurations
import configs from "eco/Charts/BarCharts/ReportsBarChart/configs";
import FormatNumber from "components/formatter/formatNumber";

function ReportsBarChart({
    color,
    title,
    description,

    chart,
    items,
    LoanCalculations = [],
    LoanGraph = {},
}) {
    const [chartData, setChartData] = useState(null);
    const [itemsData, setItemsData] = useState([]);

    // Process LoanCalculations data and construct itemsData

    const updateItemsData = (data) => {
        const itemsData = [
            {
                icon: { color: "primary", component: "library_books" },
                label: "Applications",
                progress: {
                    content: FormatNumber(data[0]?.total) || 0,
                    percentage: 0,
                },
            },
            {
                icon: { color: "success", component: "touch_app" },
                label: "Ongoing",
                progress: {
                    content: FormatNumber(data[1]?.total) || 0,
                    percentage: 0,
                },
            },
            {
                icon: { color: "warning", component: "payment" },
                label: "Total loans",
                progress: {
                    content: FormatNumber(data[0]?.total_loans) || 0,
                    percentage: 0,
                },
            },
            {
                icon: { color: "error", component: "extension" },
                label: "Paid loans",
                progress: {
                    content: FormatNumber(data[0]?.paid_loans) || 0,
                    percentage: 0,
                },
            },
        ];
        setItemsData(itemsData);
    };

    // Process LoanGraph data and construct chartData
    const updateChartData = (data) => {
        const chartData = {
            labels: data?.months,
            datasets: {
                label: "Loans",
                data: data?.applications,
            },
        };

        setChartData(chartData);
    };

    // Handle updates to LoanGraph data
    useEffect(() => {
        if (LoanGraph) {
            updateChartData(LoanGraph);
        }
    }, [LoanGraph]);

    useEffect(() => {
        if (LoanCalculations && LoanCalculations.length > 0) {
            updateItemsData(LoanCalculations);
        }
    }, [LoanCalculations]);

    const { data, options } = configs(
        chartData?.labels || [],
        chartData?.datasets || {}
    );

    const renderItems = itemsData?.map(({ icon, label, progress }) => (
        <Grid item xs={6} sm={3} key={label}>
            <BarReportsChartItem
                color={color}
                icon={{ color: icon.color, component: icon.component }}
                label={label}
                progress={{
                    content: progress.content,
                    percentage: progress.percentage,
                }}
            />
        </Grid>
    ));

    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox padding='1rem'>
                {useMemo(
                    () => (
                        <SoftBox
                            variant='gradient'
                            bgColor={color}
                            borderRadius='lg'
                            py={2}
                            pr={0.5}
                            mb={3}
                            height='12.5rem'
                        >
                            <Bar data={data} options={options} />
                        </SoftBox>
                    ),
                    [chartData, color, data, options]
                )}
                <SoftBox px={1}>
                    <SoftBox mb={2}>
                        <SoftTypography
                            variant='h6'
                            fontWeight='medium'
                            textTransform='capitalize'
                        >
                            {title}
                        </SoftTypography>
                        <SoftTypography
                            component='div'
                            variant='button'
                            color='text'
                            fontWeight='regular'
                        >
                            {description}
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox py={1} px={0.5}>
                        <Grid container spacing={2}>
                            {renderItems}
                        </Grid>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
    color: "dark",
    description: "",
    items: [],
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
    ]),
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    chart: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    ).isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
};

export default ReportsBarChart;
