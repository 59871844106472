/* eslint-disable react/prop-types */
import IdCell from "components/dataTableComponents/IdCell";
import DefaultCell from "components/dataTableComponents/DefaultCell";

const loanData = {
  columns: [
    {
      Header: "id",
      accessor: "id",
      Cell: ({ value }) => (
        <IdCell handleClick={true} url="/loans/farmer/" style={{ cursor: "pointer" }} id={value} />
      ),
    },
    {
      Header: "loan type",
      accessor: "loan_type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "loan duration",
      accessor: "loan_duration",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Outstanding loan",
      accessor: "loan",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "total payment",
      accessor: "total_payment",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "forest",
      accessor: "forest",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ]
};

export default loanData;
