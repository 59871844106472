/** @format */

import React from "react";
import SupportStatus from "../status";

const CompletedTasks = () => {
    return <SupportStatus status={"Completed"} title={"Completed tasks"} />;
};

export default CompletedTasks;
