/** @format */

import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

import CountryTableCell from "./CountryTableCell";

function CountryTable({ title, rows, handleClick }) {
    const renderTableCells = rows.map((row, key) => {
        const tableRows = [];
        const rowKey = `row-${key}`;

        tableRows.push(
            <CountryTableCell
                key={`${row.country}-${key}`}
                title='Country'
                content={row?.country}
                noBorder={key === rows.length - 1}
                image={`https://flagsapi.com/${row?.code}/shiny/32.png`}
                handleClick={handleClick}
                select={{
                    id: row?.id || "",
                    name: row?.country || "",
                }}
            />
        );
        tableRows.push(
            <CountryTableCell
                key={`${row.forest}-${key}`}
                title='forest'
                content={row?.forest || 0}
                noBorder={key === rows.length - 1}
            />
        );
        tableRows.push(
            <CountryTableCell
                key={`${row.trees}-${key}`}
                title='trees'
                content={row?.trees || 0}
                noBorder={key === rows.length - 1}
            />
        );
        tableRows.push(
            <CountryTableCell
                key={`${row.hectares}-${key}`}
                title='hectares'
                content={row?.hectares || 0}
                noBorder={key === rows.length - 1}
            />
        );

        return <TableRow key={rowKey}>{tableRows}</TableRow>;
    });

    return (
        <TableContainer sx={{ height: "100%" }}>
            <Table>
                <TableHead>
                    <SoftBox
                        component='tr'
                        width='max-content'
                        display='block'
                        mb={1.5}
                    >
                        <SoftTypography variant='h6' component='td'>
                            {title}
                        </SoftTypography>
                    </SoftBox>
                </TableHead>
                <TableBody>
                    {useMemo(() => renderTableCells, [rows, renderTableCells])}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// Setting default values for the props of CountryTable
CountryTable.defaultProps = {
    rows: [{}],
};

// Typechecking props for the CountryTable
CountryTable.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object),
};

export default CountryTable;
